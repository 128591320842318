import { useCallback, useEffect, useRef } from 'react';

export default function useOutsideClick(handleOutsideClick: any, isSelectionInclude?: boolean) {
    const ref = useRef(null);

    const onClick = useCallback(
        (event: unknown) => {
            // @ts-ignore
            if (ref.current?.contains && !ref.current.contains(event.target)) {
                if (isSelectionInclude) {
                    const selection = window.getSelection();

                    if (selection && selection.toString().length > 0) return;

                    handleOutsideClick(event);
                } else {
                    handleOutsideClick(event);
                }
            }
        },
        [handleOutsideClick],
    );

    useEffect(() => {
        document.addEventListener('click', onClick, true);
        return () => {
            document.removeEventListener('click', onClick, true);
        };
    }, [onClick]);

    return ref;
}
