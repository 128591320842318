import { workersApi } from 'api';
import { Button } from 'components/Button';
import { useAppDispatch } from 'store/hooks';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { IWorkerDetail } from 'interfaces/workers';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table } from './table';

import { fetchWorkers, setWorkerIds } from '../../../store';
import { withdrawsSelector } from '../../../store/selectors';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const WithdrawModal: FC<Props> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { workerIds, workerId } = useSelector(withdrawsSelector);

    const [workers, setWorkers] = useState<(IWorkerDetail & { withdrawError: boolean; withdraw: number })[]>([]);
    const [loading, setLoading] = useState(true);
    const [sendLoading, setSendLoading] = useState(false);

    const fetchWithdraws = async () => {
        if (workerId) {
            const { data } = await workersApi.getWorker(workerId).finally(() => {
                setLoading(false);
            });

            setWorkers([data].map((item) => ({ ...item, withdrawError: false, withdraw: item.balance })));

            return;
        }

        const promises = workerIds.map((id) => workersApi.getWorker(id).then(({ data }) => data));

        const data = await Promise.all(promises).finally(() => {
            setLoading(false);
        });

        setWorkers(data.map((item) => ({ ...item, withdrawError: false, withdraw: item.balance })));
    };

    const handleSend = () => {
        setSendLoading(true);

        const data = workers.map((item) => ({
            worker_id: item.id,
            amount: item.withdraw,
            transaction_type: 'withdraw',
        }));

        workersApi
            .doTransaction({ items: data })
            .then(() => {
                onClose();
                enqueueSnackbar('Средства успешно выведены', { variant: 'success' });
                dispatch(fetchWorkers());
                dispatch(setWorkerIds([]));
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => setSendLoading(false));
    };

    const handleAmountChange = (id: string, value: number) => {
        setWorkers((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, withdraw: value, withdrawError: value > item.balance } : item,
            ),
        );
    };

    useEffect(() => {
        setWorkers([]);
        setLoading(true);

        if (!open) return;

        fetchWithdraws();
    }, [open]);

    const emptyError = workers.some((item) => !item.withdraw);
    const withdrawError = workers.some((item) => item.withdraw > item.balance);

    return (
        <Dialog
            transitionDuration={{ exit: 0, enter: 200 }}
            maxWidth="md"
            fullWidth
            open={open}
        >
            <Title>Вывод средств</Title>
            <CloseButton onClick={onClose}>
                <CloseIcon />
            </CloseButton>
            <DialogContent sx={{ paddingY: 0 }}>
                <Table
                    rows={workers}
                    onAmountChange={handleAmountChange}
                    loading={loading}
                />
            </DialogContent>
            <DialogActions sx={{ padding: '20px 24px' }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <Button
                    loading={sendLoading}
                    disabled={withdrawError || emptyError}
                    variant="contained"
                    onClick={handleSend}
                >
                    Вывести
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Title = styled(DialogTitle)({
    fontSize: '24px',
    fontWeight: 600,
});

const CloseButton = styled(IconButton)({
    position: 'absolute',
    right: 8,
    top: 8,
    color: `${(theme: any) => theme.palette.grey[500]}`,
});
