import { Params } from 'types';

import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IPhotoFile } from '../interfaces';
import { IServiceArea, IWorkingArea } from '../interfaces/dicts';
import { IFormValuesEditWorkerFIO } from '../interfaces/edit/editWorkerFIO';
import { IAddWorkerAddressReq, IEditWorkerInfoReq } from '../interfaces/edit/editWorkerInfo';
import { IEditWorkerScheduleReq } from '../interfaces/edit/editWorkerSchedule';
import { IFormValuesEditWorkerServiceAreas } from '../interfaces/edit/editWorkerServiceAreas';
import { IEditWorkerSpecializationsReq } from '../interfaces/edit/editWorkerSpecializations';
import { ISpecialization } from '../interfaces/specializations';
import {
    ICountNewResp,
    IEducation,
    IPaymentDetails,
    IWorkerDetail,
    IWorkerPaymentMethods,
    IWorkSchedule,
    TWorkersResp,
} from '../interfaces/workers';

export default class WorkersAPI {
    getWorkers(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<TWorkersResp>;
    }

    getWorkersNew(params: Params) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<TWorkersResp>;
    }

    getCountNew() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/count_new`,
            isAuthorization: true,
        }) as AxiosPromise<ICountNewResp>;
    }

    getWorker(workerId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}`,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    uploadWorkerAvatar(workerId: string, data: FormData) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/uploadAvatar`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IPhotoFile>;
    }

    editWorkerFIO(workerId: IWorkerDetail['id'], data: IFormValuesEditWorkerFIO) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    editWorkerSchedule(workerId: IWorkerDetail['id'], data: IEditWorkerScheduleReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/schedule`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkSchedule>;
    }

    editWorkerSpecializations(workerId: IWorkerDetail['id'], data: IEditWorkerSpecializationsReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/specializations`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<ISpecialization[]>;
    }

    editWorkerServiceAreas(workerId: IWorkerDetail['id'], data: IFormValuesEditWorkerServiceAreas) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/serviceAreas`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IServiceArea[]>;
    }

    changeWorkerIsArchivedValue(workerId: string, data: { is_archived: boolean }) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/archive`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    editWorkerEducation(workerId: IWorkerDetail['id'], data: IEducation) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/education`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    editWorkerInfo(workerId: IWorkerDetail['id'], data: IEditWorkerInfoReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    editWorkerAddress(workerId: IWorkerDetail['id'], data: IEditWorkerInfoReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/address/${data.addressId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    editWorkerDescription(workerId: IWorkerDetail['id'], data: IEditWorkerInfoReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/description`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    addWorkerAddress(workerId: IWorkerDetail['id'], data: IAddWorkerAddressReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/address`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    verifyResetWorker(workerId: IWorkerDetail['id']) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${workerId}/verify/reset`,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    createWorkerArea(data: Omit<IWorkingArea, 'id'> & { worker_id: string }) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/workingArea`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    getWorkerArea(areaId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/workingArea/${areaId}`,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    putWorkerArea(areaId: string, data: any) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/workingArea/${areaId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    deleteWorkerArea(areaId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/workingArea/${areaId}`,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerDetail>;
    }

    doTransaction(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/transactions`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    postPaymentMethods(data: IWorkerPaymentMethods, id: string) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${id}/paymentDetails`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    postPaymentDetails(data: IPaymentDetails) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/accounting/paymentDetails`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getPaymentDetails(params?: { worker_id__in: string }) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/accounting/paymentDetails`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    putPaymentMethods(data: IWorkerPaymentMethods, id: string) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/workers/${id}/paymentDetails`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
