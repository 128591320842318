import { Autocomplete, Box, FormControl, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SelectedPrice from './SelectedPrice';

import TextField from '../../../../components/inputs/TextField';
import { IPriceOption } from '../../../../interfaces/prices';
import { setSelectedPricesAction } from '../../../../store/actions/create/createIssuePartner';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsCreateIssuePartnerFormSelector,
    formValuesSelector,
    selectedPricesSelector,
} from '../../../../store/selectors/create/createIssuePartnerSelectors';
import {
    // pricesIsAdditionalOptionsSelector,
    pricesOptionsSelector,
    pricesSelector,
} from '../../../../store/selectors/pricesSelectors';

function Prices() {
    const dispatch = useAppDispatch();

    const prices = useSelector(pricesSelector);
    const pricesOptions = useSelector(pricesOptionsSelector) as IPriceOption[];
    // const pricesIsAdditionalOptions = useSelector(pricesIsAdditionalOptionsSelector) as IPriceOption[];
    const selectedPrices = useSelector(selectedPricesSelector);
    const formValues = useSelector(formValuesSelector);
    const errors = useSelector(errorsCreateIssuePartnerFormSelector);

    const [value, setValue] = useState('');

    function priceSelectHandler(option: IPriceOption | null) {
        setValue('');
        if (!prices || !option) return;
        if (selectedPrices && selectedPrices.some(({ id }) => id === option.id)) return;

        const foundPrice = prices.find(({ id }) => id === option.id);

        if (!foundPrice) {
            console.error('Error: !foundPrice priceSelectHandle()');
            return;
        }

        const newSelectedPrices = selectedPrices ? [...selectedPrices, foundPrice] : [foundPrice];
        dispatch(setSelectedPricesAction(newSelectedPrices));
    }

    useEffect(() => {
        if (selectedPrices?.length) {
            dispatch(setSelectedPricesAction([]));
        }
    }, [formValues?.addressFiasId]);

    return (
        <Box mt={5}>
            <Typography
                variant={'h5'}
                mb={2}
            >
                Услуги
            </Typography>

            <FormControl fullWidth>
                <Autocomplete
                    renderInput={(params) => {
                        return (
                            <TextField
                                name={'prices'}
                                errors={errors}
                                placeholder="Услуга *"
                                params={params}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.id}
                            >
                                {option.label}
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        priceSelectHandler(option);
                    }}
                    onInputChange={(_, value) => setValue(value)}
                    inputValue={value}
                    noOptionsText={'Нет услуг...'}
                    loadingText={'Загрузка...'}
                    loading={pricesOptions === null}
                    options={pricesOptions || []}
                    disabled={!formValues?.address || !formValues?.categories?.length}
                    value={null}
                />
            </FormControl>
            {/* <FormControl
                fullWidth
                margin="normal"
            >
                <Autocomplete
                    renderInput={(params) => {
                        return (
                            <TextField
                                name={'sub_price'}
                                placeholder="Дополнительная услуга (необязательно)"
                                params={params}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.id}
                            >
                                {option.label}
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        priceSelectHandler(option);
                    }}
                    noOptionsText={'Нет дополнительных услуг'}
                    loadingText={'Загрузка...'}
                    loading={pricesOptions === null}
                    options={pricesIsAdditionalOptions || []}
                    disabled={!formValues?.address}
                />
            </FormControl> */}

            {!!selectedPrices?.length &&
                selectedPrices.map((price) => (
                    <SelectedPrice
                        key={price.id}
                        price={price}
                    />
                ))}
        </Box>
    );
}

export default Prices;
