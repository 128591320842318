import { DriveFileRenameOutlineOutlined, NoteAddOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import ServiceDialogForm from './ServiceDialogForm';

import { setIsOpenPriceServicesDialogAction } from '../../../store/actions/priceServicesDialog';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenPriceServicesDialogSelector } from '../../../store/selectors/priceServicesDialogSelectors';

import './ServiceDialog.scss';

function ServiceDialog() {
    const dispatch = useAppDispatch();

    const dialogState = useSelector(isOpenPriceServicesDialogSelector);

    const { isOpen, isEdit } = dialogState;

    function onClose() {
        dispatch(setIsOpenPriceServicesDialogAction({ isEdit: true, isOpen: false }));
    }
    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="service-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    {isEdit ? (
                        <DriveFileRenameOutlineOutlined
                            color="primary"
                            sx={{ marginRight: '30px' }}
                            fontSize="large"
                        />
                    ) : (
                        <NoteAddOutlined
                            color="primary"
                            sx={{ marginRight: '30px' }}
                            fontSize="large"
                        />
                    )}
                    <Typography variant="h6">{isEdit ? 'Изменить тип услуги' : 'Новый тип услуг'}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <ServiceDialogForm isEdit={dialogState.isEdit} />
            </DialogContent>
        </Dialog>
    );
}

export default ServiceDialog;
