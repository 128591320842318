import { servicesApi } from 'api';
import Loader from 'components/Loader';
import { ERoutes } from 'enums/routes.enum';
import { FilterIn } from 'features/filter-in';
import {
    fetchServicesComplex,
    setServicesComplexFilters,
    setServicesComplexPagination,
    setServicesComplexSort,
} from 'pages/price-lists/store';
import { TServicesComplexSort } from 'pages/price-lists/store/types';
import { useAppDispatch } from 'store/hooks';

import { Search } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button,
    InputAdornment,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { ComplexServiceItem } from './complex-services-item';
import { ControlsWrapper, HeadCell, ListItem, Pagination, StyledButton, StyledPopover, Wrapper } from './styles';

import { priceListsSelector } from '../../store/selectors';
import { MuiDivider } from '../styles';

const filterItems = [
    { value: 1, title: 'Отобразить услуги без указанной цены' },
    { value: 2, title: 'Отобразить услуги без указанной комиссии мастера' },
    { value: 3, title: 'Отобразить услуги без цены и комиссии' },
    { value: 4, title: 'Отобразить услуги без цены или комиссии' },
];

const sortItemsMapped: Record<TServicesComplexSort, string> = {
    name: '↓ Наименование',
    '-name': '↑ Наименование',
    is_active: '↓ Активность',
    '-is_active': '↑ Активность',
    created_at: '↓ Дата создания',
    '-created_at': '↑ Дата создания',
};

type TFilterTypes = 'category_id__in';

export const PriceListComplexesServicesPage = () => {
    const dispatch = useAppDispatch();

    const { priceListId } = useParams<{ priceListId: string }>();

    const {
        servicesComplex,
        servicesComplexFilters,
        servicesComplexPagination,
        serviceComplexLoading,
        servicesComplexSort,
    } = useSelector(priceListsSelector);

    const [isOpen, setOpen] = useState<any>(false);
    const [isSortOpen, setIsSortOpen] = useState<any>(false);
    const [activeFilter, setActiveFilter] = useState<number | null>();

    const clearFilterByPrice = () => {
        dispatch(
            setServicesComplexFilters({
                has_not_amount: null,
                has_not_commission: null,
                has_not_amount_and_commission: null,
                has_not_amount_or_commission: null,
            }),
        );
    };

    const handleChangeSort = (value: TServicesComplexSort) => {
        dispatch(setServicesComplexSort(value));
        dispatch(fetchServicesComplex(priceListId));
        setIsSortOpen(false);
    };

    const handleClearSort = () => {
        dispatch(setServicesComplexSort(null));
        dispatch(fetchServicesComplex(priceListId));
        setIsSortOpen(false);
    };

    const handleChangeFilterByPrice = (value: number) => {
        clearFilterByPrice();
        if (value === 1) {
            dispatch(setServicesComplexFilters({ has_not_amount: true }));
            dispatch(fetchServicesComplex(priceListId));
            setActiveFilter(value);
        } else if (value === 2) {
            dispatch(setServicesComplexFilters({ has_not_commission: true }));
            dispatch(fetchServicesComplex(priceListId));
            setActiveFilter(value);
        } else if (value === 3) {
            dispatch(setServicesComplexFilters({ has_not_amount_and_commission: true }));
            dispatch(fetchServicesComplex(priceListId));
            setActiveFilter(value);
        } else if (value === 4) {
            dispatch(setServicesComplexFilters({ has_not_amount_or_commission: true }));
            dispatch(fetchServicesComplex(priceListId));
            setActiveFilter(value);
        }
        setOpen(false);
    };

    const handleClearFilterByPrice = () => {
        clearFilterByPrice();
        dispatch(fetchServicesComplex(priceListId));
        setActiveFilter(null);
        setOpen(false);
    };

    const filterSearch = (search: string) => {
        dispatch(setServicesComplexFilters({ search }));
        dispatch(fetchServicesComplex(priceListId));
    };

    const filterSearchWithDebounced = useDebouncedCallback(filterSearch, 800);

    const handleChangeFilter = (itemId: string, filterType: TFilterTypes) => {
        const getIds = () => {
            if (servicesComplexFilters[filterType].includes(itemId as never)) {
                return servicesComplexFilters[filterType].filter((id: string) => id !== itemId);
            } else {
                return [...servicesComplexFilters[filterType], itemId];
            }
        };

        dispatch(setServicesComplexFilters({ [filterType]: getIds() }));
        dispatch(fetchServicesComplex(priceListId));
    };

    const handleFilterClean = (key: string) => {
        dispatch(setServicesComplexFilters({ [key]: [] }));
        dispatch(fetchServicesComplex(priceListId));
    };

    const onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setServicesComplexPagination({ size: +e.target.value }));
        dispatch(fetchServicesComplex(priceListId));
    };

    const onPageChange = (_: any, page: number) => {
        dispatch(setServicesComplexPagination({ page: page + 1 }));
        dispatch(fetchServicesComplex(priceListId));
    };

    useEffect(() => {
        if (servicesComplexFilters.has_not_amount) {
            setActiveFilter(1);
        } else if (servicesComplexFilters.has_not_commission) {
            setActiveFilter(2);
        } else if (servicesComplexFilters.has_not_amount_and_commission) {
            setActiveFilter(3);
        } else if (servicesComplexFilters.has_not_amount_or_commission) {
            setActiveFilter(4);
        }

        dispatch(fetchServicesComplex(priceListId));
    }, []);

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: 'calc(100% - 48px)' }}>
            <Button
                variant="contained"
                sx={{ position: 'absolute', right: '0', top: '-43px' }}
                component={Link}
                to={`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}/${ERoutes.AddComplexServices}`}
            >
                Добавить
            </Button>
            <MuiDivider />
            <ControlsWrapper>
                <TextField
                    placeholder="Поиск по наименованию"
                    variant="outlined"
                    size="small"
                    defaultValue={servicesComplexFilters.search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => filterSearchWithDebounced(e.target.value)}
                />
                <FilterIn
                    title="Категория"
                    onChange={(itemId) => handleChangeFilter(itemId, 'category_id__in')}
                    activeIds={servicesComplexFilters.category_id__in}
                    fetcher={(params) => servicesApi.getCategories({ ...params, is_root: false })}
                    cleaner={() => handleFilterClean('category_id__in')}
                />
                <Button
                    variant={activeFilter ? 'filterActive' : 'filter'}
                    onClick={(e) => setOpen(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                >
                    Фильтр по цене
                </Button>
                <StyledPopover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setOpen(false)}
                >
                    <div>
                        {filterItems.map((item) => (
                            <ListItem
                                sx={{ cursor: 'pointer' }}
                                key={item.value}
                                onClick={() => handleChangeFilterByPrice(item.value)}
                            >
                                {item.title}
                                {activeFilter === item.value ? <CheckIcon /> : null}
                            </ListItem>
                        ))}
                    </div>
                    <StyledButton onClick={handleClearFilterByPrice}>Очистить фильтр</StyledButton>
                </StyledPopover>
                <Button
                    variant="filter"
                    onClick={(e) => setIsSortOpen(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                >
                    {servicesComplexSort ? sortItemsMapped[servicesComplexSort] : 'Сортировка'}
                </Button>
                <StyledPopover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(isSortOpen)}
                    anchorEl={isSortOpen}
                    onClose={() => setIsSortOpen(false)}
                >
                    <div>
                        {Object.entries(sortItemsMapped).map(([value, title]) => (
                            <ListItem
                                sx={{ cursor: 'pointer' }}
                                key={value}
                                onClick={() => handleChangeSort(value as TServicesComplexSort)}
                            >
                                {title}
                                {servicesComplexSort === value ? <CheckIcon /> : null}
                            </ListItem>
                        ))}
                    </div>
                    <StyledButton onClick={handleClearSort}>Очистить сортировку</StyledButton>
                </StyledPopover>
            </ControlsWrapper>
            {serviceComplexLoading ? (
                <Loader />
            ) : (
                <Wrapper>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <HeadCell sx={{ width: '40%' }}>НАИМЕНОВАНИЕ</HeadCell>
                                    <HeadCell sx={{ width: '40%' }}>УСЛУГИ</HeadCell>
                                    <HeadCell
                                        sx={{ width: '10%' }}
                                        align="center"
                                    >
                                        ЦЕНА
                                    </HeadCell>
                                    <HeadCell
                                        sx={{ width: '10%' }}
                                        align="center"
                                    >
                                        КОМИССИЯ
                                    </HeadCell>

                                    <HeadCell align="center" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {servicesComplex.map((item) => {
                                    return (
                                        <ComplexServiceItem
                                            key={item?.id}
                                            priceListId={priceListId}
                                            item={item}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        rowsPerPageOptions={[5, 25, 50, 100]}
                        count={servicesComplexPagination.total}
                        rowsPerPage={servicesComplexPagination.size}
                        page={servicesComplexPagination.page - 1}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Wrapper>
            )}
        </div>
    );
};
