import Accordion from 'components/Accordion';
import AudioFiles from 'components/AudioFiles';
import { ERoutes } from 'enums/routes.enum';
import { issueSelector } from 'store/selectors/issuesSelectors';

import { Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Reason, ReasonsWrapper, Wrapper } from './styles';

type Props = {
    issueStatus: string;
};

export const ReasonRefusal: FC<Props> = ({ issueStatus }) => {
    const issue = useSelector(issueSelector);

    console.log(1, issueStatus);

    return (
        <Accordion
            title={<Typography>Причина отказа</Typography>}
            sx={{ mt: 3 }}
        >
            <Wrapper>
                <ReasonsWrapper>
                    <span>Отменил</span>
                    <Reason>{issue?.canceled_by === 'client' ? 'Клиент' : 'Бэкофис'}</Reason>
                </ReasonsWrapper>
                <ReasonsWrapper>
                    <span>Причины отмены</span>
                    <Reason>{issue?.cancel_reasons.join(', ')}</Reason>
                </ReasonsWrapper>
                {issue?.canceled_issue_info && (
                    <ReasonsWrapper>
                        <span>Дублирует</span>
                        <Reason>
                            <Link
                                target="_blank"
                                to={`/${ERoutes.Issues}/${issue.canceled_issue_info.id}`}
                            >
                                № {issue.canceled_issue_info.oid}
                            </Link>
                        </Reason>
                    </ReasonsWrapper>
                )}
                {issue?.cancel_comment && (
                    <ReasonsWrapper>
                        <span>Комментарий</span>
                        <Reason>{issue?.cancel_comment}</Reason>
                    </ReasonsWrapper>
                )}
                {issue?.canceled_audio_files?.length && (
                    <ReasonsWrapper>
                        <span>Аудиофайл</span>
                        <AudioFiles audioFiles={issue?.canceled_audio_files || []} />
                    </ReasonsWrapper>
                )}
            </Wrapper>
        </Accordion>
    );
};
