import MainLayout from 'layouts/MainLayout';

import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Tabs } from './components/tabs';

export const Accounting = () => {
    return (
        <MainLayout>
            <Container>
                <Tabs />
                <Outlet />
            </Container>
        </MainLayout>
    );
};

const Container = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});
