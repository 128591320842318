import { Autocomplete, Box, FormControl, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть
import { useDebouncedCallback } from 'use-lodash-debounce';

import DatePicker from '../../../../components/inputs/DatePicker';
import PhoneInput from '../../../../components/inputs/PhoneInput';
import TextField from '../../../../components/inputs/TextField';
import { setSetFormValuesAction } from '../../../../store/actions/create/createIssuePartner';
import { getAddressesDictsThunk } from '../../../../store/actions/dicts';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsCreateIssuePartnerFormSelector,
    formValuesSelector,
} from '../../../../store/selectors/create/createIssuePartnerSelectors';
import { addressesDictsSelector, addressesOptionsDictsSelector } from '../../../../store/selectors/dictsSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';

function Client() {
    const dispatch = useAppDispatch();

    const addressesOptions = useSelector(addressesOptionsDictsSelector);
    const formValues = useSelector(formValuesSelector);
    const address = useSelector(addressesDictsSelector);
    const errors = useSelector(errorsCreateIssuePartnerFormSelector);

    const changeAddressInpDebounced = useDebouncedCallback(changeAddressInputHandler, 800);

    function changeFirstNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSetFormValuesAction({ first_name: e.target.value }));
    }

    function changeAddressHandler(option: { label: string; value: string } | null) {
        try {
            const currentAddress = address?.find((dicts) => dicts.name === option?.label);
            dispatch(setSetFormValuesAction({ address: option?.label || null, addressFiasId: option?.value || null }));
            dispatch(
                setSetFormValuesAction({
                    lat: currentAddress?.lat || null,
                    long: currentAddress?.long || null,
                    fias_id: currentAddress?.fias_id || null,
                }),
            );
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function changeAddressInputHandler(e: React.ChangeEvent<HTMLInputElement>) {
        try {
            const val = e.target.value;
            if (!val) return;
            await dispatch(getAddressesDictsThunk({ address: val }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function changeApartmentHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSetFormValuesAction({ apartment: e.target.value }));
    }

    function changeEntranceHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSetFormValuesAction({ entrance: e.target.value }));
    }

    function changeFloorHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSetFormValuesAction({ floor: e.target.value }));
    }

    function changeIntercomHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSetFormValuesAction({ intercom: e.target.value }));
    }

    function changeDateHandler(val: Date | null) {
        dispatch(setSetFormValuesAction({ date: val }));
    }

    function changePhoneNumberHandler(val: string) {
        dispatch(setSetFormValuesAction({ phone_number: val }));
    }

    return (
        <Box>
            <Typography
                variant="h5"
                mb={2}
            >
                Клиент
            </Typography>
            <FormControl fullWidth>
                <TextField
                    name={'first_name'}
                    errors={errors}
                    onChange={changeFirstNameHandler}
                    placeholder="Фамилия имя *"
                />
            </FormControl>
            <FormControl
                fullWidth
                margin="normal"
            >
                <Autocomplete
                    filterOptions={(opt) => opt}
                    renderInput={(params) => {
                        return (
                            <TextField
                                name={'address'}
                                errors={errors}
                                onChange={changeAddressInpDebounced}
                                placeholder="Город, улица, дом *"
                                params={params}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.value}
                            >
                                {option.label}
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        changeAddressHandler(option);
                    }}
                    noOptionsText={'Не найдено'}
                    loadingText={'Загрузка...'}
                    loading
                    options={addressesOptions || []}
                    isOptionEqualToValue={() => true}
                />
            </FormControl>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="apartment"
                        onChange={changeApartmentHandler}
                        placeholder="Квартира"
                        labelText="Квартира"
                        fullWidth
                        params={{ margin: 'normal' }}
                        errors={errors}
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="entrance"
                        errors={errors}
                        onChange={changeEntranceHandler}
                        placeholder="Подъезд"
                        labelText="Подъезд"
                        fullWidth
                        params={{ margin: 'normal' }}
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="floor"
                        errors={errors}
                        onChange={changeFloorHandler}
                        placeholder="Этаж"
                        labelText="Этаж"
                        fullWidth
                        params={{ margin: 'normal' }}
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="intercom"
                        errors={errors}
                        onChange={changeIntercomHandler}
                        placeholder="Домофон"
                        labelText="Домофон"
                        fullWidth
                        params={{ margin: 'normal' }}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        name="latitude"
                        labelText="Широта"
                        disabled
                        fullWidth
                        params={{ margin: 'normal' }}
                        value={formValues?.lat || ''}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        name="longitude"
                        labelText="Долгота"
                        disabled
                        fullWidth
                        params={{ margin: 'normal' }}
                        value={formValues?.long || ''}
                    />
                </Grid>
            </Grid>
            <FormControl
                fullWidth
                margin="dense"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <DatePicker
                            name="date"
                            placeholder="ДД.ММ.ГГГГ"
                            label="Желаемая дата установки *"
                            fullWidth
                            disablePast
                            errors={errors}
                            onChange={changeDateHandler}
                            params={{ margin: 'normal' }}
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <PhoneInput
                            name="phone_number"
                            onChange={changePhoneNumberHandler}
                            placeholder="+7 900 000 00 00"
                            fullWidth
                            errors={errors}
                            params={{ margin: 'normal' }}
                        />
                    </Grid>
                </Grid>
            </FormControl>
        </Box>
    );
}

export default Client;
