import Autocomplete from 'components/inputs/Autocomplete';
import DatePicker from 'components/inputs/DatePicker';
import { getTimeWindowsSlots } from 'mockData/timeWindows';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';
import { timeWindowsFormatter } from 'utils/helpers/timeWindowsFormatter';

import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { IOption, TErrors } from 'interfaces';
import { ITime } from 'interfaces/create/createIssue';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ReasonModal from './ReasonModal';

import 'dayjs/locale/ru';
import './EditIssueAppointment.scss';

interface IFormValues {
    date?: Date | null;
    /* 09:00-14:00 */
    time?: string | null;
}

function EditIssueAppointment() {
    const issue = useSelector(issueSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<IFormValues | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
    const [timeWindow, setTimeWindow] = useState<{ date: string; time: ITime } | null>(null);

    const timeWindows = useMemo(() => getTimeWindowsSlots(issue?.help_type), [issue?.help_type]);

    function handleClose() {
        setIsOpen(false);
    }

    useEffect(() => {
        const dateString = issue?.time_windows[0]?.date;
        setFormValues({
            date: dateString ? new Date(dateString) : null,
            time: timeWindowsFormatter(issue?.time_windows).slice(11, 24) || null,
        });
        setErrors(null);
    }, [isOpen]);

    async function submitHandler() {
        if (!issue || !formValues) return;

        if (validator()) {
            try {
                setIsReasonModalOpen(true);
                setTimeWindow({
                    date: dayjs(formValues?.date!).format('YYYY-MM-DD'),
                    time: {
                        start_time: formValues?.time!.slice(0, 5) as string,
                        end_time: formValues?.time?.slice(8, 14) as string,
                    },
                });
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    function changeDateHandler(val: Date | null) {
        setFormValues({ ...(formValues || {}), date: val });
    }

    function changeTimeWindowHandler(option: IOption | null) {
        setFormValues({ ...(formValues || {}), time: (option?.value as string) || null });
    }

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.date) {
            errs.date = { message: 'Обязательное поле' };
        }

        if (!formValues?.time) {
            errs.time = { message: 'Обязательное поле' };
        }

        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    return (
        <div className="val-prop-iss">
            {timeWindowsFormatter(issue?.time_windows)}
            <IconButton
                className="edit-iss-prop-icon"
                onClick={() => setIsOpen(!isOpen)}
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box className="edit-issue-appointment-dialog-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменение даты и времени вызова</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div className="edit-issue-appointment-form-wrapper">
                        <DatePicker
                            name="date"
                            placeholder="ДД.ММ.ГГГГ"
                            label="Дата вызова*"
                            fullWidth
                            disablePast
                            errors={errors}
                            onChange={changeDateHandler}
                            defaultValue={dayjs(formValues?.date) || null}
                        />
                        <Autocomplete
                            name="time"
                            inpPlaceholder={'Время вызова*'}
                            margin="normal"
                            errors={errors}
                            options={timeWindows.map(({ startTime, endTime }) => {
                                return {
                                    value: `${startTime} - ${endTime}`,
                                    label: `${startTime} - ${endTime}`,
                                };
                            })}
                            onChange={changeTimeWindowHandler}
                            value={formValues?.time ? { label: formValues?.time, value: formValues?.time } : null}
                            isOptionEqualToValue={() => true}
                        />

                        <div className="buttons-wrapper">
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Отмена
                            </Button>

                            <LoadingButton
                                variant="contained"
                                loading={isLoading}
                                onClick={submitHandler}
                            >
                                Продолжить
                            </LoadingButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <ReasonModal
                isOpen={isReasonModalOpen}
                setIsOpen={setIsReasonModalOpen}
                setIsParentOpen={setIsOpen}
                timeWindow={timeWindow}
            />
        </div>
    );
}

export default memo(EditIssueAppointment);
