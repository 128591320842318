import { issuesApi } from 'api';
import { Button } from 'components/Button';
import { ERoutes } from 'enums/routes.enum';
import { fetchDeposits } from 'pages/accounting/deposits/store';
import { depositsSelector } from 'pages/accounting/deposits/store/selectors';
import { TSuggestedPrice } from 'pages/accounting/deposits/store/types';
import { useAppDispatch } from 'store/hooks';

import CloseIcon from '@mui/icons-material/Close';
import { Typography, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table } from './table';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const AccrualModal: FC<Props> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();

    const { depositId } = useSelector(depositsSelector);
    const [workerId, setWorkerId] = useState('');
    const [issueOid, setIssueOid] = useState(0);
    const [issueId, setIssueId] = useState('');

    const [suggestedPrices, setSuggestedPrices] = useState<
        (TSuggestedPrice & { commission_fixation: number; comission_percent: number })[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [sendLoading, setSendLoading] = useState(false);
    const [partnerName, setPartnerName] = useState<any>();

    const fetchDeposit = async () => {
        const { data } = await issuesApi.getDeposit(depositId).finally(() => {
            setLoading(false);
        });

        setSuggestedPrices(
            (data as any).prices_suggested.map((item: TSuggestedPrice) =>
                item.commission_type === 'fixation'
                    ? { ...item, commission_fixation: item.commission_amount }
                    : {
                          ...item,
                          commission_fixation: item.amount * item.commission_amount,
                          comission_percent: item.commission_amount,
                      },
            ),
        );
        setWorkerId(data.worker.id);
        setIssueOid(data.issue.oid);
        setIssueId(data.issue.id);
        setPartnerName(data.issue.company.name);
    };

    const handleSend = async () => {
        const data = {
            deposit_request_id: depositId,
            worker_id: workerId,
            commission_prices: suggestedPrices.map((item) => ({
                commission_amount: item.commission_amount.toFixed(2),
                commission_type: item.commission_type,
                issue_price_id: item.id,
            })),
        };

        setSendLoading(true);

        issuesApi
            .postDeposits({ items: [data] })
            .then(() => {
                onClose();
                enqueueSnackbar('Начисление успешно выполнено', { variant: 'success' });
                dispatch(fetchDeposits());
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => setSendLoading(false));
    };

    const handlePercentageChange = (id: string, value: string) => {
        setSuggestedPrices((prev) =>
            prev.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          commission_type: value,
                          commission_amount:
                              value === 'fixation' ? item.commission_fixation : item.comission_percent || 0,
                      }
                    : item,
            ),
        );
    };

    const handlePercentChange = (id: string, value: number) => {
        setSuggestedPrices((prev) =>
            prev.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          commission_amount: value,
                      }
                    : item,
            ),
        );
    };

    const handleAmountChange = (id: string, value: number) => {
        setSuggestedPrices((prev) =>
            prev.map((item) => (item.id === id ? { ...item, commission_amount: value } : item)),
        );
    };

    useEffect(() => {
        setSuggestedPrices([]);
        setLoading(true);

        if (!open) return;

        fetchDeposit();
    }, [open]);

    const totalAmount = suggestedPrices.reduce((acc, item) => {
        return item.commission_type === 'fixation'
            ? item.commission_amount + acc
            : item.amount * item.commission_amount + acc;
    }, 0);

    const error = suggestedPrices.some(
        (item) => item.commission_fixation !== 0 && item.commission_amount > item.amount,
    );

    const isChanged = suggestedPrices.some((item) =>
        item.commission_type === 'fixation'
            ? item.commission_amount !== item.commission_fixation
            : item.commission_amount !== item.comission_percent,
    );

    return (
        <Dialog
            transitionDuration={{ exit: 0, enter: 200 }}
            fullWidth
            open={open}
            sx={{
                '.MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '1300px',
                },
            }}
        >
            <Title sx={{ padding: '16px 24px' }}>
                Начисление по заявке{' '}
                <Link
                    to={`/${ERoutes.Issues}/${issueId}`}
                    target="_blank"
                >
                    №{issueOid}
                </Link>
            </Title>
            <CloseButton onClick={onClose}>
                <CloseIcon />
            </CloseButton>
            <DialogContent>
                <Table
                    rows={suggestedPrices}
                    onPercentageChange={handlePercentageChange}
                    onPercentChange={handlePercentChange}
                    onAmountChange={handleAmountChange}
                    loading={loading}
                    partnerName={partnerName}
                />

                {isChanged ? <Danger>Вы внесли изменения. Проверьте сумму.</Danger> : null}
            </DialogContent>
            <DialogActions sx={{ padding: '20px 24px' }}>
                <Sum>
                    Итого к начислению:{' '}
                    <NumericFormat
                        displayType="text"
                        value={totalAmount}
                        suffix=" ₽"
                        thousandSeparator=" "
                        decimalScale={2}
                    />
                </Sum>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <Button
                    loading={sendLoading}
                    disabled={error}
                    variant="contained"
                    onClick={handleSend}
                >
                    Начислить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Title = styled(DialogTitle)({
    fontSize: '24px',
    fontWeight: 600,
});

const Sum = styled(Typography)({
    fontSize: '20px',
    fontWeight: 500,
    marginRight: 'auto',
});

const CloseButton = styled(IconButton)({
    position: 'absolute',
    right: 8,
    top: 8,
    color: `${(theme: any) => theme.palette.grey[500]}`,
});

const Danger = styled('div')({
    marginTop: '25px',
    color: '#F56767',
    fontSize: '14px',
});
