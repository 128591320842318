import { ESideBarActions } from '../../enums/actions.enum';
import { ISideBarState, TSideBarActions } from '../../interfaces/sideBar';

const initState: ISideBarState = {
    isOpen: localStorage.getItem('isHideSideBar') === 'true' ? false : true,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const sideBarReducer = (state = initialState, action: TSideBarActions): ISideBarState => {
    switch (action.type) {
        case ESideBarActions.SetIsOpen: {
            localStorage.setItem('isHideSideBar', (!action.payload).toString());
            return { ...state, isOpen: action.payload };
        }
        default:
            return state;
    }
};
