import { Divider, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { EPricesTabs } from '../../../enums/prices.enum';
import { pricesTabsMock } from '../../../mockData/prices';
import { setPricesTabAction } from '../../../store/actions/prices';
import { useAppDispatch } from '../../../store/hooks';
import { pricesTabSelector } from '../../../store/selectors/pricesSelectors';

function PricesTabs() {
    const dispatch = useAppDispatch();
    const priceTabs = useSelector(pricesTabSelector);

    const handleChangeTab = (event: React.SyntheticEvent, tabValue: EPricesTabs) => {
        dispatch(setPricesTabAction(tabValue));
    };

    return (
        <>
            <Tabs
                sx={{ marginTop: '-10px' }}
                value={priceTabs}
                onChange={handleChangeTab}
            >
                {Object.entries(pricesTabsMock).map(([key, value]) => (
                    <Tab
                        key={key}
                        value={key}
                        label={value}
                    />
                ))}
            </Tabs>
            <Divider />
        </>
    );
}

export default PricesTabs;
