import { Box, styled } from '@mui/material';

export const CloseIconWrapper = styled(Box)({
    position: 'absolute',
    top: '15px',
    right: '20px',
    cursor: 'pointer',
});

export const SubTitle = styled('div')({
    color: '#878EA5',
    fontSize: '14px',
});

export const ButtonsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    marginTop: '20px',
});
