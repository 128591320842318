import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    cancelOrderThunk,
    deleteOrderLossThunk,
    deleteOrderMoveThunk,
    getOrdersThunk,
    setIsOpenCancelOrderAction,
    setOrderAction,
    setSetSelectedOrdersAction,
} from '../../../../../store/actions/orders';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import { cancelOrderInfo, orderSelector, selectedOrdersSelector } from '../../../../../store/selectors/ordersSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

function CancelOrder() {
    const dispatch = useAppDispatch();

    const { isOpenCancelOrder, cancelOrderType } = useSelector(cancelOrderInfo);
    const order = useSelector(orderSelector);
    const issue = useSelector(issueSelector);
    const selectedOrders = useSelector(selectedOrdersSelector);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpenCancelOrder) {
            dispatch(setOrderAction(null));
        }
    }, [isOpenCancelOrder]);

    const orderNames = () => {
        switch (true) {
            case !!order: {
                return [order!.spare_part.name];
            }
            case !!selectedOrders && !!selectedOrders.length: {
                return selectedOrders!.map((selectedOrder) => selectedOrder.spare_part.name) as string[];
            }
            default:
                return [''];
        }
    };

    function onCloseHandler() {
        dispatch(setIsOpenCancelOrderAction({ isOpen: false, type: null }));
    }

    async function submitFormHandler() {
        try {
            setIsLoading(true);
            switch (true) {
                case !!order: {
                    if (cancelOrderType === 'loss') {
                        await dispatch(
                            deleteOrderLossThunk({ spare_part_order_ids: [order!.id] }, order!.moysklad_loss.id),
                        );
                    } else if (cancelOrderType === 'move') {
                        await dispatch(
                            deleteOrderMoveThunk({ spare_part_order_ids: [order!.id] }, order!.moysklad_move.id),
                        );
                    } else {
                        await dispatch(cancelOrderThunk({ order_ids: [order!.id] }));
                    }

                    await dispatch(getOrdersThunk({ issue_id: order!.issue_id }));
                    onCloseHandler();
                    break;
                }
                case !!selectedOrders && !!selectedOrders.length && !!issue: {
                    const order_ids = selectedOrders!.map((selectedOrder) => selectedOrder.id);
                    const spare_part_order_ids = selectedOrders!.map((selectedOrder) => selectedOrder.spare_part.id);

                    if (cancelOrderType === 'loss') {
                        await dispatch(deleteOrderLossThunk({ spare_part_order_ids }, order!.moysklad_loss.id));
                    } else if (cancelOrderType === 'move') {
                        await dispatch(deleteOrderMoveThunk({ spare_part_order_ids }, order!.moysklad_move.id));
                    } else {
                        await dispatch(cancelOrderThunk({ order_ids }));
                    }

                    await dispatch(getOrdersThunk({ issue_id: issue!.id }));
                    dispatch(setSetSelectedOrdersAction(null));
                    onCloseHandler();
                    break;
                }
                default:
                    console.error('submitFormHandler parts orders "CancelOrder"');
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Dialog
            open={isOpenCancelOrder}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div
                        className="close-icon"
                        style={{ position: 'absolute', right: '15px', cursor: 'pointer' }}
                    >
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <ErrorOutlineIcon
                        color="error"
                        fontSize="large"
                        sx={{ marginRight: '15px' }}
                    />
                    <Typography
                        variant="h6"
                        sx={{ marginRight: '30px' }}
                    >
                        Вы уверены, что хотите отказаться от <strong>{orderNames().join(', ')}</strong> ?
                    </Typography>
                </Box>
                <DialogContent>
                    <DialogActions>
                        <Box mt={2}>
                            <Button
                                onClick={onCloseHandler}
                                color="inherit"
                                variant="contained"
                            >
                                Нет
                            </Button>

                            <LoadingButton
                                onClick={submitFormHandler}
                                loading={isLoading}
                                variant="contained"
                                color="error"
                                sx={{ ml: 1 }}
                            >
                                Да
                            </LoadingButton>
                        </Box>
                    </DialogActions>
                </DialogContent>
            </DialogTitle>
        </Dialog>
    );
}

export default CancelOrder;
