import { priceListsApi, servicesApi } from 'api';
import Loader from 'components/Loader';
import { FilterIn } from 'features/filter-in';
import { FilterIs } from 'features/filter-is';
import MainLayout from 'layouts/MainLayout';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import { Search } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { AxiosPromise } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { CreateService } from './components/create-service';
import { EditService } from './components/edit-service';
import { ServiceItem } from './components/service-item';
import { fetchServices, setFilters, setPagination, setServiceById } from './store';
import { MainPaper, MuiDrawer, Container, ControlsWrapper, FiltersWrapper, Pagination } from './styles';

const rootItems = [
    { value: true, title: 'Да' },
    { value: false, title: 'Нет' },
];

const parentItems = [
    { value: true, title: 'Есть' },
    { value: false, title: 'Нет' },
];

type TFilterTypes = 'category_id__in' | 'service_type_id__in' | 'company_id__in';

export const ServicesPage = () => {
    const dispatch = useAppDispatch();

    const { services, filters, loading, pagination } = useSelector(servicesSelector);

    const [isOpenAddModal, setOpenAddModal] = useState<any>(false);
    const [isOpenEditModal, setOpenEditModal] = useState<any>(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        dispatch(fetchServices());
    }, []);

    useEffect(() => {
        if (!isOpenEditModal && isEditing) {
            dispatch(fetchServices());
            setIsEditing(false);
        }
    }, [isOpenEditModal, isEditing]);

    const filterSearch = (search: string) => {
        dispatch(setPagination({ page: 1 }));
        dispatch(setFilters({ search }));
        dispatch(fetchServices());
    };

    const filterSearchWithDebounced = useDebouncedCallback(filterSearch, 800);

    const converter = async (func: any, params: any) => {
        const res = await func({ size: 20, ...params });
        return { data: { items: res.data, total: res.data.length } };
    };

    const handleChangeFilter = (itemId: string, filterType: TFilterTypes) => {
        const getIds = () => {
            if (filters[filterType].includes(itemId as never)) {
                return filters[filterType].filter((id: string) => id !== itemId);
            } else {
                return [...filters[filterType], itemId];
            }
        };

        dispatch(setPagination({ page: 1 }));
        dispatch(setFilters({ [filterType]: getIds() }));
        dispatch(fetchServices());
    };

    const handleFilterClean = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchServices());
    };

    const onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setPagination({ size: +e.target.value }));
        dispatch(fetchServices());
    };

    const onPageChange = (_: any, page: number) => {
        dispatch(setPagination({ page: page + 1 }));
        dispatch(fetchServices());
    };

    const handleChangeFilterIs = (key: 'is_root' | 'has_parents', value: boolean | null) => {
        dispatch(setPagination({ page: 1 }));
        dispatch(setFilters({ [key]: value }));
        dispatch(fetchServices());
    };

    return (
        <MainLayout>
            <Container>
                <ControlsWrapper>
                    <FiltersWrapper>
                        <TextField
                            placeholder="Поиск"
                            variant="outlined"
                            size="small"
                            onChange={(e) => filterSearchWithDebounced(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            defaultValue={filters?.search}
                        />
                        <FilterIn
                            title="Компания"
                            onChange={(itemId) => handleChangeFilter(itemId, 'company_id__in')}
                            activeIds={filters.company_id__in}
                            fetcher={(params) => converter(priceListsApi.getCompanies, params) as AxiosPromise}
                            cleaner={() => handleFilterClean('company_id__in')}
                        />
                        <FilterIn
                            withoutSearch
                            title="Тип услуги"
                            onChange={(itemId) => handleChangeFilter(itemId, 'service_type_id__in')}
                            activeIds={filters.service_type_id__in}
                            fetcher={(params) => converter(servicesApi.getServiceTypes, params) as AxiosPromise}
                            cleaner={() => handleFilterClean('service_type_id__in')}
                        />
                        <FilterIn
                            title="Категория"
                            onChange={(itemId) => handleChangeFilter(itemId, 'category_id__in')}
                            activeIds={filters.category_id__in}
                            fetcher={(params) => servicesApi.getCategories(params)}
                            cleaner={() => handleFilterClean('category_id__in')}
                        />
                        <FilterIs
                            title="Основная"
                            options={rootItems}
                            activeOption={
                                rootItems.find((item) => item.value === filters.is_root) ?? { title: '', value: null }
                            }
                            onChange={(value) => handleChangeFilterIs('is_root', value)}
                        />
                        <FilterIs
                            title="Родители"
                            options={parentItems}
                            activeOption={
                                parentItems.find((item) => item.value === filters.has_parents) ?? {
                                    title: '',
                                    value: null,
                                }
                            }
                            onChange={(value) => handleChangeFilterIs('has_parents', value)}
                        />
                    </FiltersWrapper>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            dispatch(setServiceById(null));
                            setOpenAddModal(e.currentTarget);
                        }}
                    >
                        Создать услугу
                    </Button>
                    <MuiDrawer
                        open={Boolean(isOpenAddModal)}
                        anchor="right"
                        onClose={() => setOpenAddModal(false)}
                    >
                        <CreateService
                            setOpenEditModal={setOpenEditModal}
                            setOpen={setOpenAddModal}
                        />
                    </MuiDrawer>
                </ControlsWrapper>
                <MainPaper>
                    <div>
                        {loading ? (
                            <Loader />
                        ) : (
                            services.map((item) => (
                                <ServiceItem
                                    key={item.id}
                                    setOpenAddModal={setOpenAddModal}
                                    setOpenEditModal={setOpenEditModal}
                                    item={item}
                                />
                            ))
                        )}
                    </div>

                    <Pagination
                        rowsPerPageOptions={[5, 25, 50, 100]}
                        count={pagination.total}
                        rowsPerPage={pagination.size}
                        page={pagination.page - 1}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </MainPaper>
                <MuiDrawer
                    open={Boolean(isOpenEditModal)}
                    anchor="right"
                    onClose={() => setOpenEditModal(false)}
                >
                    <EditService
                        setIsEditing={setIsEditing}
                        setOpenEdit={setOpenEditModal}
                    />
                </MuiDrawer>
            </Container>
        </MainLayout>
    );
};
