import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';

import { ButtonsContainer, CloseIconWrapper, SubTitle } from '../styles';

type Props = {
    isOpenConfirm: boolean;
    setOpenConfirm: (value: boolean) => void;
    deleteComment: () => void;
};

export const Confirm: FC<Props> = ({ isOpenConfirm, setOpenConfirm, deleteComment }) => {
    return (
        <Dialog
            open={isOpenConfirm}
            sx={{
                '.MuiDialog-paper': {
                    width: '380px',
                    borderRadius: '8px',
                    position: 'relative',
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h5">Подтвердить действие</Typography>
                <CloseIconWrapper onClick={() => setOpenConfirm(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent>
                <SubTitle>Вы уверены, что хотите удалить комментарий?</SubTitle>
                <ButtonsContainer>
                    <Button
                        onClick={() => setOpenConfirm(false)}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <Button
                        onClick={deleteComment}
                        variant="contained"
                    >
                        Удалить
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
