import InfoIcon from '@mui/icons-material/Info';
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { ECommissionsType } from '../../../../../enums/prices.enum';
import { IIssuePrice, IIssuePriceDetail, TPriceRecipient } from '../../../../../interfaces/issues';
import { IPrice } from '../../../../../interfaces/prices';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import priceFormatter from '../../../../../utils/helpers/priceFormatter';
import './PricesTable.scss';

function getMoney(amount: number): string {
    if (!amount || Number.isNaN(amount)) return '0';
    return amount?.toLocaleString();
}

type IProps = {
    price: IIssuePrice | null;
    priceType: TPriceRecipient | 'both';
};

function PricesTable({ price, priceType }: IProps) {
    const issue = useSelector(issueSelector);
    const categoryIds: Set<string> = new Set(issue?.sub_issues?.flatMap((i) => i.home_device.category?.id || []) ?? []);

    const amountsSumRecipient = (prices: IIssuePriceDetail[]) => prices.reduce((acc, cur) => cur.amount + acc, 0);
    const valuesSumRecipient = (prices: IIssuePriceDetail[]) =>
        prices.reduce((acc, cur) => cur.original_amount + acc, 0);

    const data: { totalAmount: string; totalDiscount: string; priceDetails: any[] } = {
        totalAmount: '',
        totalDiscount: '',
        priceDetails: [],
    };
    switch (priceType) {
        case 'both': {
            data.totalAmount = getMoney(Number(price?.total_discount_amount.toFixed(2)));
            data.totalDiscount = getMoney(
                Number((Number(price?.total_amount) - Number(price?.total_discount_amount)).toFixed(2)),
            );
            data.priceDetails = price?.details || [];
            break;
        }
        case 'client': {
            const clientPrices = price?.details?.filter((pr) => pr.price_recipient === 'client') || [];

            const amountSumClient = Number(amountsSumRecipient(clientPrices).toFixed(2));
            const valuesSumClient = valuesSumRecipient(clientPrices);

            data.totalAmount = getMoney(Number(amountSumClient));
            data.totalDiscount = getMoney(Number(valuesSumClient - amountSumClient));
            data.priceDetails = clientPrices;
            break;
        }
        case 'partner': {
            const partnerPrices = price?.details?.filter((pr) => pr.price_recipient === 'partner') || [];

            const amountSumPartner = Number(amountsSumRecipient(partnerPrices).toFixed(2));
            const valuesSumPartner = valuesSumRecipient(partnerPrices);

            data.totalAmount = getMoney(Number(amountSumPartner));
            data.totalDiscount = getMoney(Number(valuesSumPartner - amountSumPartner));
            data.priceDetails = partnerPrices;
            break;
        }
        default: {
            console.log('default: ', data);
        }
    }

    const tableColumns: { id: number; align: 'left' | 'right'; title: string; dataKey: string }[] = [
        {
            id: 1,
            align: 'left',
            title: 'Услуга',
            dataKey: 'name',
        },
        {
            id: 2,
            align: 'left',
            title: 'Цена',
            dataKey: 'priceAmount',
        },
        {
            id: 3,
            align: 'left',
            title: 'Скидка',
            dataKey: 'discount',
        },
        {
            id: 4,
            align: 'right',
            title: 'Сумма',
            dataKey: 'amount',
        },
    ];

    const checkValidPriceCategory = (item: IPrice): boolean => {
        const categories: string[] = item.categories?.map((i) => i.id) || [];
        if (!categories.length) return true;
        return categories.some((i) => categoryIds.has(i));
    };

    return (
        <TableContainer className="price-table">
            <Table sx={{ minWidth: 400 }}>
                <TableHead>
                    <TableRow>
                        {tableColumns.map((column) => (
                            <TableCell
                                key={column.id}
                                className="table-head-item"
                                align={column.align}
                            >
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.priceDetails.map((detail) => {
                        return (
                            <TableRow key={detail.id}>
                                {tableColumns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        className={cn('table-cell', column.dataKey === 'discount' && 'discount')}
                                        align={column.align}
                                    >
                                        {column.dataKey === 'priceAmount' &&
                                            priceFormatter(Number(detail.original_amount))}
                                        {column.dataKey === 'amount' && priceFormatter(Number(detail.amount))}
                                        {column.dataKey === 'name' && (
                                            <div className="table-cell-name">
                                                {!checkValidPriceCategory(detail.price) ? (
                                                    <span
                                                        className="warning"
                                                        title="Категории услуги не соответствуют технике в заявке"
                                                    >
                                                        <InfoIcon />
                                                    </span>
                                                ) : null}
                                                {detail.price.name}{' '}
                                                {detail.price.is_additional ? (
                                                    <span className="additional"> (доп. услуга)</span>
                                                ) : null}
                                            </div>
                                        )}
                                        {column.dataKey === 'discount' &&
                                            `${
                                                detail.discount_type === ECommissionsType.Percentage
                                                    ? `${detail.discount ? detail.discount * 100 : 0} %`
                                                    : `${detail.discount || 0} ₽`
                                            }`}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div className="table-caption">
                <Typography sx={{ color: '#8A92A6' }}>Сумма скидки</Typography>
                <Typography
                    color="error"
                    sx={{ fontSize: '16px' }}
                >
                    {data.totalDiscount} ₽
                </Typography>
            </div>
            <Divider />
            <div className="table-caption">
                <Typography>Итого</Typography>
                <Typography
                    color="green"
                    fontWeight="bold"
                >
                    {data.totalAmount} ₽
                </Typography>
            </div>
        </TableContainer>
    );
}

export default memo(PricesTable);
