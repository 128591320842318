import { getSearchThunk } from 'store/actions/search';
import { useAppDispatch } from 'store/hooks';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Addresses from './Addresses';
import Clients from './Clients';
import IssueCodes from './IssueCodes';
import Oids from './Oids';
import SellerCodes from './SellerCodes';

import { EIssueFilter } from '../../../../../enums/search.enum';
import {
    filtersSearchSelector,
    indiciesSearchSelector,
    itemsSearchSelector,
    pageItemsSearchSelector,
    textSearchSelector,
    totalItemsSearchSelector,
} from '../../../../../store/selectors/searchSelectors';

import './SearchItemsStyles.scss';

function SearchItems() {
    const dispatch = useAppDispatch();

    const items = useSelector(itemsSearchSelector);
    const total = useSelector(totalItemsSearchSelector);
    const searchFilters = useSelector(filtersSearchSelector);
    const indicies = useSelector(indiciesSearchSelector);
    const text = useSelector(textSearchSelector);
    const page = useSelector(pageItemsSearchSelector);

    const lastItemRef = useRef<HTMLDivElement | null>(null);

    const handleSearch = useCallback(async () => {
        try {
            if (!searchFilters || !text || !indicies) {
                return;
            }
            await dispatch(getSearchThunk({ filters: searchFilters, text, indicies, page: page + 1 }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }, [searchFilters, text, indicies, page]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && total && page < Math.ceil(total / 100)) {
                    handleSearch();
                }
            },
            {
                rootMargin: '100px',
            },
        );

        if (lastItemRef.current) {
            observer.observe(lastItemRef.current);
        }

        return () => {
            if (lastItemRef.current) {
                observer.unobserve(lastItemRef.current);
            }
        };
    }, [page, total, handleSearch]);

    return (
        <div className="search-items">
            <Typography
                fontSize={14}
                fontWeight={400}
                color="#ADB5BD"
            >
                Результаты поиска ({total})
            </Typography>
            <Box className="items-list">
                {(() => {
                    if (!searchFilters || !items) return null;
                    const { issue_filters } = searchFilters;
                    switch (issue_filters) {
                        case EIssueFilter.Oid:
                            return <Oids items={items} />;
                        case EIssueFilter.Client:
                            return <Clients items={items} />;
                        case EIssueFilter.Address:
                            return <Addresses items={items} />;
                        case EIssueFilter.SellerCode:
                            return <SellerCodes items={items} />;
                        case EIssueFilter.IssueCode:
                            return <IssueCodes items={items} />;
                        default:
                            return null;
                    }
                })()}
                <div ref={lastItemRef} />
            </Box>
        </div>
    );
}

export default SearchItems;
