import { DriveFileRenameOutlineOutlined, NoteAddOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import PriceDialogForm from './PriceDialogForm';

import { getCompaniesThunk } from '../../../store/actions/companies';
import {
    setAdditionalPricesDialogAction,
    setCategoriesPriceDialogAction,
    setErrorsPriceDialogAction,
    setFormValuesPriceDialogAction,
    setIsOpenPriceDialogAction,
    setPriceIdPriceDialogAction,
    setSelectedAdditionalPricesPriceDialogAction,
    setSelectedCategoriesPriceDialogAction,
} from '../../../store/actions/priceDialog';
import { getPriceThunk } from '../../../store/actions/prices';
import { useAppDispatch } from '../../../store/hooks';
import { dialogStatePriceSelector, priceIdPriceSelector } from '../../../store/selectors/priceDialogSelector';
import { priceSelector } from '../../../store/selectors/pricesSelectors';
import { priceServiceSelector } from '../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';
import './PriceDialog.scss';

function PriceDialog() {
    const dispatch = useAppDispatch();

    const { isOpen, isEdit, isAdditional } = useSelector(dialogStatePriceSelector);
    const price = useSelector(priceSelector);
    const service = useSelector(priceServiceSelector);
    const priceId = useSelector(priceIdPriceSelector);

    useEffect(() => {
        async function handle() {
            if (isEdit && price) {
                await dispatch(
                    setFormValuesPriceDialogAction({
                        service_id: price?.service?.id,
                        name: price.name,
                        amount: price.amount,
                        description: price.description,
                        is_additional: price.is_additional,
                        included_in_price: price.included_in_price,
                        excluded_in_price: price.excluded_in_price,
                        category_ids: price.categories?.map(({ id }) => id),
                        additional_prices: price.additional_prices?.map(({ id }) => id),
                        is_active_on_widget: price.is_active_on_widget,
                        partner_id: price.partner_id,
                    }),
                );
                await dispatch(setSelectedCategoriesPriceDialogAction(price.categories));
                await dispatch(setSelectedAdditionalPricesPriceDialogAction(price.additional_prices));
            }
        }
        handle();
    }, [isEdit, price, service]);

    useEffect(() => {
        if (!isOpen) {
            dispatch(setCategoriesPriceDialogAction(null));
            dispatch(setAdditionalPricesDialogAction(null));
            dispatch(setErrorsPriceDialogAction(null));
            dispatch(setFormValuesPriceDialogAction(null));
            dispatch(setPriceIdPriceDialogAction(null));
            dispatch(setSelectedCategoriesPriceDialogAction(null));
            dispatch(setSelectedAdditionalPricesPriceDialogAction(null));
        }
    }, [isOpen]);

    useEffect(() => {
        async function handle() {
            try {
                if (!priceId) return;
                await dispatch(getPriceThunk(priceId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, [priceId]);

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getCompaniesThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, []);

    const dialogLabel = useMemo(() => {
        switch (true) {
            case isEdit:
                return 'Изменение услуги';
            case isAdditional:
                return 'Новая основная услуга';
            default:
                return 'Новая дополнительная услуга';
        }
    }, [isEdit, isAdditional]);

    function onClose() {
        dispatch(setIsOpenPriceDialogAction({ isEdit: false, isOpen: false }));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="new-price-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    {isEdit ? (
                        <DriveFileRenameOutlineOutlined
                            color="primary"
                            sx={{ marginRight: '30px' }}
                            fontSize="large"
                        />
                    ) : (
                        <NoteAddOutlined
                            color="primary"
                            sx={{ marginRight: '30px' }}
                            fontSize="large"
                        />
                    )}
                    <Typography variant="h6">{dialogLabel}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <PriceDialogForm
                    isEdit={isEdit}
                    isAdditional={isAdditional || false}
                />
            </DialogContent>
        </Dialog>
    );
}

export default PriceDialog;
