import { historyApi } from 'api';
import { createSlice } from 'store/hooks';

import { PayloadAction } from '@reduxjs/toolkit';

import { TState } from './types';

export const initialState: TState = {
    commentsHistory: [],

    chooseDelete: null,

    loading: false,
};

const slice = createSlice({
    name: 'commentsHistory',
    initialState,
    reducers: (create) => ({
        fetchHistoryComments: create.asyncThunk(
            async (issueId, { dispatch }) => {
                const { data } = await historyApi.getHistoryAndComments(issueId);

                dispatch(setCommentsHistory(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setCommentsHistory: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.commentsHistory = payload;
        }),

        setChooseDelete: create.reducer((state, { payload }: PayloadAction<string | null>) => {
            state.chooseDelete = payload;
        }),
    }),
});

export const { fetchHistoryComments, setCommentsHistory, setChooseDelete } = slice.actions;

export default slice.reducer;
