import { ESearchActions } from '../../enums/actions.enum';
import { ISearchState, TSearchActions } from '../../interfaces/search';

const initState: ISearchState = {
    items: null,
    total: 0,
    page: 1,
    filters: null,
    indicies: null,
    text: null,
    isOpen: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const searchReducer = (state = initialState, action: TSearchActions): ISearchState => {
    switch (action.type) {
        case ESearchActions.SetItems: {
            return { ...state, items: [...(state.items || []), ...(action.payload || [])] };
        }
        case ESearchActions.SetTotal: {
            return { ...state, total: action.payload };
        }
        case ESearchActions.SetPage: {
            return { ...state, page: action.payload };
        }
        case ESearchActions.SetFilters: {
            return { ...state, filters: action.payload, items: null, page: 1, total: 0 };
        }
        case ESearchActions.SetIndicies: {
            return { ...state, indicies: action.payload, items: null, page: 1, total: 0 };
        }
        case ESearchActions.SetText: {
            return { ...state, text: action.payload, items: null, page: 1, total: 0 };
        }
        case ESearchActions.SetIsOpen: {
            return { ...state, isOpen: action.payload };
        }
        default:
            return state;
    }
};
