import { priceListsApi, servicesApi } from 'api';
import { fetchServiceById, fetchServices } from 'pages/services/store';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';
import { Option, selectMapper } from 'utils/helpers/selectMapper';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import {
    ButtonsContainer,
    ButtonsWrapper,
    CheckBox,
    CheckBoxWrapper,
    CheckTitle,
    MuiTextField,
    SubTitle,
} from './styles';

import { FootNote } from '../../footnote';
import { Cell, Field, HeadCell, MuiAutocomplete, Asterisk, FieldContainer, PlusButton } from '../styles';

type TForm = {
    parents: string[];
    is_root: boolean;
    children: string[];
    description: string;
    name: string;
    service_types: string[];
    categories: string[];
    company_ids: string[];
    is_active: boolean;
};

const initialStateForm: TForm = {
    parents: [],
    is_root: true,
    children: [],
    description: '',
    name: '',
    service_types: [],
    categories: [],
    company_ids: [],
    is_active: true,
};

type Props = {
    setOpen: (value: boolean) => void;
    setOpenEditModal: (value: boolean) => void;
};

export const Basics: FC<Props> = ({ setOpen, setOpenEditModal }) => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);

    const [form, setForm] = useState({
        ...initialStateForm,
        service_types: serviceById?.service_types?.map((item) => item.id),
        categories: serviceById?.categories?.map((item) => item.id),
        company_ids: serviceById?.companies?.map((item) => item.id),
    });

    const [isParentInit, setIsParentInit] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [childServices, setChildServices] = useState<any[]>([]);
    const [addChildService, setAddChildService] = useState<any>();

    const [mainServices, setMainServices] = useState<Option[]>();
    const [childrenServices, setChildrenServices] = useState<Option[]>();
    const [categories, setCategories] = useState<Option[]>();
    const [serviceTypes, setServiceTypes] = useState<Option[]>();
    const [companies, setCompanies] = useState<Option[]>();

    const handleConfirm = () => {
        if (!addChildService.name) return;

        setChildServices((prev) => [...prev, addChildService]);
        setAddChildService({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddChildService({});
        setVisible(false);
    };

    const handleSearchServices = (value: string) => {
        servicesApi.getServices({ search: value }).then(({ data }) => {
            setChildrenServices(data.items.map(selectMapper));
        });
    };

    const handleSearchServicesDebounced = useDebouncedCallback(handleSearchServices, 700);

    const handleSearchParentServices = (value: string) => {
        setMainServices([]);
        servicesApi.getServices({ search: value }).then(({ data }) => {
            setMainServices(data.items.map(selectMapper));
        });
    };

    const handleSearchParentServicesDebounced = useDebouncedCallback(handleSearchParentServices, 700);

    const handleCreateService = () => {
        servicesApi
            .createService(form)
            .then(({ data }) => {
                enqueueSnackbar('Услуга создана', { variant: 'success' });
                dispatch(fetchServices());

                dispatch(fetchServiceById(data.id)).then(() => {
                    setOpenEditModal(true);
                    setOpen(false);
                });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    useEffect(() => {
        setForm((prev: any) => {
            return { ...prev, children: childServices };
        });
    }, [childServices]);

    const fetchAutocompleteData = async () => {
        const [serviceTypesRes, categoriesRes, companiesRes] = await Promise.all([
            servicesApi.getServiceTypes(),
            servicesApi.getCategories({}),
            priceListsApi.getCompanies({}),
        ]);

        setCategories(categoriesRes.data.items.map(selectMapper));
        setServiceTypes(serviceTypesRes.data.map(selectMapper));
        setCompanies(companiesRes.data.map(selectMapper));
    };

    const fetchMainServices = useCallback(async () => {
        if (!isParentInit) {
            const { data } = await servicesApi.getServices({});
            setMainServices(data.items.map(selectMapper));
            setIsParentInit(true);
        }
    }, [isParentInit]);

    useEffect(() => {
        fetchAutocompleteData();

        if (serviceById) {
            inputRef.current = [{ label: serviceById?.name, value: serviceById?.id }];
            setForm((prev: any) => {
                return { ...prev, parents: [serviceById?.id] };
            });
        }
    }, []);

    useEffect(() => {
        setForm((prev: any) => {
            return { ...prev, children: childServices };
        });
    }, [childServices]);

    const defaultCategories = serviceById?.categories?.map((item) => ({ label: item.name, value: item.id }));
    const defaultServicesTypes = serviceById?.service_types?.map((item) => ({ label: item.name, value: item.id }));
    const defaultCompanies = serviceById?.companies?.map((item) => ({ label: item.name, value: item.id }));

    const inputRef = useRef<Option[]>([]);

    return (
        <Box>
            <FieldContainer>
                <div>
                    <Field>
                        <SubTitle>
                            Родители<Asterisk>*</Asterisk>
                        </SubTitle>
                        <MuiAutocomplete
                            multiple
                            size="small"
                            loading
                            popupIcon={<ExpandMoreIcon />}
                            options={mainServices ?? []}
                            isOptionEqualToValue={(option, value) => option === value}
                            filterSelectedOptions
                            onFocus={fetchMainServices}
                            renderInput={(params) => (
                                <>
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                </>
                            )}
                            onInputChange={(_, value) => {
                                handleSearchParentServicesDebounced(value);
                            }}
                            onChange={(_, value) => {
                                const options = value as Option[];

                                inputRef.current = options;

                                const parents = options?.map((option) => option.value);

                                setForm((prev: any) => {
                                    return { ...prev, parents };
                                });
                            }}
                            value={inputRef.current}
                        />
                    </Field>
                    <Field sx={{ marginTop: '10px' }}>
                        <SubTitle />
                        <CheckBox
                            defaultChecked
                            onClick={() => {
                                setForm((prev: any) => {
                                    return { ...prev, is_root: !prev.is_root };
                                });
                            }}
                        />
                        <CheckTitle>Основная</CheckTitle>
                    </Field>
                </div>
                <Field>
                    <SubTitle>
                        Наименование<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        multiline
                        onChange={(e) =>
                            setForm((prev: any) => {
                                return { ...prev, name: e.target.value };
                            })
                        }
                        size="small"
                    />
                </Field>
                <Field>
                    <SubTitle>Описание</SubTitle>
                    <MuiTextField
                        multiline
                        minRows={4}
                        onChange={(e) =>
                            setForm((prev: any) => {
                                return { ...prev, description: e.target.value };
                            })
                        }
                    />
                </Field>
                <Field>
                    <SubTitle>Компании</SubTitle>
                    <MuiAutocomplete
                        loading
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={companies ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Компании"
                            />
                        )}
                        onChange={(_, value) => {
                            const options = value as Option[];

                            const company_ids = options?.map((option) => option.value);

                            setForm((prev: any) => {
                                return {
                                    ...prev,
                                    company_ids,
                                };
                            });
                        }}
                        defaultValue={defaultCompanies ?? []}
                    />
                </Field>
                <Field>
                    <SubTitle>Типы услуг</SubTitle>
                    <MuiAutocomplete
                        loading
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={serviceTypes ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Типы услуг"
                            />
                        )}
                        onChange={(_, value) => {
                            const options = value as Option[];

                            const service_types = options?.map((option) => option.value);

                            setForm((prev: any) => {
                                return {
                                    ...prev,
                                    service_types,
                                };
                            });
                        }}
                        defaultValue={defaultServicesTypes ?? []}
                    />
                </Field>
                <Field>
                    <SubTitle>Категории</SubTitle>
                    <MuiAutocomplete
                        loading
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={categories ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Категории"
                            />
                        )}
                        onInputChange={(_, value) => {
                            servicesApi.getCategories({ search: value }).then(({ data }) => {
                                setCategories(data.items.map(selectMapper));
                            });
                        }}
                        onChange={(_, value) => {
                            const options = value as Option[];

                            const categories = options?.map((option) => option.value);

                            setForm((prev: any) => {
                                return {
                                    ...prev,
                                    categories,
                                };
                            });
                        }}
                        defaultValue={defaultCategories ?? []}
                    />
                </Field>
                <Field sx={{ marginTop: '10px' }}>
                    <SubTitle>Активная</SubTitle>
                    <CheckBoxWrapper>
                        <CheckBox
                            onChange={() =>
                                setForm((prev: any) => {
                                    return { ...prev, is_active: !prev.is_active };
                                })
                            }
                            defaultChecked={form?.is_active}
                        />
                        <FootNote />
                    </CheckBoxWrapper>
                </Field>
            </FieldContainer>
            <Box sx={{ marginTop: '28px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Дочерние услуги</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <HeadCell sx={{ width: '450px' }}>НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                        <HeadCell sx={{ width: '450px' }}>ВХОДИТ В СТОИМОСТЬ</HeadCell>
                        <HeadCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {childServices.map((child) => {
                        return (
                            <TableRow key={child.id}>
                                <Cell>{child.name}</Cell>
                                <Cell>
                                    <Checkbox
                                        onChange={() => {
                                            const newArray = childServices.map((item) =>
                                                item.id === child.id
                                                    ? { ...item, is_include_in_price: !item.is_include_in_price }
                                                    : item,
                                            );
                                            setChildServices(newArray);
                                        }}
                                        checked={child.is_include_in_price}
                                    />
                                </Cell>
                                <Cell>
                                    <Button
                                        onClick={() => {
                                            setChildServices((prev) => {
                                                return prev.filter((el) => el.id !== child.id);
                                            });
                                        }}
                                    >
                                        <DeleteBasket />
                                    </Button>
                                </Cell>
                            </TableRow>
                        );
                    })}

                    {isVisible ? (
                        <TableRow>
                            <Cell>
                                <Autocomplete
                                    size="small"
                                    sx={{ width: '260px' }}
                                    popupIcon={<ExpandMoreIcon />}
                                    disabled={!childrenServices}
                                    options={childrenServices ?? []}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выберите услугу"
                                        />
                                    )}
                                    onInputChange={(_, value) => {
                                        handleSearchServicesDebounced(value);
                                    }}
                                    onChange={(_, value) => {
                                        const isInclude = childServices.find((child) => child.id === value?.value);
                                        if (isInclude) {
                                            enqueueSnackbar('Невозможно добавить две одинаковые услуги', {
                                                variant: 'error',
                                            });
                                        } else {
                                            setAddChildService({
                                                id: value?.value,
                                                name: value?.label,
                                                is_include_in_price: false,
                                            });
                                        }

                                        servicesApi.getServices({}).then(({ data }) => {
                                            setChildrenServices(data.items.map(selectMapper));
                                        });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <Checkbox
                                    onChange={(e) => {
                                        setAddChildService((prev: any) => {
                                            return { ...prev, is_include_in_price: e.target.checked };
                                        });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <ButtonsContainer>
                                    <div
                                        onClick={handleConfirm}
                                        style={{ padding: 0 }}
                                    >
                                        <Confirm isDisabled={!addChildService?.name} />
                                    </div>
                                    <div onClick={handleCancel}>
                                        <Cancel />
                                    </div>
                                </ButtonsContainer>
                            </Cell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <PlusButton
                                onClick={() => setVisible(true)}
                                startIcon={<AddIcon />}
                                disabled
                            >
                                Добавить услугу
                            </PlusButton>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ButtonsWrapper>
                <Button
                    onClick={() => setOpen(false)}
                    color="error"
                >
                    Отмена
                </Button>
                <Button
                    onClick={handleCreateService}
                    variant="contained"
                    disabled={(!form.parents.length && !form.is_root) || !form.name}
                >
                    Создать
                </Button>
            </ButtonsWrapper>
        </Box>
    );
};
