import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { memo, useState } from 'react';

import EditPaymentMethodForm from './EditPaymentMethodForm';

function EditPaymentMethod() {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    function handleClose() {
        setIsOpen(false);
    }
    return (
        <div className="val-prop-iss">
            <IconButton
                className="edit-iss-prop-icon"
                onClick={() => setIsOpen(!isOpen)}
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box className="edit-issue-address-dialog-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменить способ оплаты</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <EditPaymentMethodForm onClose={handleClose} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default memo(EditPaymentMethod);
