import { createSelector } from 'reselect';

import { issueStatusesMock } from '../../mockData/issues';
import { issueForAutoAssignment, issueForMasterSearch } from '../../utils/helpers/issues';
import { RootState } from '../rootReducer';

const state = ({ issues }: RootState) => issues;

export const issueSelector = createSelector(state, ({ issue }) => issue);
export const issueActivePutStatusesSelector = createSelector(state, ({ issue }) => {
    if (!issue) return null;
    const { status } = issue;
    return issueStatusesMock[status].activePutStatuses.filter((el) =>
        issueStatusesMock[status].isCanPutActiveStatus(issue, el),
    );
});
export const issuesSelector = createSelector(state, ({ issues }) => issues);
export const issuesOptionSelector = createSelector([issuesSelector], (issues) =>
    issues?.map((issue) => ({ value: issue.id, label: `${issue.oid}` })),
);
export const issuesIdSelector = createSelector(state, ({ issues }) => (issues ? issues.map((issue) => issue.id) : []));
export const issuesFiltersSelector = createSelector(state, ({ filters }) => filters);
export const statusFiltersSelector = createSelector(state, ({ statusFilters }) => statusFilters);
export const tableHeadIssuesSelector = createSelector(state, ({ tableHead }) => tableHead);
export const isIssuesTableLoaderSelector = createSelector(state, ({ isIssuesTableLoader }) => isIssuesTableLoader);
export const issuesPaginationSelector = createSelector(state, ({ pagination }) => pagination);
export const issuesIsOpenCommentDialogSelector = createSelector(
    state,
    ({ isOpenCommentDialog }) => isOpenCommentDialog,
);
export const issuesIsOpenDeleteCommentDialogSelector = createSelector(
    state,
    ({ isOpenDeleteCommentDialog }) => isOpenDeleteCommentDialog,
);
export const selectedIssuesSelector = createSelector(state, ({ selectedIssues }) => selectedIssues);
export const selectedIssuesDetailSelector = createSelector(state, ({ issues, selectedIssues }) => {
    if (!issues || !selectedIssues) return [];
    return issues.filter((issue) => selectedIssues.includes(issue.id));
});
export const notesIssuesSelector = createSelector(state, ({ notesIssue }) => notesIssue);

export const selectedIssuesHasAnyForChangeStatus = createSelector(state, ({ issues, selectedIssues }) => {
    if (!issues || !selectedIssues || selectedIssues.length === 0) return false;
    const objectMap = new Map(issues.map((issue) => [issue.id, issue]));

    return selectedIssues.some((id) => {
        const issue = objectMap.get(id);
        return issue && issueForMasterSearch(issue);
    });
});
export const selectedIssuesHasAnyForAutoAssignment = createSelector(state, ({ issues, selectedIssues }) => {
    if (!issues || !selectedIssues || selectedIssues.length === 0) return false;
    const objectMap = new Map(issues.map((issue) => [issue.id, issue]));

    return selectedIssues.some((id) => {
        const issue = objectMap.get(id);
        return issue && issueForAutoAssignment(issue);
    });
});

export const selectedIssuesForMasterSearch = createSelector(state, ({ issues, selectedIssues }) => {
    if (!issues || !selectedIssues || selectedIssues.length === 0) return [];
    return issues.filter((issue) => {
        return selectedIssues.includes(issue.id) && issueForMasterSearch(issue);
    });
});

export const selectedIssuesForAutoAssignment = createSelector(state, ({ issues, selectedIssues }) => {
    if (!issues || !selectedIssues || selectedIssues.length === 0) return [];
    return issues.filter((issue) => {
        return selectedIssues.includes(issue.id) && issueForAutoAssignment(issue);
    });
});
