import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ clients }: RootState) => clients;

export const clientSelector = createSelector(state, ({ client }) => client);
export const clientsSelector = createSelector(state, ({ clients }) => clients);
export const formValuesCCSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsCCSelector = createSelector(state, ({ errors }) => errors);
export const isOpenCreateClientSelector = createSelector(state, ({ isOpenCreateClient }) => isOpenCreateClient);
