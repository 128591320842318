import { Box, FormControl, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../components/inputs/TextField';
import { setSetFormValuesAction } from '../../../../store/actions/create/createIssuePartner';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsCreateIssuePartnerFormSelector,
    formValuesSelector,
} from '../../../../store/selectors/create/createIssuePartnerSelectors';

function Additional() {
    const dispatch = useAppDispatch();
    const errors = useSelector(errorsCreateIssuePartnerFormSelector);
    const formValues = useSelector(formValuesSelector);
    function changeDescriptionHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSetFormValuesAction({ description: e.target.value }));
    }

    function changeCodeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const newCodes = formValues?.codes ? { ...formValues.codes } : {};
        dispatch(setSetFormValuesAction({ codes: { ...newCodes, code: e.target.value } }));
    }

    function changeIssueHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const newCodes = formValues?.codes ? { ...formValues.codes } : {};
        dispatch(setSetFormValuesAction({ codes: { ...newCodes, issue: e.target.value } }));
    }

    return (
        <Box mt={3}>
            <Typography variant="h5">Дополнительно</Typography>
            <FormControl
                fullWidth
                margin="dense"
            >
                <TextField
                    name="description"
                    onChange={changeDescriptionHandler}
                    placeholder="Что еще специалисту стоит знать о заказе"
                    labelText="Что еще специалисту стоит знать о заказе"
                    params={{ multiline: true, minRows: 4, margin: 'dense' }}
                />
            </FormControl>
            <FormControl
                fullWidth
                margin="dense"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            name="code"
                            onChange={changeCodeHandler}
                            labelText="Код менеджера *"
                            placeholder="123456"
                            fullWidth
                            errors={errors}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            name="issue"
                            onChange={changeIssueHandler}
                            placeholder="123456"
                            labelText="Номер заказа *"
                            fullWidth
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </FormControl>
        </Box>
    );
}

export default Additional;
