import { Box, Tab, Tabs as MuiTabs, styled } from '@mui/material';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

const tabs = [
    { title: 'Начисление', id: 'deposits' },
    { title: 'Вывод средств', id: 'withdraws' },
    { title: 'История транзакций', id: 'transactions' },
];

export const Tabs: FC = () => {
    const location = useLocation();

    const getSelectedTab = () => {
        switch (location.pathname.split('/')[2]) {
            case 'deposits':
                return 0;
            case 'withdraws':
                return 1;
            case 'transactions':
                return 2;
            default:
                return 0;
        }
    };

    return (
        <Container>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <MuiTabs
                    variant="scrollable"
                    value={getSelectedTab()}
                >
                    {tabs.map((tab, idx) => {
                        return (
                            <Link
                                key={tab.id}
                                to={tab.id}
                            >
                                <Tab
                                    label={tab.title}
                                    value={idx}
                                />
                            </Link>
                        );
                    })}
                </MuiTabs>
            </Box>
        </Container>
    );
};

const Container = styled('div')({
    width: '100%',
    marginBottom: '20px',

    marginTop: '-10px',
});
