import depositsReducer from 'pages/accounting/deposits/store';
import transactionsReducer from 'pages/accounting/transactions/store';
import withdrawsReducer from 'pages/accounting/withdraws/store';
import commentsHistoryReducer from 'pages/issue/comments-history/store';
import priceListsReducer from 'pages/price-lists/store';
import integrationsReducer from 'pages/services/components/edit-service/integrations/store';
import servicesPriceListsReducer from 'pages/services/components/edit-service/price-lists/store';
import specificationsReducer from 'pages/services/components/edit-service/specifications/store';
import servicesReducer from 'pages/services/store';

import { combineReducers } from '@reduxjs/toolkit';

import { archiveReducer } from './reducers/archive';
import { brandsReducer } from './reducers/brands';
import { candidatesModalReducer } from './reducers/candidatesModal';
import { categoriesReducer } from './reducers/categories';
import { categoriesRatiosFilterReducer } from './reducers/categoriesRatiosFilter';
import { centrifugeReducer } from './reducers/centrifuge';
import { chatReducer } from './reducers/chat';
import { chatGroupByUserReducer } from './reducers/chatGroupByUser';
import { chatGroupByUsersReducer } from './reducers/chatGroupByUsers';
import { chatsReducer } from './reducers/chats';
import { chatsInfiniteReducer } from './reducers/chatsInfinite';
import { clientsReducer } from './reducers/clients';
import { companiesReducer } from './reducers/companies';
import { bindWorkerMoySkladReducer } from './reducers/create/bindMoySklad';
import { createGroupReducer } from './reducers/create/createGroup';
import { createIssueReducer } from './reducers/create/createIssue';
import { createIssuePartnerReducer } from './reducers/create/createIssuePartner';
import { createIssueReminderReducer } from './reducers/create/createIssueReminder';
import { createWorkerMoySkladReducer } from './reducers/create/createMoySklad';
import { createOrderReducer } from './reducers/create/createOrder';
import { createPartReducer } from './reducers/create/сreatePart';
import { devicesReducer } from './reducers/devices';
import { dialogReducer } from './reducers/dialog';
import { dictsReducer } from './reducers/dicts';
import { editCategoryReducer } from './reducers/edit/editCategory';
import { editIssuePricesReducer } from './reducers/edit/editIssuePrices';
import { editIssueReminderReducer } from './reducers/edit/editIssueReminder';
import { editIssueStatusReducer } from './reducers/edit/editIssueStatus';
import { editIssueStatusToCancelReducer } from './reducers/edit/editIssueStatusToCancel';
import { editIssueStatusToMasterSearchReducer } from './reducers/edit/editIssueStatusToMasterSearch';
import { editIssueStatusWithCommentReducer } from './reducers/edit/editIssueStatusWithComment';
import { editRatioReducer } from './reducers/edit/editRatio';
import { editWorkerFIOReducer } from './reducers/edit/editWorkerFIO';
import { editWorkerInfoReducer } from './reducers/edit/editWorkerInfo';
import { editWorkerScheduleReducer } from './reducers/edit/editWorkerSchedule';
import { editWorkerServiceAreasReducer } from './reducers/edit/editWorkerServiceAreas';
import { editWorkerSpecializationsReducer } from './reducers/edit/editWorkerSpecializations';
import { unbindWorkerMoySkladReducer } from './reducers/edit/unbindMoySklad';
import { groupsReducer } from './reducers/groups';
import { historyReducer } from './reducers/history';
import { invoicingNotificationsReducer } from './reducers/invoicingNotifications';
import { issuesReducer } from './reducers/issues';
import { mapReducer } from './reducers/map';
import { modelsReducer } from './reducers/models';
import { ordersReducer } from './reducers/orders';
import { partnersReducer } from './reducers/partners';
import { partnersRatiosFilterReducer } from './reducers/partnersRatiosFilter';
import { partsReducer } from './reducers/parts';
import { priceDialogReducer } from './reducers/priceDialog';
import { pricesReducer } from './reducers/prices';
import { priceServicesDialogReducer } from './reducers/priceServicesDialog';
import { pricesServicesReducer } from './reducers/pricesServices';
import { profileReducer } from './reducers/profile';
import { ratiosReducer } from './reducers/ratios';
import { remindersReducer } from './reducers/reminders';
import { rolesReducer } from './reducers/roles';
import { searchReducer } from './reducers/search';
import { sideBarReducer } from './reducers/sideBar';
import { signInReducer } from './reducers/signIn';
import { tagsReducer } from './reducers/tags';
import { toastReducer } from './reducers/toast';
import { websocketsReducer } from './reducers/websockets';
import { workerReducer } from './reducers/worker';
import { workersReducer } from './reducers/workers';

const rootReducer = combineReducers({
    archive: archiveReducer,
    brands: brandsReducer,
    candidatesModal: candidatesModalReducer,
    categories: categoriesReducer,
    categoriesRatiosFilter: categoriesRatiosFilterReducer,
    centrifuge: centrifugeReducer,
    chat: chatReducer,
    chatGroupByUsers: chatGroupByUsersReducer,
    chatGroupByUser: chatGroupByUserReducer,
    chats: chatsReducer,
    chatsInfinite: chatsInfiniteReducer,
    clients: clientsReducer,
    companies: companiesReducer,
    createGroup: createGroupReducer,
    createIssue: createIssueReducer,
    createIssuePartner: createIssuePartnerReducer,
    createIssueReminder: createIssueReminderReducer,
    createOrder: createOrderReducer,
    createPart: createPartReducer,
    devices: devicesReducer,
    dialog: dialogReducer,
    dicts: dictsReducer,
    editCategory: editCategoryReducer,
    editIssuePrices: editIssuePricesReducer,
    editIssueReminder: editIssueReminderReducer,
    editIssueStatus: editIssueStatusReducer,
    editIssueStatusToCancel: editIssueStatusToCancelReducer,
    editIssueStatusWithComment: editIssueStatusWithCommentReducer,
    editIssueStatusToMasterSearch: editIssueStatusToMasterSearchReducer,
    editRatio: editRatioReducer,
    editWorkerFIO: editWorkerFIOReducer,
    editWorkerInfo: editWorkerInfoReducer,
    editWorkerSchedule: editWorkerScheduleReducer,
    editWorkerServiceAreas: editWorkerServiceAreasReducer,
    editWorkerSpecializations: editWorkerSpecializationsReducer,
    createWorkerMoySklad: createWorkerMoySkladReducer,
    bindWorkerMoySklad: bindWorkerMoySkladReducer,
    unbindWorkerMoySklad: unbindWorkerMoySkladReducer,
    groups: groupsReducer,
    history: historyReducer,
    invoicingNotifications: invoicingNotificationsReducer,
    issues: issuesReducer,
    map: mapReducer,
    models: modelsReducer,
    orders: ordersReducer,
    partners: partnersReducer,
    partnersRatiosFilter: partnersRatiosFilterReducer,
    parts: partsReducer,
    priceDialog: priceDialogReducer,
    priceServicesDialog: priceServicesDialogReducer,
    prices: pricesReducer,
    pricesServices: pricesServicesReducer,
    profile: profileReducer,
    ratios: ratiosReducer,
    reminders: remindersReducer,
    roles: rolesReducer,
    search: searchReducer,
    sideBar: sideBarReducer,
    signIn: signInReducer,
    tags: tagsReducer,
    toast: toastReducer,
    websockets: websocketsReducer,
    worker: workerReducer,
    workers: workersReducer,
    deposits: depositsReducer,
    transactions: transactionsReducer,
    withdraws: withdrawsReducer,
    services: servicesReducer,
    priceLists: priceListsReducer,
    integrations: integrationsReducer,
    specifications: specificationsReducer,
    servicesPriceLists: servicesPriceListsReducer,
    commentsHistory: commentsHistoryReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
