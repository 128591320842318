import { RootState } from 'store/rootReducer';
import { AppDispatch } from 'store/store';

import { searchApi } from '../../api';
import { ESearchActions } from '../../enums/actions.enum';
import {
    ISearchItem,
    ISearchReq,
    ISearchState,
    ISetFiltersSearchAction,
    ISetIndiciesSearchAction,
    ISetIsOpenSearchAction,
    ISetItemsSearchAction,
    ISetPageSearchItems,
    ISetTextSearchAction,
    ISetTotalSearchItems,
} from '../../interfaces/search';

export const setItemsSearchAction = (payload: ISearchItem[] | null): ISetItemsSearchAction => ({
    type: ESearchActions.SetItems,
    payload,
});

export const setFiltersSearchAction = (payload: ISearchState['filters']): ISetFiltersSearchAction => ({
    type: ESearchActions.SetFilters,
    payload,
});

export const setIndiciesSearchAction = (payload: ISearchState['indicies']): ISetIndiciesSearchAction => ({
    type: ESearchActions.SetIndicies,
    payload,
});

export const setTextSearchAction = (payload: string | null): ISetTextSearchAction => ({
    type: ESearchActions.SetText,
    payload,
});

export const setIsOpenSearchAction = (payload: boolean): ISetIsOpenSearchAction => ({
    type: ESearchActions.SetIsOpen,
    payload,
});

export const setTotalSearchItems = (payload: number): ISetTotalSearchItems => ({
    type: ESearchActions.SetTotal,
    payload,
});

export const setPageSearchItems = (payload: number): ISetPageSearchItems => ({
    type: ESearchActions.SetPage,
    payload,
});

export const getSearchThunk =
    ({ filters, indicies, text, page }: ISearchReq) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const pageCurrent = page || getState().search.page;

            const { data } = await searchApi.postSearch(`?size=100&page=${pageCurrent}`, {
                filters,
                indicies,
                text,
            });
            const { items, total } = data;
            dispatch(setTotalSearchItems(total));
            dispatch(setPageSearchItems(data.page));
            dispatch(setItemsSearchAction(items));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
