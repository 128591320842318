import { priceListsApi, servicesApi } from 'api';
import { fetchServiceById, fetchServices, setServiceById } from 'pages/services/store';
import { TService } from 'pages/services/store/types';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';
import { LoosePartial } from 'types';
import { selectMapper, Option } from 'utils/helpers/selectMapper';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import {
    ButtonsContainer,
    ButtonsWrapper,
    Cell,
    CheckBox,
    CheckBoxWrapper,
    CheckTitle,
    HeadCell,
    MuiTextField,
    SubTitle,
} from './styles';

import { Asterisk, Field, FieldContainer, MuiAutocomplete, PlusButton } from '../../create-service/styles';
import { FootNote } from '../../footnote';
import { DeleteService } from '../../modals/delete-service';

type Props = {
    setOpenEdit: (isOpen: boolean) => void;
    setIsEditing: (isOpen: boolean) => void;
};

export const Basics: FC<Props> = ({ setOpenEdit, setIsEditing }) => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);

    const [isVisible, setVisible] = useState(false);
    const [addChildService, setAddChildService] = useState<
        Partial<{
            id: string;
            is_include_in_price: boolean;
            is_deleted: boolean;
        }>
    >();
    const [isOpenDelete, setOpenDelete] = useState<boolean>(false);
    const [hasFetchedOnFirstFocus, setHasFetchedOnFirstFocus] = useState(false);

    const [parents, setParents] = useState<Option[]>();
    const [childrenServices, setChildrenServices] = useState<Option[]>();
    const [categories, setCategories] = useState<Option[]>();
    const [serviceTypes, setServiceTypes] = useState<Option[]>();
    const [companies, setCompanies] = useState<Option[]>();

    const serviceChildren = useMemo(() => serviceById?.children || [], [serviceById?.children]);

    const defaultParents = useMemo(() => {
        if (serviceById?.parents?.[0]?.id) {
            return serviceById?.parents?.map(selectMapper);
        }
        return [];
    }, [serviceById?.parents?.[0]?.id, serviceById?.parents]);

    const defaultCategories = useMemo(() => {
        if (serviceById?.categories?.[0]?.id) {
            return serviceById?.categories?.map(selectMapper);
        }
        return [];
    }, [serviceById?.categories]);

    const defaultServiceTypes = useMemo(() => {
        if (serviceById?.service_types?.[0]?.id) {
            return serviceById?.service_types?.map(selectMapper);
        }
        return [];
    }, [serviceById?.service_types]);

    const defaultCompanies = useMemo(() => {
        if (serviceById?.companies?.[0]?.id) {
            return serviceById?.companies?.map(selectMapper);
        }
        return [];
    }, [serviceById?.companies]);

    const prevLengthsRef = useRef({
        categoriesLength: 0,
        serviceTypesLength: 0,
        childrenLength: 0,
        companiesLength: 0,
    });

    const handleConfirm = () => {
        handleEditService({ children: [addChildService] });
        servicesApi.getPossibleChildren(serviceById?.id as string, {}).then(({ data }) => {
            setChildrenServices(data.items.map(selectMapper));
        });
        setVisible(false);
    };

    const handleCancel = () => {
        setAddChildService({});
        setVisible(false);
    };

    const handleEditService = (body: LoosePartial<TService>) => {
        servicesApi
            .editService(serviceById?.id, body)
            .then(({ data }) => {
                enqueueSnackbar('Изменения сохранены', { variant: 'success' });
                dispatch(setServiceById(data));
                if (!('description' in body)) setIsEditing(true);
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const handleDeleteService = (param: boolean) => {
        servicesApi
            .deleteService(serviceById?.id as string, { children_delete_enabled: param })
            .then(() => {
                enqueueSnackbar('Услуга удалена', { variant: 'success' });
                setOpenEdit(false);
                setOpenDelete(false);
                dispatch(fetchServices());
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const handleSearchServices = (value: string) => {
        servicesApi.getPossibleChildren(serviceById?.id as string, { search: value }).then(({ data }) => {
            setChildrenServices(data.items.map(selectMapper));
        });
    };

    const handleSearchServicesDebounced = useDebouncedCallback(handleSearchServices, 100);

    const handleGetParentServices = (search?: string) => {
        setParents([]);
        const params: Record<string, any> = {
            service_id: serviceById?.id,
        };

        if (search) {
            params.search = search;
        }

        servicesApi.getServicesPossibleParents(params).then(({ data }) => {
            setParents(data.items.map(selectMapper));
        });
    };

    const handleSearchParentServices = useDebouncedCallback(handleGetParentServices, 700);

    const handleFocusParentServices = () => {
        const { categoriesLength, serviceTypesLength, childrenLength, companiesLength } = prevLengthsRef.current;

        const isFirstFocus =
            categoriesLength === 0 && serviceTypesLength === 0 && childrenLength === 0 && companiesLength === 0;

        if (isFirstFocus && !hasFetchedOnFirstFocus) {
            handleGetParentServices();
            setHasFetchedOnFirstFocus(true);
        }

        const hasChanges =
            categoriesLength !== defaultCategories.length ||
            serviceTypesLength !== defaultServiceTypes.length ||
            childrenLength !== serviceChildren.length ||
            companiesLength !== defaultCompanies.length;

        if (hasFetchedOnFirstFocus && hasChanges) {
            handleGetParentServices();
        }

        prevLengthsRef.current = {
            categoriesLength: defaultCategories.length,
            serviceTypesLength: defaultServiceTypes.length,
            childrenLength: serviceChildren.length,
            companiesLength: defaultCompanies.length,
        };
    };

    const fetchAutocompleteData = async () => {
        const [childrenServiceRes, serviceTypesRes, categoriesRes, companiesRes] = await Promise.all([
            servicesApi.getPossibleChildren(serviceById?.id as string, {}),
            servicesApi.getServiceTypes(),
            servicesApi.getCategories({}),
            priceListsApi.getCompanies({}),
        ]);

        setChildrenServices(childrenServiceRes.data.items.map(selectMapper));
        setCategories(categoriesRes.data.items.map(selectMapper));
        setServiceTypes(serviceTypesRes.data.map(selectMapper));
        setCompanies(companiesRes.data.map(selectMapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    if (!serviceById) return null;

    return (
        <Box>
            <FieldContainer>
                <div>
                    <Field>
                        <SubTitle>
                            Родители<Asterisk>*</Asterisk>
                        </SubTitle>
                        <MuiAutocomplete
                            multiple
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={parents || []}
                            isOptionEqualToValue={(option, value) => option === value}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Выбрать"
                                />
                            )}
                            onInputChange={(_, value) => {
                                handleSearchParentServices(value);
                            }}
                            onFocus={() => {
                                handleFocusParentServices();
                            }}
                            onChange={(_, value, reason, detail) => {
                                const id = (detail?.option as Option)?.value;

                                if (reason === 'selectOption') {
                                    handleEditService({ parents: [{ id, is_deleted: false }] });
                                } else if (reason === 'removeOption') {
                                    handleEditService({ parents: [{ id, is_deleted: true }] });
                                } else if (reason === 'clear') {
                                    handleEditService({
                                        parents: defaultParents.map(({ value }) => ({
                                            id: value,
                                            is_deleted: true,
                                        })),
                                    });
                                }
                            }}
                            value={defaultParents}
                        />
                    </Field>
                    <Field sx={{ marginTop: '10px' }}>
                        <SubTitle />
                        <CheckBox
                            checked={serviceById?.is_root}
                            onClick={() => handleEditService({ is_root: !serviceById?.is_root })}
                        />
                        <CheckTitle>Основная</CheckTitle>
                    </Field>
                </div>
                <Field>
                    <SubTitle>
                        Наименование<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        multiline
                        defaultValue={serviceById?.name}
                        onBlur={(e) => {
                            if (e.target.value.trim() === serviceById?.name) return;
                            handleEditService({ name: e.target.value.trim() });
                        }}
                        size="small"
                    />
                </Field>
                <Field>
                    <SubTitle>Описание</SubTitle>
                    <MuiTextField
                        multiline
                        minRows={4}
                        defaultValue={serviceById?.description}
                        onBlur={(e) => {
                            if (e.target.value.trim() === serviceById?.description) return;
                            handleEditService({ description: e.target.value.trim() });
                        }}
                    />
                </Field>
                <Field>
                    <SubTitle>Компании</SubTitle>
                    <MuiAutocomplete
                        loading
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={companies ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Компании"
                            />
                        )}
                        onChange={(_, value, reason, detail) => {
                            const id = (detail?.option as Option)?.value;

                            if (reason === 'selectOption') {
                                handleEditService({ companies: [{ id, is_deleted: false }] });
                            } else if (reason === 'removeOption') {
                                handleEditService({ companies: [{ id, is_deleted: true }] });
                            } else if (reason === 'clear') {
                                handleEditService({
                                    companies: defaultCompanies.map(({ value }) => ({
                                        id: value,
                                        is_deleted: true,
                                    })),
                                });
                            }
                        }}
                        value={defaultCompanies}
                    />
                </Field>
                <Field>
                    <SubTitle>Типы услуг</SubTitle>
                    <MuiAutocomplete
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={serviceTypes ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Типы услуг"
                            />
                        )}
                        onChange={(_, value, reason, detail) => {
                            const id = (detail?.option as Option)?.value;

                            if (reason === 'selectOption') {
                                handleEditService({ service_types: [{ id, is_deleted: false }] });
                            } else if (reason === 'removeOption') {
                                handleEditService({ service_types: [{ id, is_deleted: true }] });
                            } else if (reason === 'clear') {
                                handleEditService({
                                    service_types: defaultServiceTypes.map(({ value }) => ({
                                        id: value,
                                        is_deleted: true,
                                    })),
                                });
                            }
                        }}
                        value={defaultServiceTypes}
                    />
                </Field>
                <Field>
                    <SubTitle>Категории</SubTitle>
                    <MuiAutocomplete
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={categories ?? []}
                        clearOnBlur={false}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Категории"
                            />
                        )}
                        onInputChange={(_, value) => {
                            servicesApi.getCategories({ search: value }).then(({ data }) => {
                                setCategories(data.items.map(selectMapper));
                            });
                        }}
                        value={defaultCategories}
                        onChange={(_, value, reason, detail) => {
                            const id = (detail?.option as Option)?.value;

                            if (reason === 'selectOption') {
                                handleEditService({ categories: [{ id, is_deleted: false }] });
                            } else if (reason === 'removeOption') {
                                handleEditService({ categories: [{ id, is_deleted: true }] });
                            } else if (reason === 'clear') {
                                handleEditService({
                                    categories: defaultCategories.map(({ value }) => ({
                                        id: value,
                                        is_deleted: true,
                                    })),
                                });
                            }
                        }}
                    />
                </Field>
                <Field sx={{ marginTop: '10px' }}>
                    <SubTitle>Активная</SubTitle>
                    <CheckBoxWrapper>
                        <CheckBox
                            onClick={() => handleEditService({ is_active: !serviceById?.is_active })}
                            defaultChecked={serviceById?.is_active}
                        />
                        <FootNote />
                    </CheckBoxWrapper>
                </Field>
            </FieldContainer>
            <Box sx={{ marginTop: '28px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Дочерние услуги</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <HeadCell sx={{ width: '450px' }}>НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                        <HeadCell sx={{ width: '450px' }}>ВХОДИТ В СТОИМОСТЬ</HeadCell>
                        <HeadCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {serviceChildren.map((child) => {
                        return (
                            <TableRow key={child.id}>
                                <Cell>{child.name}</Cell>
                                <Cell>
                                    <Checkbox
                                        onClick={() => {
                                            servicesApi
                                                .editServiceBranch(child.service_branch_id, {
                                                    is_include_in_price: !child.is_include_in_price,
                                                })
                                                .then(() => {
                                                    enqueueSnackbar('Изменения сохранены', {
                                                        variant: 'success',
                                                    });
                                                    dispatch(fetchServiceById(serviceById?.id));
                                                })
                                                .catch((e) => {
                                                    enqueueSnackbar(e.response.data.error?.message || e.message, {
                                                        variant: 'error',
                                                    });
                                                });
                                        }}
                                        checked={child.is_include_in_price}
                                    />
                                </Cell>
                                <Cell>
                                    <Button
                                        onClick={() => {
                                            handleEditService({
                                                children: [
                                                    {
                                                        id: child.id,
                                                        is_include_in_price: child.is_include_in_price,
                                                        is_deleted: true,
                                                    },
                                                ],
                                            });
                                        }}
                                    >
                                        <DeleteBasket />
                                    </Button>
                                </Cell>
                            </TableRow>
                        );
                    })}

                    {isVisible ? (
                        <TableRow>
                            <Cell>
                                <Autocomplete
                                    size="small"
                                    sx={{ width: '260px' }}
                                    popupIcon={<ExpandMoreIcon />}
                                    disabled={!childrenServices}
                                    options={childrenServices ?? []}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выберите услугу"
                                        />
                                    )}
                                    onInputChange={(_, value) => {
                                        handleSearchServicesDebounced(value);
                                    }}
                                    onChange={(_, value) => {
                                        const isInclude = serviceChildren.find((item) => item.id === value?.value);

                                        if (isInclude) {
                                            enqueueSnackbar('Невозможно добавить две одинаковые услуги', {
                                                variant: 'error',
                                            });
                                        } else {
                                            setAddChildService({
                                                id: value?.value,
                                                is_include_in_price: false,
                                                is_deleted: false,
                                            });
                                        }
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <Checkbox
                                    onChange={(e) => {
                                        setAddChildService((prev) => {
                                            return { ...prev, is_include_in_price: e.target.checked };
                                        });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <ButtonsContainer>
                                    <div onClick={handleConfirm}>
                                        <Confirm />
                                    </div>
                                    <div onClick={handleCancel}>
                                        <Cancel />
                                    </div>
                                </ButtonsContainer>
                            </Cell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <PlusButton
                                onClick={() => setVisible(true)}
                                startIcon={<AddIcon />}
                            >
                                Добавить услугу
                            </PlusButton>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ButtonsWrapper>
                <Button
                    onClick={() => {
                        setOpenDelete(true);
                    }}
                    color="error"
                >
                    Удалить
                </Button>
            </ButtonsWrapper>

            <DeleteService
                isOpen={isOpenDelete}
                onClose={setOpenDelete}
                handleDeleteService={handleDeleteService}
            />
        </Box>
    );
};
