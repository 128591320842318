import { TableHead, TableRow } from '@mui/material';

import { HeadCell } from './styles';

export const Head = () => {
    return (
        <TableHead>
            <TableRow>
                <HeadCell sx={{ textAlign: 'start', width: '30%' }}>СИСТЕМНОЕ НАИМЕНОВАНИЕ</HeadCell>
                <HeadCell sx={{ textAlign: 'start', width: '30%' }}>НАИМЕНОВАНИЕ</HeadCell>
                <HeadCell>КОМПАНИЯ</HeadCell>
                <HeadCell sx={{ width: '115px' }}>ТИП УСЛУГИ</HeadCell>
                <HeadCell>РЕГИОН</HeadCell>
                <HeadCell sx={{ paddingRight: 0 }}>СТАТУС</HeadCell>
                <HeadCell sx={{ paddingLeft: 0 }} />
            </TableRow>
        </TableHead>
    );
};
