import { ERoutes } from 'enums/routes.enum';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TErrors } from '../../../../../interfaces';
import {
    createIssueThunk,
    setErrorsAction,
    setIsOpenCreateIssueAction,
} from '../../../../../store/actions/create/createIssue';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsCreateIssueSelector,
    formValuesCreateIssueSelector,
    selectedPricesSelector,
} from '../../../../../store/selectors/create/createIssuesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import { isDateFuture } from '../../../../../utils/helpers/isDateFuture';
import { onlyNumberReg } from '../../../../../utils/regex';

interface IFooterFormProps {
    onCloseHandler: () => void;
}

function FooterForm({ onCloseHandler }: IFooterFormProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectedPrices = useSelector(selectedPricesSelector);
    const formValues = useSelector(formValuesCreateIssueSelector);
    const errors = useSelector(errorsCreateIssueSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.client_id) {
            errs.client_id = { message: 'Обязательное поле' };
        }
        if (!formValues?.home_device_id) {
            errs.home_device_id = { message: 'Обязательное поле' };
        }
        if (!formValues?.help_type) {
            errs.help_type = { message: 'Обязательное поле' };
        }

        if (!formValues?.address) {
            errs.address = { message: 'Обязательное поле' };
        }
        if (formValues?.floor && !onlyNumberReg.test(formValues?.floor)) {
            errs.floor = { message: 'Только цифры' };
        }
        if (formValues?.entrance && !onlyNumberReg.test(formValues?.entrance)) {
            errs.entrance = { message: 'Только цифры' };
        }
        if (formValues?.apartment && !onlyNumberReg.test(formValues?.apartment)) {
            errs.apartment = { message: 'Только цифры' };
        }
        if (formValues?.intercom && !onlyNumberReg.test(formValues?.intercom)) {
            errs.intercom = { message: 'Только цифры' };
        }

        if (!formValues?.date) {
            errs.date = { message: 'Обязательное поле' };
        }
        if (formValues?.date) {
            if (!isDateFuture(formValues.date)) {
                errs.date = { message: 'Не корректное значение' };
            }
        }

        if (!formValues?.time) {
            errs.time = { message: 'Обязательное поле' };
        }

        if (!formValues?.created_source) {
            errs.created_source = { message: 'Обязательное поле' };
        }

        if (!selectedPrices?.length) {
            errs.prices = { message: 'Обязательное поле' };
        }

        // if (selectedPrices) {
        //     if (selectedPrices.every(({ service }) => service.is_additional)) {
        //         errs.prices = { message: 'Обязательное поле' };
        //     }
        // }

        dispatch(setErrorsAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues, selectedPrices]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        if (!formValues) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [formValues, selectedPrices]);

    async function submitFormHandler() {
        console.log(formValues);
        if (!formValues) return;

        if (validator() && selectedPrices) {
            try {
                setIsLoading(true);
                const newIssue = await dispatch(createIssueThunk({ formValues, selectedPrices }));

                navigate(`/${ERoutes.Issues}/${newIssue?.id}`);

                dispatch(setIsOpenCreateIssueAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Box mt={2}>
            <Button
                onClick={onCloseHandler}
                disabled={isBtnDisabled}
                variant="outlined"
            >
                Отмена
            </Button>

            <LoadingButton
                onClick={submitFormHandler}
                disabled={isBtnDisabled}
                loading={isLoading}
                variant="contained"
                sx={{ ml: 1 }}
            >
                Создать
            </LoadingButton>
        </Box>
    );
}

export default FooterForm;
