import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PriceDetail from './PriceDetail';
import PricesButtonGroup from './PricesButtonGroup';

import MainLayout from '../../layouts/MainLayout';
import DeletePrice from '../../modules/PriceModals/DeletePrice';
import PriceDialog from '../../modules/PriceModals/PriceDialog';
import { getPriceThunk, setIsPriceLoadingAction, setPriceAction } from '../../store/actions/prices';
import { useAppDispatch } from '../../store/hooks';
import { priceSelector } from '../../store/selectors/pricesSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';

function PricePage() {
    const { priceId } = useParams();
    const dispatch = useAppDispatch();

    const price = useSelector(priceSelector);

    useEffect(() => {
        async function handle() {
            try {
                if (!priceId) return;
                dispatch(setIsPriceLoadingAction(true));
                await dispatch(getPriceThunk(priceId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                dispatch(setIsPriceLoadingAction(false));
            }
        }
        handle();
        return () => {
            dispatch(setPriceAction(null));
        };
    }, [priceId]);

    return (
        <MainLayout>
            <div className="price-page-wrapper">
                <div className="prices-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        {price?.name}
                    </Typography>
                    <PriceDialog />
                    <DeletePrice />
                    <PricesButtonGroup />
                </div>
                <PriceDetail price={price} />
            </div>
        </MainLayout>
    );
}

export default PricePage;
