import { EIssueHelpType } from 'enums/issues.enum';
import {
    setDataEditIssueStatusWithCommentAction,
    setErrorsEditIssueStatusWithCommentAction,
    setFormValuesEditIssueStatusWithCommentAction,
    setIsOpenEditIssueStatusWithCommentAction,
} from 'store/actions/edit/editIssueStatusWithComment';
import { useAppDispatch } from 'store/hooks';
import { isOpenEditIssueStatusWithCommentSelector } from 'store/selectors/edit/editIssueStatusWithCommentSelectors';

import CloseIcon from '@mui/icons-material/Close';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';

import EditIssueStatusWithCommentForm from './EditIssueStatusWithCommentForm';

import './EditIssueStatusWithComment.scss';

interface IProps {
    issueHelpType: EIssueHelpType;
    reason: string | undefined;
}

function EditIssueStatusWithComment({ issueHelpType, reason }: IProps) {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenEditIssueStatusWithCommentSelector);

    function onClose() {
        dispatch(setIsOpenEditIssueStatusWithCommentAction(false));
        dispatch(setFormValuesEditIssueStatusWithCommentAction(null));
        dispatch(setErrorsEditIssueStatusWithCommentAction(null));
        dispatch(setDataEditIssueStatusWithCommentAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            transitionDuration={0}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="edit-issue-status-with-comment-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <SmsOutlinedIcon className="title-icon" />
                    <div className="title-text">Изменение статуса заявки</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditIssueStatusWithCommentForm
                    reason={reason}
                    issueHelpType={issueHelpType}
                />
            </DialogContent>
        </Dialog>
    );
}

export default EditIssueStatusWithComment;
