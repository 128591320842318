import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ArchiveIssuesFilters from './issues/ArchiveIssuesFilters';
import ArchiveIssuesTable from './issues/AtchiveIssuesTable';
import Toggle from './Toggle';
import ArchiveWorkersFilters from './workers/ArchiveWorkersFilters';
import ArchiveWorkersTable from './workers/ArchiveWorkersTable';

import { TArchiveSelectedTab } from '../../interfaces/archive';
import MainLayout from '../../layouts/MainLayout';
import {
    archiveResetAction,
    setArchiveIssuesFiltersAction,
    setArchiveSelectedTabAction,
} from '../../store/actions/archive';
import {
    setCategoriesRatiosFilterAction,
    setSearchCRFAction,
    setSelectedCRFAction,
} from '../../store/actions/categoriesRatiosFilter';
import { setWorkersFiltersAction } from '../../store/actions/workers';
import { useAppDispatch } from '../../store/hooks';
import { archiveSelectedTab } from '../../store/selectors/archiveSelectors';
import { categoriesCRFSelector } from '../../store/selectors/categoriesRatiosFilterSelectors';
import { getCategoriesRFWithChecked } from '../../utils/helpers/getCategoriesRFWithChecked';
import './Archive.scss';

function ArchivePage() {
    const dispatch = useAppDispatch();

    const selectedTab = useSelector(archiveSelectedTab);
    const categories = useSelector(categoriesCRFSelector);

    useEffect(() => {
        return () => {
            dispatch(archiveResetAction());
        };
    }, []);

    const setSelectedTab = (value: TArchiveSelectedTab) => {
        dispatch(setArchiveSelectedTabAction(value));
        dispatch(setArchiveIssuesFiltersAction(null));
        dispatch(setWorkersFiltersAction(null));
        dispatch(setSelectedCRFAction([]));
        dispatch(setSearchCRFAction(null));
        categories && dispatch(setCategoriesRatiosFilterAction(getCategoriesRFWithChecked(categories)));
    };

    return (
        <MainLayout>
            <div className="archive-page-wrapper">
                <div className="archive-filters-toggle-wrapper">
                    <Toggle
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    {selectedTab === 'Заявки' && <ArchiveIssuesFilters />}
                    {selectedTab === 'Мастера' && <ArchiveWorkersFilters />}
                </div>
                {selectedTab === 'Заявки' && <ArchiveIssuesTable />}
                {selectedTab === 'Мастера' && <ArchiveWorkersTable />}
            </div>
        </MainLayout>
    );
}

export default memo(ArchivePage);
