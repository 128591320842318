import Autocomplete from 'components/inputs/Autocomplete';
import { setFormValuesEditIssueStatusToCancelAction } from 'store/actions/edit/editIssueStatusToCancel';
import { getIssuesThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { isIssuesTableLoaderSelector, issueSelector, issuesOptionSelector } from 'store/selectors/issuesSelectors';
import { Option } from 'utils/helpers/selectMapper';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

export const IssueSelect = () => {
    const dispatch = useAppDispatch();
    const issuesOptions = useSelector(issuesOptionSelector);
    const isIssuesLoading = useSelector(isIssuesTableLoaderSelector);
    const issue = useSelector(issueSelector);

    const handleIssuesSearch = useDebouncedCallback((value: string) => {
        dispatch(getIssuesThunk({ search: value, filters: { exclude_pk__in: [issue?.id || ''] } }));
    }, 700);

    const handleChangeIssue = (value: Option | null) => {
        if (value) {
            return dispatch(
                setFormValuesEditIssueStatusToCancelAction({ issue: { id: value.value, oid: +value.label } }),
            );
        }

        return dispatch(setFormValuesEditIssueStatusToCancelAction({ issue: null }));
    };
    useEffect(() => {
        if (issue?.id) {
            dispatch(getIssuesThunk({ filters: { exclude_pk__in: [issue.id] } }));
        }
    }, [issue?.id]);

    return (
        <Autocomplete
            name="issues"
            label="Заявка"
            margin="normal"
            options={issuesOptions}
            disabled={isIssuesLoading}
            onInpChange={handleIssuesSearch}
            onChange={handleChangeIssue}
        />
    );
};
