import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PricesServices from './PricesServices';
import PricesTabs from './PricesTabs';

import ButtonGroup, { IButtonGroupItem } from '../../components/ButtonGroup';
import { EPricesTabs } from '../../enums/prices.enum';
import { EPricesServicesTabs } from '../../enums/pricesServices.enum';
import MainLayout from '../../layouts/MainLayout';
import DeleteService from '../../modules/ServiceModals/DeleteService';
import ServiceDialog from '../../modules/ServiceModals/ServiceDialog';
import { setPricesTabAction } from '../../store/actions/prices';
import { setIsOpenPriceServicesDialogAction } from '../../store/actions/priceServicesDialog';
import {
    setPriceServiceAction,
    setPriceServicesAction,
    setPriceServicesTabAction,
} from '../../store/actions/pricesServices';
import { useAppDispatch } from '../../store/hooks';
import { pricesTabSelector } from '../../store/selectors/pricesSelectors';
import './PricesPage.scss';

function PricesPageNew() {
    const dispatch = useAppDispatch();
    const pricesTab = useSelector(pricesTabSelector);

    useEffect(() => {
        return () => {
            dispatch(setPriceServicesAction(null));
            dispatch(setPricesTabAction(EPricesTabs.Services));
            dispatch(setPriceServicesTabAction(EPricesServicesTabs.Description));
            dispatch(setPriceServiceAction(null));
        };
    }, []);

    function getButtonGroupItems(): IButtonGroupItem[] {
        return [
            {
                title: 'Тип услуги',
                handler: () => createPriceService(),
            },
        ];
    }

    function createPriceService() {
        dispatch(setIsOpenPriceServicesDialogAction({ isEdit: false, isOpen: true }));
    }

    return (
        <MainLayout>
            <div className="prices-page-wrapper">
                <ServiceDialog />
                <DeleteService />
                <div style={{ position: 'absolute', right: 35, top: 15, zIndex: 1 }}>
                    <ButtonGroup
                        title="Добавить"
                        buttonsInfo={getButtonGroupItems()}
                    />
                </div>
                <PricesTabs />
                {(() => {
                    switch (pricesTab) {
                        case EPricesTabs.Services: {
                            return <PricesServices />;
                        }
                        default:
                            return null;
                    }
                })()}
            </div>
        </MainLayout>
    );
}

export default memo(PricesPageNew);
