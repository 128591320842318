import { EWorkerStatus } from 'enums/workers.enum';
import { FilterStatus } from 'features/filter-status';

import { TWorkersFiltersCM } from 'interfaces/candidatesModal';
import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { EIssueHelpType } from '../../../enums/issues.enum';
import { ICategoryRatiosFilter } from '../../../interfaces/categoriesRatiosFilter';
import { IGroup } from '../../../interfaces/groups';
import CategoriesFilter from '../../../modules/CategoriesFilter';
import CategoriesRatiosFilter from '../../../modules/CategoriesRatiosFilter';
import GroupFilter from '../../../modules/GroupFilter';
import HelpTypeFilter from '../../../modules/HelpTypeFilter';
import Search from '../../../modules/WorkersSelect/Search';
import { setGroupAction } from '../../../store/actions/groups';
import { setWorkersFiltersAction } from '../../../store/actions/workers';
import { useAppDispatch } from '../../../store/hooks';
import { workersFiltersSelector } from '../../../store/selectors/workersSelectors';
import WorkersTabs from '../WokersTabs';

import './WorkersFilters.scss';

const filterItems = [
    { title: 'Работает (готов)', value: EWorkerStatus.ConnectedWorkingReady },
    { title: 'Работает (не готов)', value: EWorkerStatus.ConnectedWorkingNotReady },
    { title: 'Отключен', value: EWorkerStatus.Disabled },
    { title: 'На тестировании', value: EWorkerStatus.Testing },
    { title: 'Проверен', value: EWorkerStatus.Verified },
    { title: 'Ожидает проверки', value: EWorkerStatus.WaitingVerified },
];

function WorkersFilters() {
    const dispatch = useAppDispatch();

    const filters = useSelector(workersFiltersSelector);

    function selectCategories(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), area_id__in: categoriesIds }));
    }

    function selectCRFIdsForWorkers(selectCRFIds: string[] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), specialization__category_id__in: selectCRFIds }));
    }
    function selectHelpTypeForWorkers(helpType: EIssueHelpType[] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), specialization__help_type__in: helpType }));
    }

    function selectGroup(group: IGroup | null) {
        dispatch(setGroupAction(group));
        dispatch(setWorkersFiltersAction({ ...filters, group_id__in: group }));
    }

    function searchWorker(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setWorkersFiltersAction({ ...filters, search: event.target.value }));
    }

    const handleStatusChange = (status: string | null) => {
        if (!filters?.['status__in']?.includes(status as EWorkerStatus)) {
            dispatch(
                setWorkersFiltersAction({
                    ...filters,
                    status__in: [...(filters?.['status__in'] || []), status],
                } as TWorkersFiltersCM),
            );
        } else {
            dispatch(
                setWorkersFiltersAction({
                    ...filters,
                    status__in: filters['status__in'].filter((item) => item !== status),
                } as TWorkersFiltersCM),
            );
        }
    };

    const handleInactiveChange = () => {
        // eslint-disable-next-line no-prototype-builtins
        if (filters?.hasOwnProperty('is_active')) {
            const obj = { ...filters };
            delete obj?.is_active;
            dispatch(setWorkersFiltersAction({ ...obj } as TWorkersFiltersCM));
        } else {
            dispatch(setWorkersFiltersAction({ ...filters, is_active: false } as TWorkersFiltersCM));
        }
    };

    const activeFilters = [...(filters?.['status__in'] || []), filters?.['is_active']].filter(
        (item) => item !== undefined,
    );

    return (
        <div className="workers-filters-wrapper">
            <WorkersTabs />
            <div className="extra-filters">
                <CategoriesRatiosFilter onChange={selectCategories} />
                <CategoriesFilter
                    selectedCRFIdsForWorkers={filters?.specialization__category_id__in || null}
                    onChange={selectCRFIdsForWorkers}
                />
                <HelpTypeFilter
                    selectedHelpType={filters?.specialization__help_type__in || null}
                    onChange={selectHelpTypeForWorkers}
                />
                <GroupFilter
                    selectedGroup={filters?.group_id__in || null}
                    onChange={selectGroup}
                />
                <FilterStatus
                    activeFilters={activeFilters}
                    filterItems={filterItems}
                    handleStatusChange={handleStatusChange}
                    handleInactiveChange={handleInactiveChange}
                />
            </div>
            <Search
                onChange={searchWorker}
                placeholder="Поиск по ФИО, номеру телефона..."
            />
        </div>
    );
}

export default WorkersFilters;
