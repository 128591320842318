import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';

import { ButtonsContainer, CloseIconWrapper, DialogStyled } from './styles';

interface IConfirmDeleteComplexServiceModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    handleConfirm: () => void;
    complexServiceName: string;
}

export const ConfirmDeleteComplexServiceModal = ({
    isOpen,
    setIsOpen,
    handleConfirm,
    complexServiceName,
}: IConfirmDeleteComplexServiceModalProps) => {
    return (
        <DialogStyled
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">Подтвердите действие</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ margin: '5px 0' }}>
                    Вы действительно хотите удалить комплекс услуг {complexServiceName}?
                </Typography>
                <ButtonsContainer>
                    <Button
                        onClick={handleConfirm}
                        variant="contained"
                    >
                        Удалить
                    </Button>
                    <Button
                        onClick={() => setIsOpen(false)}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </DialogStyled>
    );
};
