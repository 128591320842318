// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть

import TextField from 'components/inputs/TextField';
import { EIssueStatus } from 'enums/issues.enum';
import { ECommissionsType } from 'enums/prices.enum';
import { pricesServicesCommissionsTypeMock } from 'mockData/pricesServices';
import {
    getAllPricesEditIssuePriceListServiceThunk,
    getAllPricesEditIssuePricesThunk,
} from 'store/actions/edit/editIssuePrices';
import { useAppDispatch } from 'store/hooks';
import {
    allPricesEditIssuePricesSelector,
    errorsEditIssuePricesSelector,
    priceRecipientEditIssuePricesSelector,
    searchPricesOptionsEditIssuePricesSelector,
} from 'store/selectors/edit/editIssuePricesSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';
import { toDivideAmount } from 'utils/helpers/toDivideAmount';

import { Add } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import EastIcon from '@mui/icons-material/East';
import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField as TextFieldUi,
} from '@mui/material';
import { IOption } from 'interfaces';
import { IPriceFormValue } from 'interfaces/edit/editIssuePrices';
import { TPriceRecipient } from 'interfaces/issues';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import './InputStack.scss';

function getCalculatedAmount(selectedPrice: number | null, priceDiscount: number | null): number {
    if (selectedPrice) {
        const price = Number.isNaN(Number(selectedPrice)) ? 0 : selectedPrice ?? 0;
        const discount = Number.isNaN(Number(priceDiscount)) ? 0 : priceDiscount ?? 0;
        return Number(((price * (100 - discount)) / 100).toFixed(2));
    } else {
        return Number(((0 * (100 - 0)) / 100).toFixed(2));
    }
}

export interface IProps {
    values?: IPriceFormValue[] | undefined;
    onChange: (values: IPriceFormValue[]) => void;
}

function PriceInputStack({ values, onChange }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const priceRecipient = useSelector(priceRecipientEditIssuePricesSelector);
    const priceOptions = useSelector(searchPricesOptionsEditIssuePricesSelector);
    const prices = useSelector(allPricesEditIssuePricesSelector);
    const errors = useSelector(errorsEditIssuePricesSelector);

    const { address } = issue || {};

    const changePriceInpDebounced = useDebouncedCallback(changePriceInputHandler, 800);

    const [amountValues, setAmountValues] = useState<number[]>([]);
    const [stackValues, setStackValues] = useState(values || []);

    useEffect(() => {
        const newAmountValues = stackValues.map((value) => {
            if (value.discount_type === ECommissionsType.Percentage) {
                return getCalculatedAmount(value.value, value.discount || null);
            }

            return Number((value.value || 0) - (value?.discount || 0));
        });
        setAmountValues(newAmountValues);
    }, [stackValues]);

    async function changePriceInputHandler(val: React.FocusEvent<HTMLInputElement>) {
        try {
            if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
                if (issue) {
                    await dispatch(
                        getAllPricesEditIssuePriceListServiceThunk({
                            help_type: issue.help_type,
                            search: val.target.value || undefined,
                            company_id: issue.company_id,
                            is_active: true,
                            price_list__is_active: true,
                            region_id: issue.ratio_category_id,
                        }),
                    );
                }
            } else {
                await dispatch(
                    getAllPricesEditIssuePricesThunk({
                        params: {
                            search: val.target.value || null,
                            region: address,
                            feature_type: 'string',
                            partner_id: issue?.partner?.id,
                            //category_id__in: categoryIds,
                        },
                    }),
                );
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function updateState(newValues: IPriceFormValue[], newAmountValues: number[]) {
        setStackValues(newValues);
        setAmountValues(newAmountValues);
        onChange && onChange(newValues);
    }

    function onChangePriceName(index: number, option: IOption<string> | null) {
        const newValues = [...stackValues];
        newValues[index].price_id = option;
        // @ts-ignore
        const price = prices?.find((pr) => pr.id === option?.value);
        if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
            newValues[index].value = price?.amount || 0;
        } else {
            newValues[index].value = price?.ratios?.total_ratio_amount || 0;
        }

        updateState(newValues, amountValues);
    }

    function onChangePriceDiscount(index: number, value: IPriceFormValue, event: React.ChangeEvent<HTMLInputElement>) {
        const newValues = [...stackValues];
        const discount = +event.target.value;

        if (newValues[index].discount_type === ECommissionsType.Percentage) {
            if (Number.isNaN(discount)) return;
            if (Number(discount) > 100 || Number(discount) < 0) return;
            newValues[index].discount = discount;
        } else if (newValues[index].discount_type === ECommissionsType.Fixation) {
            if (Number.isNaN(discount)) return;

            if (value.value) {
                if (Number(discount) > value.value || Number(discount) < 0) return;
            } else {
                return;
            }

            newValues[index].discount = discount;
        }
        updateState(newValues, amountValues);
    }

    function onChangePriceValue(index: number, value: IPriceFormValue, event: React.ChangeEvent<HTMLInputElement>) {
        const newValues = [...stackValues];
        const discountPrice = +event.target.value;

        newValues[index].value = discountPrice;

        updateState(newValues, amountValues);
    }

    function onClickAddButton() {
        const newValues = [
            ...stackValues,
            {
                price_id: null,
                discount: null,
                value: null,
                price_recipient: priceRecipient as TPriceRecipient,
                discount_type: ECommissionsType.Percentage,
            },
        ];
        const newAmountValues = [...amountValues, 0];
        updateState(newValues, newAmountValues);
    }

    function onClickDeleteButton(index: number) {
        const newValues = [...stackValues];
        newValues.splice(index, 1);
        const newAmountValues = [...amountValues];
        newAmountValues.splice(index, 1);
        updateState(newValues, newAmountValues);
    }

    function onChangeCommissionsType(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        const newValues = [...stackValues];
        newValues[index].discount_type = event.target.value as ECommissionsType;
        newValues[index].discount = null;
        updateState(newValues, amountValues);
    }

    return (
        <div className="price-inputs-container">
            {stackValues?.map((value, index) => {
                const name = index.toString();
                let errorMessage;
                let isDisabled = false;
                if (issue?.status === EIssueStatus.Solved) {
                    errorMessage =
                        Array.isArray(errors) &&
                        errors?.find((price: any) => price.id === index && value.value)?.message;
                    isDisabled = !!value.id;
                    console.log('errors', errors);
                }
                return (
                    <div
                        className="price-input"
                        key={name}
                    >
                        <div className="price-input-title">Услуга {index + 1}</div>
                        <Autocomplete
                            placeholder="Название услуги"
                            loadingText="Загрузка..."
                            //@ts-ignore
                            value={value?.price_id || ''}
                            options={priceOptions || []}
                            disabled={isDisabled}
                            onFocus={changePriceInpDebounced}
                            onChange={(event, option) => onChangePriceName(index, option)}
                            isOptionEqualToValue={() => true}
                            renderInput={(params) => <TextFieldUi {...params} />}
                            renderOption={(props, option) => {
                                return (
                                    <li
                                        {...props}
                                        key={option.value}
                                        className="item"
                                    >
                                        {option.label} {option.amount && <>({toDivideAmount(option.amount)} ₽)</>}
                                        {option.serviceName && (
                                            <div className="descr">
                                                <span>{option.serviceName}</span>
                                                {option.categoryName && <EastIcon fontSize="small" />}
                                                <span>{option.categoryName}</span>
                                                {option.isAdditional ? <span>(доп. услуга)</span> : null}
                                            </div>
                                        )}
                                    </li>
                                );
                            }}
                        />
                        <FormControl
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                margin: '10px 0',
                            }}
                        >
                            <FormLabel id="demo-radio-buttons-group-label">Выбрать тип скидки</FormLabel>
                            <RadioGroup
                                row
                                name="category_radio"
                                defaultValue={value.discount_type}
                                onChange={(event) => onChangeCommissionsType(index, event)}
                            >
                                {/* eslint-disable-next-line no-shadow */}
                                {Object.entries(pricesServicesCommissionsTypeMock).map(([key, value]) => (
                                    <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio />}
                                        label={value}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>

                        <div className="discount-amount">
                            <div>
                                <TextField
                                    name={`price${name}`}
                                    labelText={'Цена'}
                                    params={{ margin: 'dense', inputProps: { maxLength: 500 } }}
                                    type="number"
                                    sxStyle={{ width: '180px' }}
                                    disabled={isDisabled}
                                    value={value.value || ''}
                                    onChange={(event) => onChangePriceValue(index, value, event)}
                                />
                                {errorMessage && <div style={{ fontSize: '10px', color: 'red' }}>{errorMessage}</div>}
                            </div>
                            <div>
                                <TextField
                                    name={`discount${name}`}
                                    labelText={`Скидка (${pricesServicesCommissionsTypeMock[value.discount_type!]})`}
                                    params={{ margin: 'dense', inputProps: { maxLength: 500 } }}
                                    type="number"
                                    sxStyle={{ width: '180px' }}
                                    disabled={isDisabled}
                                    value={value.discount || ''}
                                    onChange={(event) => onChangePriceDiscount(index, value, event)}
                                />
                                {errorMessage && <div style={{ fontSize: '10px', color: 'red' }}>{errorMessage}</div>}
                            </div>
                            <div className="amount">
                                <div className="amount-title">Сумма (руб.)</div>
                                <div className="amount-value">{amountValues[index] || 0}</div>
                            </div>
                        </div>

                        <div className="delete-button">
                            <IconButton
                                disableRipple
                                onClick={() => onClickDeleteButton(index)}
                                disabled={isDisabled}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                );
            })}
            <div className="add-button">
                <Button
                    startIcon={<Add />}
                    onClick={onClickAddButton}
                >
                    Добавить услугу
                </Button>
            </div>
        </div>
    );
}

export default PriceInputStack;
