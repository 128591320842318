import { companiesApi, priceListsApi, servicesApi } from 'api';
import Loader from 'components/Loader';
import { ERoutes } from 'enums/routes.enum';
import { CopyModal } from 'pages/price-lists/components/modals';
import { ConfirmDeletePriceListModal } from 'pages/price-lists/components/modals/confirm-delete';
import { setPriceList } from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { TPriceList } from 'pages/price-lists/store/types';
import { useAppDispatch } from 'store/hooks';
import { Option, selectMapper, regionsMapper } from 'utils/helpers/selectMapper';

import { MoreVert } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Checkbox, Popover } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BasicsWrapper, ButtonItem, IconWrapper } from './styles';

import { Field, FieldsWrapper, MuiDivider, MuiTextField, SubTitle } from '../styles';

export const PriceListMainPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isOpenCopyModal, setOpenCopyModal] = useState<any>(false);
    const [isOpen, setOpen] = useState<HTMLDivElement | null>(null);
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

    const { priceList } = useSelector(priceListsSelector);

    const [companies, setCompanies] = useState<Option[]>([]);
    const [serviceTypes, setServiceTypes] = useState<Option[]>([]);
    const [regions, setRegions] = useState<Option[]>([]);

    const fetchAutocompleteData = async () => {
        const [companiesRes, serviceTypesRes, regionsRes] = await Promise.all([
            companiesApi.getCompanies(),
            servicesApi.getServiceTypes(),
            priceListsApi.getRegionality({}),
        ]);

        setCompanies(companiesRes.data.map(selectMapper));
        setServiceTypes(serviceTypesRes.data.map(selectMapper));
        setRegions(regionsRes.data.items.map(regionsMapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    const handleChange = (data: any) => {
        if (!priceList) return;

        const [key, value] = Object.entries(data)[0] as [keyof TPriceList, unknown];

        if (priceList[key] !== value) {
            priceListsApi
                .editPriceList(priceList?.id, data)
                .then(({ data }) => {
                    enqueueSnackbar('Изменения сохранены', { variant: 'success' });
                    dispatch(setPriceList(data));
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                });
        }
    };

    const handleDelete = () => {
        priceListsApi.deletePriceList(priceList?.id as string).then(() => {
            enqueueSnackbar('Прайс-лист удален', { variant: 'success' });
            navigate(`/${ERoutes.PriceLists}`);
        });
    };

    const handleCopy = () => {
        dispatch(setPriceList(priceList));
        setOpenCopyModal(true);
        setOpen(null);
    };

    if (!priceList) return <Loader />;

    return (
        <BasicsWrapper>
            <div>
                <MuiDivider sx={{ width: '600px' }} />
                <IconWrapper onClick={(e) => setOpen(e.currentTarget)}>
                    <MoreVert sx={{ color: '#0040F1' }} />
                </IconWrapper>
                <Popover
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
                        <ButtonItem
                            color="inherit"
                            onClick={handleCopy}
                        >
                            Копировать прайс-лист
                        </ButtonItem>
                        <ButtonItem
                            color="error"
                            onClick={() => setIsOpenConfirmDelete(true)}
                        >
                            Удалить прайс-лист
                        </ButtonItem>
                        {/* <ButtonItem color="inherit">Скачать PDF</ButtonItem> */}
                    </div>
                </Popover>
                <FieldsWrapper>
                    <Field>
                        <SubTitle>Компания</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={companies}
                            defaultValue={{ label: priceList?.company?.name || '', value: priceList?.company_id }}
                            onChange={(_, option) => {
                                const { value } = option as Option;

                                handleChange({ company_id: priceList?.company_id });
                                console.log(value);
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                            disabled
                        />
                    </Field>
                    <Field>
                        <SubTitle>Тип услуги</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={serviceTypes}
                            defaultValue={{
                                label: priceList?.service_type?.name || '',
                                value: priceList?.service_type.id,
                            }}
                            onChange={(_, option) => {
                                const { value } = option as Option;

                                console.log(value);
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    defaultValue={priceList?.service_type?.name}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                            disabled
                        />
                    </Field>
                    <Field>
                        <SubTitle>Регион</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={regions}
                            defaultValue={{
                                label: priceList?.region?.title || '',
                                value: priceList?.region?.id,
                            }}
                            onChange={(_, option) => {
                                const { value } = option as Option;

                                console.log(value);
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    defaultValue={priceList?.region?.title}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                            disabled
                        />
                    </Field>
                    <Field>
                        <SubTitle>Системное наименование</SubTitle>
                        <MuiTextField
                            onBlur={(e) => handleChange({ system_name: e.target.value })}
                            defaultValue={priceList?.system_name}
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Наименование</SubTitle>
                        <MuiTextField
                            onBlur={(e) => handleChange({ name: e.target.value })}
                            defaultValue={priceList?.name}
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Активность</SubTitle>
                        <Checkbox
                            onChange={(_, value) => {
                                handleChange({ is_active: value });
                            }}
                            checked={priceList?.is_active}
                            sx={{ padding: 0 }}
                        />
                    </Field>
                    <Field>
                        <SubTitle>Дата создания</SubTitle>
                        <MuiTextField
                            defaultValue={priceList?.created_at.split('T')[0]}
                            size="small"
                            disabled
                        />
                    </Field>
                </FieldsWrapper>
            </div>
            {isOpenCopyModal && <CopyModal setOpen={setOpenCopyModal} />}
            <ConfirmDeletePriceListModal
                isOpen={isOpenConfirmDelete}
                setIsOpen={setIsOpenConfirmDelete}
                priceListName={priceList?.system_name}
                handleConfirm={handleDelete}
            />
        </BasicsWrapper>
    );
};
