import EditSharpIcon from '@mui/icons-material/EditSharp';
import { Button, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ECommissionsType } from '../../../../../enums/prices.enum';
import { IIssuePrice } from '../../../../../interfaces/issues';
import {
    setDialogTitleEditIssuePricesAction,
    setFormValuesEditIssuePricesAction,
    setIssueIdEditIssuePricesAction,
    setPriceRecipientEditIssuePricesAction,
    setStaticPricesEditIssuePricesAction,
} from '../../../../../store/actions/edit/editIssuePrices';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import EditIssuePrices from '../EditIssuePrices';
import PricesTable from '../PricesTable';
import './IssueClientPrices.scss';

interface IProps {
    price: IIssuePrice | null;
    isEditable: boolean;
}

function IssueClientPrices({ price, isEditable }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const clientPrices = price?.details.filter((priceDetail) => priceDetail.price_recipient === 'client') || [];
    const partnerPrices = price?.details.filter((priceDetail) => priceDetail.price_recipient === 'partner') || [];

    function openAddEditPricesListModal() {
        setIsOpen(true);
        dispatch(setIssueIdEditIssuePricesAction(issue?.id || null));
        dispatch(
            setFormValuesEditIssuePricesAction({
                prices: clientPrices.map((pr) => {
                    return {
                        id: pr.id,
                        price_id: { value: pr.price.id, label: pr.price.name },
                        discount:
                            pr.discount_type === ECommissionsType.Percentage ? (pr.discount || 0) * 100 : pr.discount,
                        value: pr.original_amount,
                        price_recipient: pr.price_recipient,
                        discount_type: pr.discount_type,
                    };
                }),
            }),
        );
        dispatch(
            setStaticPricesEditIssuePricesAction(
                partnerPrices.map((pr) => ({
                    price_id: pr.price.id,
                    discount: pr.discount,
                    amount: pr.original_amount,
                    price_recipient: pr.price_recipient,
                    discount_type: pr.discount_type,
                })),
            ),
        );
        dispatch(setPriceRecipientEditIssuePricesAction('client'));
        dispatch(setDialogTitleEditIssuePricesAction('Услуги для клиента'));
    }

    return (
        <div className="issue-client-prices">
            <Typography
                fontWeight={600}
                mb={2}
            >
                Услуги для клиента
            </Typography>

            {!isEditable ? (
                <PricesTable
                    price={price}
                    priceType="client"
                />
            ) : (
                <>
                    <EditIssuePrices
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />
                    {!clientPrices.length ? (
                        <div className="text-info-button-container">
                            <div className="info-text">
                                Вам нужно заполнить услуги и выставить счет, чтобы клиент смог потом оплатить заявку
                            </div>
                            <Button
                                variant="contained"
                                onClick={openAddEditPricesListModal}
                            >
                                Заполнить услуги
                            </Button>
                        </div>
                    ) : (
                        <>
                            <PricesTable
                                price={price}
                                priceType="client"
                            />
                            <div className="text-info-button-container">
                                <div className="info-text">Пока клиент не оплатит счет, вы можете изменить услуги</div>
                                <Button
                                    variant="text"
                                    startIcon={<EditSharpIcon />}
                                    onClick={openAddEditPricesListModal}
                                >
                                    Изменить услуги
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default memo(IssueClientPrices);
