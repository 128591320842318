import Autocomplete from 'components/inputs/Autocomplete';
import ImgInput from 'components/inputs/ImgInput';
import TextField from 'components/inputs/TextField';
import { EIssueCreatedSource, EIssueHelpType } from 'enums/issues.enum';
import { issueCreatedSourceMock, issueCreatedHowYouFindOutMock } from 'mockData/issues';
import {
    getClientHomeDevicesThunk,
    getClientsThunk,
    setClientsAction,
    setFormValuesCCAction,
    setIsOpenCreateClientAction,
} from 'store/actions/clients';
import { setFormValuesCIAction, setSelectedPricesCIAction } from 'store/actions/create/createIssue';
import { setHomeDevicesAction, setIsOpenCDAction } from 'store/actions/devices';
import { getPartnersThunk } from 'store/actions/partners';
import { getPriceListServiceThunk, getPricesThunk } from 'store/actions/prices';
import { useAppDispatch } from 'store/hooks';
import { clientsSelector } from 'store/selectors/clientsSelectors';
import { errorsCreateIssueSelector, formValuesCreateIssueSelector } from 'store/selectors/create/createIssuesSelectors';
import { homeDevicesOptionsSelector, homeDevicesSelector } from 'store/selectors/devicesSelectors';
import { partnersOptionsSelector } from 'store/selectors/partnersSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import getClientOption from 'utils/helpers/getClientOption';
import { getErrorString } from 'utils/helpers/getErrorString';
import { getQueryParamsAsString } from 'utils/helpers/getQueryParamsAsString';

import AddIcon from '@mui/icons-material/Add';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IOption, IPhotoFile } from 'interfaces';
import { IClient, IClientDetail } from 'interfaces/clients';
import { IHomeDeviceDetail } from 'interfaces/devices';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Additional from './Additional';
import Address from './Address';
import CreateClient from './CreateClient';
import CreateDevice, { IOnCreateParams } from './CreateDevice';
import FooterForm from './FooterForm';
import Prices from './Prices';
import TimeWindows from './TimeWindows';

import './CreateIssueForm.scss';

interface ICreateIssueProps {
    onCloseHandler: () => void;
}

function CreateIssueForm({ onCloseHandler }: ICreateIssueProps) {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesCreateIssueSelector);
    const clients = useSelector(clientsSelector);
    const homeDevices = useSelector(homeDevicesSelector);
    const homeDevicesOptions = useSelector(homeDevicesOptionsSelector);
    const partnersOptions = useSelector(partnersOptionsSelector);
    const errors = useSelector(errorsCreateIssueSelector);

    const [client, setClient] = useState<IClient | null>(null);
    const [clientInpVal, setClientInpVal] = useState<string | null>(null);
    const [selectedHomeDevice, setSelectedHomeDevice] = useState<IHomeDeviceDetail | null>(null);

    const clientsOptions = useMemo(() => {
        return clients?.map((item) => getClientOption({ item, clientInpVal }));
    }, [clients, clientInpVal]);

    const currentClient = useMemo(() => {
        if (!formValues?.client_id) return null;

        return clientsOptions?.find((item) => item.value === formValues.client_id) || null;
    }, [formValues?.client_id]);

    const isServiceDisabled =
        !formValues?.client_id ||
        !formValues?.home_device_id ||
        !formValues?.partner_id ||
        !formValues?.help_type ||
        !formValues?.addressFiasId ||
        !formValues?.address;

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getClientsThunk());
                await dispatch(getPartnersThunk());
                dispatch(
                    setFormValuesCIAction({
                        help_type: EIssueHelpType.Installation,
                        created_source: EIssueCreatedSource.CallCenter,
                    }),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (clientInpVal === null) return;
                const queries = getQueryParamsAsString([{ search: clientInpVal }]);
                await dispatch(getClientsThunk(queries));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [clientInpVal]);

    useEffect(() => {
        async function handle() {
            try {
                if (!formValues?.client_id) return;
                if (!clients) {
                    console.error('Error!!! !clients useEffect[formValues?.client_id]');
                    return;
                }
                await dispatch(getClientHomeDevicesThunk(formValues.client_id));
                const foundClient = clients.find(({ id }) => id === formValues.client_id);
                foundClient && setClient(foundClient);
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [formValues?.client_id]);

    useEffect(() => {
        async function handle() {
            try {
                if (!formValues?.help_type || isServiceDisabled) return;

                if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
                    await dispatch(
                        getPriceListServiceThunk({
                            help_type: formValues.help_type,
                            region: formValues?.addressFiasId,
                            company_id: formValues?.partner_id,
                            is_active: true,
                            price_list__is_active: true,
                            feature_type: 'region_fias_id',
                        }),
                    );
                } else {
                    await dispatch(
                        getPricesThunk({
                            filters: {
                                help_type__in: formValues?.help_type,
                                partner_id: formValues?.partner_id,
                                with_additional: true,
                                with_paginate: false,
                                category_id__in: selectedHomeDevice?.category?.id
                                    ? [selectedHomeDevice.category.id]
                                    : null,
                            },
                        }),
                    );
                }
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [formValues?.help_type, formValues?.partner_id, selectedHomeDevice?.category?.id, formValues?.addressFiasId]);

    function openCreateClientHandler() {
        if (!isNaN(Number(clientInpVal)) && clientInpVal !== '' && clientInpVal !== null) {
            dispatch(
                setFormValuesCCAction({
                    phone_number: clientInpVal.trim().startsWith('+7')
                        ? clientInpVal.slice(2)
                        : clientInpVal.trim().startsWith('7')
                        ? clientInpVal.slice(1)
                        : clientInpVal,
                    last_name: '',
                }),
            );
        } else {
            dispatch(setFormValuesCCAction({ last_name: clientInpVal || '', phone_number: '' }));
        }

        dispatch(setIsOpenCreateClientAction(true));
    }

    function onCreateClient(clientDetail: IClientDetail) {
        if (!clients) return;
        dispatch(setClientsAction([{ ...clientDetail }, ...clients]));
        setClientInpVal(clientsOptions?.find((i) => i.value === clientDetail.id)?.label as string);
        dispatch(setFormValuesCIAction({ client_id: clientDetail.id }));
    }

    function changeClientHandler(option: IOption | null) {
        setClientInpVal(option?.label as string);
        dispatch(setFormValuesCIAction({ client_id: option?.value as string }));
    }

    function changeHowYouFindOutHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ howYouFindOut: option?.value as string }));
    }

    function searchClient(val: string | null) {
        setClientInpVal(val);
    }

    function openCreateDeviceHandler() {
        dispatch(setIsOpenCDAction(true));
    }

    function onCreateDevice({ newHomeDevice }: IOnCreateParams) {
        if (!homeDevices) return;
        dispatch(setHomeDevicesAction([{ ...newHomeDevice }, ...homeDevices]));
        dispatch(setIsOpenCDAction(false));
    }

    function changeDeviceHandler(option: IOption | null) {
        setSelectedHomeDevice(homeDevices?.find((i) => i.id === option?.value) || null);
        dispatch(setFormValuesCIAction({ home_device_id: option?.value as string }));
    }

    function changeHelpTypeHandler(e: SelectChangeEvent<EIssueHelpType>) {
        dispatch(setFormValuesCIAction({ help_type: e.target.value as EIssueHelpType }));
    }

    function changeDescriptionHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ description: e.target.value as EIssueHelpType }));
    }

    function changePhotoFileHandler(files: IPhotoFile[] | null) {
        dispatch(
            setFormValuesCIAction({
                photo_files_ids:
                    files?.map(({ id }) => {
                        return id;
                    }) || null,
            }),
        );
    }

    function changeCreatedSourceHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ created_source: (option?.value as EIssueCreatedSource) || null }));
    }

    function changePartnerIdHandler(option: (IOption & { partner_id: string }) | null) {
        if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
            dispatch(setFormValuesCIAction({ partner_id: (option?.value as string) || null }));
        } else {
            dispatch(setFormValuesCIAction({ partner_id: (option?.partner_id as string) || null }));
        }

        dispatch(setSelectedPricesCIAction(null));
    }

    function changeByLoyaltyHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ by_loyalty: e.target.checked }));
    }

    return (
        <div className="create-issue-form-wrapper">
            <Autocomplete
                name="client_id"
                margin="dense"
                inpPlaceholder={'Клиент*'}
                topBtn={
                    <Button
                        onClick={openCreateClientHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить нового клиента
                    </Button>
                }
                noOptionsText={
                    <Button
                        onClick={openCreateClientHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить нового клиента
                    </Button>
                }
                onInpChange={searchClient}
                options={clientsOptions}
                errors={errors}
                onChange={changeClientHandler}
                value={currentClient}
                isOptionEqualToValue={() => true}
            />
            <Autocomplete
                name="home_device_id"
                margin="dense"
                inpPlaceholder={'Техника*'}
                topBtn={
                    <Button
                        onClick={openCreateDeviceHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить новую технику
                    </Button>
                }
                noOptionsText={
                    <Button
                        onClick={openCreateDeviceHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить новую технику
                    </Button>
                }
                disabled={!formValues?.client_id}
                options={homeDevicesOptions}
                errors={errors}
                onChange={changeDeviceHandler}
            />
            <Autocomplete
                name="how_you_find_out"
                margin="dense"
                inpPlaceholder={'Откуда о нас узнали'}
                options={issueCreatedHowYouFindOutMock.map((item) => ({ value: item, label: item }))}
                onChange={changeHowYouFindOutHandler}
            />
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Autocomplete
                        name={'created_source'}
                        margin="dense"
                        inpPlaceholder={'Источник'}
                        onChange={changeCreatedSourceHandler}
                        errors={errors}
                        defaultValue={{
                            value: EIssueCreatedSource.CallCenter,
                            label: issueCreatedSourceMock[EIssueCreatedSource.CallCenter],
                        }}
                        options={Object.keys(issueCreatedSourceMock).map((key) => {
                            return {
                                value: key,
                                label: issueCreatedSourceMock[key as keyof typeof issueCreatedSourceMock],
                            };
                        })}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Autocomplete
                        name={'partner_id'}
                        margin="dense"
                        inpPlaceholder={'Партнер'}
                        onChange={changePartnerIdHandler}
                        options={partnersOptions || []}
                        tooltipText="При смене партнера все услуги сбрасываются"
                    />
                </Grid>
            </Grid>
            <FormControl
                fullWidth
                margin="dense"
            >
                <InputLabel id="help_type-label">Тип заявки*</InputLabel>
                <Select
                    labelId="help_type-label"
                    name="help_type"
                    margin="dense"
                    label="Тип зявки*"
                    onChange={changeHelpTypeHandler}
                    defaultValue={EIssueHelpType.Installation}
                >
                    <MenuItem value={EIssueHelpType.Repair}>Ремонт</MenuItem>
                    <MenuItem value={EIssueHelpType.Installation}>Установка</MenuItem>
                </Select>
            </FormControl>
            <Address />
            <Prices disabled={isServiceDisabled} />
            <TextField
                name="description"
                placeholder="Описание заявки"
                fullWidth
                onChange={changeDescriptionHandler}
                params={{ multiline: true, minRows: 4, margin: 'normal' }}
            />
            {/*<Autocomplete*/}
            {/*	name={'tag'}*/}
            {/*	inpPlaceholder={'Тег'}*/}
            {/*	options={[]}*/}
            {/*	onChange={() => {*/}
            {/*		//Теги заложены на будущее пока их нет на бэке*/}
            {/*		console.log('test tag');*/}
            {/*	}}*/}
            {/*/>*/}
            <div className="photo-create-issue">
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                >
                    Фото
                </Typography>
                <ImgInput
                    name={'photo_file_ids'}
                    onChange={changePhotoFileHandler}
                />
            </div>
            <TimeWindows />
            <Additional />
            <CreateClient onCreate={onCreateClient} />
            {client && (
                <CreateDevice
                    onCreate={onCreateDevice}
                    defaultClient={client}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        name="by_loyalty"
                        onChange={changeByLoyaltyHandler}
                    />
                }
                label="По лояльности (тест)"
            />
            <FooterForm onCloseHandler={onCloseHandler} />
        </div>
    );
}

export default memo(CreateIssueForm);
