import { issuesApi } from 'api';
import { EPaymentMethod } from 'enums/issues.enum';
import { setIssueAction } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';

import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const PaymentMethod = () => {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const [paymentMethod, setPaymentMethod] = useState<string | undefined>(issue?.payment_method);

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSend = () => {
        setIsLoading(true);
        if (!issue) return;
        issuesApi
            .putPaymentMethod(issue.id, {
                payment_method: paymentMethod === 'cash' ? EPaymentMethod.Cash : EPaymentMethod.WithoutCash,
            })
            .then(({ data }) => {
                dispatch(setIssueAction(data));
                setIsOpen(false);
                enqueueSnackbar('Способ оплаты успешно изменен', { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(err, { variant: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="val-prop-iss">
            {issue?.payment_method ? (
                <>
                    <div>{issue?.payment_method === 'without_cash' ? 'Безналичный' : 'Наличные'}</div>
                    <IconButton
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        className="edit-iss-prop-icon"
                    >
                        <EditSharpIcon />
                    </IconButton>
                </>
            ) : (
                <div>Способ не указан</div>
            )}

            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box className="modal-description-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />
                        <div className="title-text">Изменение способа оплаты</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl
                        fullWidth
                        margin="dense"
                    >
                        <InputLabel id="help-type-label">Способ оплаты</InputLabel>
                        <Select
                            labelId="help-type-label"
                            name="help-type"
                            margin="dense"
                            label="Способ оплаты"
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            defaultValue={issue?.payment_method}
                        >
                            <MenuItem value={EPaymentMethod.WithoutCash}>Безналичный</MenuItem>
                            <MenuItem value={EPaymentMethod.Cash}>Наличные</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleSend}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};
