import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import MapFilters from './Filters';
import Map from './Map';
import SidebarMap from './SidebarMap';

import Loader from '../../components/Loader';
import { EMapTabs } from '../../enums/map.enum';
import { IFilters } from '../../interfaces/map';
import MainLayout from '../../layouts/MainLayout';
import { statusFiltersMock } from '../../mockData/issues';
import { setCategoriesRatiosFilterAction, setSelectedCRFAction } from '../../store/actions/categoriesRatiosFilter';
import {
    getMapThunk,
    setAreasMapAction,
    setFiltersMapAction,
    setIsLoadingMapAction,
    setIssuesMapAction,
    setMapTabAction,
    setPinAction,
    setSelectedAreaAction,
    setSelectedIssuesAction,
    setSelectedPinIssueAction,
    setSelectedPinWorkerAction,
    setSelectedWorkersAction,
    setWorkersMapAction,
} from '../../store/actions/map';
import { useAppDispatch } from '../../store/hooks';
import { filtersMapSelector, isLoadingMapSelector } from '../../store/selectors/mapSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { todayEnd, todayStart } from '../../utils/helpers/getDaysInMS';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './MapPage.scss';

function MapPage() {
    const dispatch = useAppDispatch();

    const filters = useSelector(filtersMapSelector);
    const isLoading = useSelector(isLoadingMapSelector);

    useEffect(() => {
        async function handle() {
            try {
                const defaultFilters: IFilters = {
                    time_window__gte: todayStart,
                    time_window__lte: todayEnd,
                    status: statusFiltersMock.find((filter) => filter.isActive)?.statuses,
                };
                dispatch(setFiltersMapAction(defaultFilters));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
        return () => {
            dispatch(setAreasMapAction(null));
            dispatch(setIssuesMapAction(null));
            dispatch(setWorkersMapAction(null));
            dispatch(setMapTabAction(EMapTabs.Areas));
            dispatch(setSelectedIssuesAction(null));
            dispatch(setSelectedWorkersAction(null));
            dispatch(setPinAction(null));
            dispatch(setSelectedPinIssueAction(null));
            dispatch(setSelectedPinWorkerAction(null));
            dispatch(setSelectedAreaAction(null));
            dispatch(setIsLoadingMapAction(false));
            dispatch(setSelectedCRFAction(null));
            dispatch(setCategoriesRatiosFilterAction(null));
        };
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (!filters) return;
                await dispatch(getMapThunk({ filters }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [filters]);
    return (
        <MainLayout>
            <div className="map-page-wrapper">
                <MapFilters />
                <div className="map-page-content">
                    {isLoading ? (
                        <div style={{ zIndex: 100 }}>
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <Map />
                            <SidebarMap />
                        </>
                    )}
                </div>
            </div>
        </MainLayout>
    );
}

export default MapPage;
