import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

interface IConfirmCloseProps {
    isOpen: boolean;
    onCloseHandler: () => void;
    onConfirmHandler: () => void;
}

export const ConfirmClose = ({ isOpen, onCloseHandler, onConfirmHandler }: IConfirmCloseProps) => {
    return (
        <Dialog
            open={isOpen}
            maxWidth={'xs'}
            fullWidth
            scroll="paper"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Отменить создание заявки?</Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: '20px 0', display: 'flex', justifyContent: 'center', gap: '24px' }}>
                <Button
                    sx={{ width: '100px' }}
                    onClick={onConfirmHandler}
                    variant="outlined"
                >
                    Да
                </Button>
                <Button
                    onClick={onCloseHandler}
                    sx={{ width: '100px' }}
                    variant="contained"
                >
                    Нет
                </Button>
            </DialogContent>
        </Dialog>
    );
};
