import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';
import cn from 'classnames';
import React, { ChangeEvent, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { useDebouncedCallback } from 'use-lodash-debounce';

import SearchFilters from './SearchFilters';
import SearchItems from './SearchItems';

import { EIndicies, EIssueFilter } from '../../../../enums/search.enum';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import {
    getSearchThunk,
    setFiltersSearchAction,
    setIndiciesSearchAction,
    setIsOpenSearchAction,
    setTextSearchAction,
} from '../../../../store/actions/search';
import { useAppDispatch } from '../../../../store/hooks';
import {
    filtersSearchSelector,
    indiciesSearchSelector,
    isOpenSearchSelector,
    textSearchSelector,
} from '../../../../store/selectors/searchSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import './SearchStyles.scss';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => {
    return {
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            border: '1px solid rgb(0 0 0 / 20%)',
            borderRadius: '6px',
        },
    };
});

function SearchTopBar() {
    const dispatch = useAppDispatch();

    const filters = useSelector(filtersSearchSelector);
    const indicies = useSelector(indiciesSearchSelector);
    const text = useSelector(textSearchSelector);
    const isOpen = useSelector(isOpenSearchSelector);

    const searchRef = useOutsideClick(outsideClickHandle, true);
    const changeInpHandleDebounced = useDebouncedCallback(changeInpHandle, 800);

    useEffect(() => {
        function handle() {
            /* TODO выяснить у бэка будут ли ещё indicies, и как они строятся */
            dispatch(setIndiciesSearchAction([EIndicies.Issues]));
            dispatch(setFiltersSearchAction({ issue_filters: EIssueFilter.Oid }));
        }
        handle();
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (!filters || !text || !indicies) {
                    return;
                }
                await dispatch(getSearchThunk({ filters, text, indicies }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, [text, filters, indicies]);

    function changeInpHandle(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setTextSearchAction(e.target.value));
    }

    function onFocusInpHandle() {
        dispatch(setIsOpenSearchAction(true));
    }

    function outsideClickHandle() {
        dispatch(setIsOpenSearchAction(false));
    }

    return (
        <Search
            sx={{ pointerEvents: 'all' }}
            ref={searchRef}
        >
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>

            <StyledInputBase
                onChange={changeInpHandleDebounced}
                placeholder="Поиск..."
                inputProps={{ 'aria-label': 'search', variant: 'outlined' }}
                onFocus={onFocusInpHandle}
            />
            <Paper
                elevation={1}
                className={cn('search-drop-down', { isOpen })}
            >
                <SearchFilters />
                <SearchItems />
            </Paper>
        </Search>
    );
}

export default memo(SearchTopBar);
