import { Button, styled } from '@mui/material';

export const ButtonItem = styled(Button)({
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '2px 14px',
    fontSize: '16px',
    '.MuiButtonBase-root': {},
});

export const MenuWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
});
