import ButtonGroup, { IButtonGroupItem } from 'components/ButtonGroup';
import { EIssueStatus } from 'enums/issues.enum';
import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import CreateIssueCopy from 'pages/issue/CreateIssueCopy';
import { setTotalUnseenCountIssuesAction } from 'store/actions/chats';
import { getClientThunk } from 'store/actions/clients';
import { setIsOpenCreateIssueAction } from 'store/actions/create/createIssue';
import {
    setIsOpenCreateIssueReminderAction,
    setIssueIdCreateIssueReminderAction,
} from 'store/actions/create/createIssueReminder';
import { setIsOpenEditIssueStatusAction, setIssueIdEditIssueStatusAction } from 'store/actions/edit/editIssueStatus';
import { getIssueThunk, putIssueArchiveThunk, setIssueAction } from 'store/actions/issues';
import { setSetSelectedOrdersAction } from 'store/actions/orders';
import { useAppDispatch } from 'store/hooks';
import { clientSelector } from 'store/selectors/clientsSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { profileSelector } from 'store/selectors/profileSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';
import { isAccessDenied } from 'utils/helpers/isAccesDenite';
import { timeWindowsFormatter } from 'utils/helpers/timeWindowsFormatter';

import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import IssueCommentsWrapper from './comments-history';
import { fetchHistoryComments } from './comments-history/store';
import CreateIssueReminder from './CreateIssueReminder';
import EditIssueStatus from './EditIssueStatus';
import IssueDetail from './IssueDetail';
import './Issue.scss';

function IssuePage() {
    const { issueId } = useParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const issue = useSelector(issueSelector);
    const issueClient = useSelector(clientSelector);

    const archiveIsVisible = useMemo(() => {
        switch (issue?.status) {
            case EIssueStatus.Canceled: {
                return false;
            }
            case EIssueStatus.Solved: {
                return false;
            }
            default:
                return true;
        }
    }, [issue?.status]);

    useEffect(() => {
        dispatch(setTotalUnseenCountIssuesAction(issue?.count_unseen_messages || null));
    }, [issue]);

    useEffect(() => {
        return () => {
            dispatch(setIssueAction(null));
            dispatch(setSetSelectedOrdersAction(null));
        };
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (!issueId) {
                    console.error('Error!!! !issueId IssuePage');
                    return;
                }
                await dispatch(getIssueThunk(issueId));
                await dispatch(fetchHistoryComments(issueId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, [issueId]);

    function getButtonGroupItems(): IButtonGroupItem[] {
        const buttonGroupItems: IButtonGroupItem[] = [
            {
                title: 'Скопировать информацию',
                handler: () => copyInformation(),
            },
            {
                title: 'Создать напоминание',
                handler: () => openCreateReminderModal(),
            },
            {
                title: 'Создать копию',
                handler: () => showDialogIssueCopy(),
            },
            {
                title: issue?.is_archived ? 'Убрать из архива' : 'Перенести в архив',
                handler: () => changeIssueArchive(),
                disabled: archiveIsVisible,
            },
        ];

        if (
            isAccessDenied({ profile, entityRole: EPermissionsKeys.Issues, action: EPermissionsValues.SetPriceSolved })
        ) {
            buttonGroupItems.push({
                title: 'Изменить статус',
                handler: () => openChangeStatusModal(),
            });
        }

        return buttonGroupItems;
    }

    function showDialogIssueCopy() {
        dispatch(setIsOpenCreateIssueAction(true));
    }

    async function copyInformation() {
        if (!issue?.address && issue?.client) {
            await dispatch(getClientThunk(issue.client.id));
        }
        if (issue) {
            const issueInfo = [
                issue.oid,
                issue.client?.first_name,
                issue.client?.last_name,
                issue.client?.middle_name,
                issue.client?.phone_number,
                issue.sub_issues?.[0].home_device?.category?.name,
                issue.sub_issues?.[0].home_device?.brand?.name,
                issue.description || issue.comment,
                issue?.address || issueClient?.addresses?.[0]?.address,
                timeWindowsFormatter(issue.time_windows) || 'Не выбрана',
            ]
                .filter((e) => e)
                .join(' / ');

            await navigator.clipboard.writeText(issueInfo);
        }
    }

    function openCreateReminderModal() {
        if (issue) {
            dispatch(setIssueIdCreateIssueReminderAction(issue.id));
            dispatch(setIsOpenCreateIssueReminderAction(true));
        }
    }

    function openChangeStatusModal() {
        if (issue) {
            dispatch(setIssueIdEditIssueStatusAction(issue.id));
            dispatch(setIsOpenEditIssueStatusAction(true));
        }
    }

    async function changeIssueArchive() {
        try {
            if (!issue || !issueId) return;
            await dispatch(putIssueArchiveThunk({ issueId, data: { is_archived: !issue.is_archived } }));
            navigate(issue.is_archived ? `/${ERoutes.Archive}` : `/${ERoutes.Issues}`);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <MainLayout>
            <div className="issue-page-wrapper">
                <div className="issue-page-header">
                    <ButtonGroup
                        title="Меню"
                        buttonsInfo={getButtonGroupItems()}
                    />
                    <CreateIssueReminder />
                    <CreateIssueCopy />
                    <EditIssueStatus currentStatus={issue?.status || null} />
                </div>
                <div className="issue-page-content">
                    <IssueDetail />
                    <IssueCommentsWrapper />
                </div>
            </div>
        </MainLayout>
    );
}

export default IssuePage;
