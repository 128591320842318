import { Box, styled } from '@mui/material';

export const ControlsWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

export const FiltersWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
});
