import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ prices }: RootState) => prices;

export const pricesSelector = createSelector(state, ({ prices }) => prices);
export const priceSelector = createSelector(state, ({ price }) => price);
export const pricesOptionsSelector = createSelector([pricesSelector], (prices) => {
    return (
        prices?.map(({ id, name, amount }) => {
            return { id, label: name, amount };
        }) || null
    );
});
export const pricesFiltersSelector = createSelector(state, ({ filters }) => filters);
export const pricesIsAdditionalOptionsSelector = createSelector([pricesSelector], (prices) => {
    return (
        prices
            ?.filter((price) => price?.is_additional)
            ?.map(({ id, name, amount }) => {
                return { id, label: name, amount };
            }) || null
    );
});
export const pricesTabSelector = createSelector(state, ({ pricesTab }) => pricesTab);
export const paginationSelector = createSelector(state, ({ pagination }) => pagination);
export const isPriceLoadingSelector = createSelector(state, ({ isPriceLoading }) => isPriceLoading);
export const isOpenDeletePriceSelector = createSelector(state, ({ isOpenDeletePrice }) => isOpenDeletePrice);
