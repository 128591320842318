import dayjs from 'dayjs';

export default ({ start, end }: { start: string | null; end: string | null }) => {
    if (!start || !end) return undefined;

    const today = dayjs();
    const yesterday = today.subtract(1, 'day'); // Получаем вчерашний день

    const gteDate = dayjs(start); // Преобразуем start в Day.js объект
    const lteDate = dayjs(end); // Преобразуем end в Day.js объект

    const isToday = (date: dayjs.Dayjs) => date.isSame(today, 'day'); // Проверяем, что дата сегодня
    const isYesterday = (date: dayjs.Dayjs) => date.isSame(yesterday, 'day'); // Проверяем, что дата вчера

    if (isToday(gteDate) && isToday(lteDate)) {
        return {
            value: {
                id: 'Сегодня',
                title: 'Сегодня',
            },
            startTime: gteDate as unknown as Date,
            endTime: lteDate as unknown as Date,
        };
    }

    if (isYesterday(gteDate) && isYesterday(lteDate)) {
        return {
            value: {
                id: 'Вчера',
                title: 'Вчера',
            },
            startTime: gteDate as unknown as Date,
            endTime: lteDate as unknown as Date,
        };
    }

    if (gteDate && lteDate) {
        return {
            value: {
                id: 'Период',
                title: 'Период',
            },
            startTime: gteDate as unknown as Date,
            endTime: lteDate as unknown as Date,
        };
    }

    return undefined;
};
