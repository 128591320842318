import { EIssueHelpType } from 'enums/issues.enum';
import { TPriceListService, TPriceListServiceFilters } from 'pages/price-lists/store/types';
import { AppDispatch } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { issuesApi, pricesApi, servicesPriceListsApi } from '../../../api';
import { EEditIssuePricesActions } from '../../../enums/actions.enum';
import {
    IFormValuesEditIssuePrices,
    IPutIssuePricesReq,
    ISetSearchPricesEditIssuePricesAction,
    ISetDialogTitleEditIssuePricesAction,
    ISetFormValuesEditIssuePricesAction,
    ISetIsOpenEditIssuePricesAction,
    ISetIssueIdEditIssuePricesAction,
    ISetPriceRecipientEditIssuePricesAction,
    ISetAllPricesEditIssuePricesAction,
    ISetStaticPricesEditIssuePricesAction,
    ISetIsOpenEditIssuePricesClientAction,
    ISetErrorsEditIssuePricesAction,
    IError,
} from '../../../interfaces/edit/editIssuePrices';
import { IIssue, TPriceRecipient } from '../../../interfaces/issues';
import { IPrice, IPricesFilters } from '../../../interfaces/prices';
import { getQueryParamsAsString, TArrayParams } from '../../../utils/helpers/getQueryParamsAsString';
import { setIssueAction } from '../issues';

export const setIsOpenEditIssuePricesAction = (payload: boolean): ISetIsOpenEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetIsOpen,
    payload,
});

export const setIsOpenEditIssuePricesClientAction = (payload: boolean): ISetIsOpenEditIssuePricesClientAction => ({
    type: EEditIssuePricesActions.SetIsOpenClient,
    payload,
});

export const setIssueIdEditIssuePricesAction = (payload: IIssue['id'] | null): ISetIssueIdEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetIssueId,
    payload,
});

export const setStaticPricesEditIssuePricesAction = (
    payload: IPutIssuePricesReq[] | null,
): ISetStaticPricesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetStaticPrices,
    payload,
});

export const setFormValuesEditIssuePricesAction = (
    payload: IFormValuesEditIssuePrices | null,
): ISetFormValuesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetFormValues,
    payload,
});

export const setErrorsEditIssuePricesAction = (payload: IError[] | null): ISetErrorsEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetErrors,
    payload,
});

export const setSearchPricesEditIssuePricesAction = (
    payload: IPrice[] | null,
): ISetSearchPricesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetSearchPrices,
    payload,
});

export const setAllPricesEditIssuePricesAction = (
    payload: IPrice[] | TPriceListService[] | null,
): ISetAllPricesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetAllPrices,
    payload,
});

export const setPriceRecipientEditIssuePricesAction = (
    payload: TPriceRecipient | null,
): ISetPriceRecipientEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetPriceRecipient,
    payload,
});

export const setDialogTitleEditIssuePricesAction = (payload: string | null): ISetDialogTitleEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetDialogTitle,
    payload,
});

export const editIssuePricesThunk = (issueId: string, newPrices: IPutIssuePricesReq[]) => {
    return async (dispatch: AppDispatch) => {
        try {
            const { data } = await issuesApi.putIssuePrices(issueId, newPrices);

            dispatch(setIssueAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const editIssuePricesSolvedThunk = (issueId: string, newPrices: IPutIssuePricesReq[]) => {
    return async (dispatch: AppDispatch) => {
        try {
            const { data } = await issuesApi.putIssuePricesSolved(issueId, newPrices);

            dispatch(setIssueAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

interface IGetPricesEditIssuePricesThunkParams {
    params?: IPricesFilters | null;
}

export const getAllPricesEditIssuePricesThunk = ({ params }: IGetPricesEditIssuePricesThunkParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [{ with_additional: true }];
            if (params) {
                const { partner_id, region, feature_type, search, category_id__in, company_id } = params;

                if (search) {
                    queriesObj.push({ search });
                }
                if (feature_type) {
                    queriesObj.push({ feature_type });
                }
                if (partner_id) {
                    queriesObj.push({ partner_id });
                }
                if (company_id) {
                    queriesObj.push({ company_id });
                }
                if (region) {
                    queriesObj.push({ region });
                }
                if (category_id__in) {
                    queriesObj.push({ category_id__in });
                }
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await pricesApi.getPricesWithOutPagination(queries);
            dispatch(setAllPricesEditIssuePricesAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const getAllPricesEditIssuePriceListServiceThunk =
    (filters: TPriceListServiceFilters) => async (dispatch: AppDispatch) => {
        try {
            const {
                company_id,
                category_id__in,
                region,
                help_type,
                search,
                feature_type,
                is_active,
                price_list__is_active,
                region_id,
                with_general_price_list,
            } = filters;

            const params: Record<string, any> = {
                with_paginate: false,
                feature_type,
                search,
                company_id,
                help_type,
                region,
                region_id,

                category_id__in,
                is_active,
                price_list__is_active,
                with_general_price_list,
            };

            if (help_type === EIssueHelpType.Repair) {
                params.with_general_price_list = true;
            }

            if (help_type === EIssueHelpType.Installation && !region) {
                params.with_general_price_list = false;
            }

            const { data } = await servicesPriceListsApi.getServicesComplexWithOutPaginate(getFilledParams(params));
            dispatch(setAllPricesEditIssuePricesAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
