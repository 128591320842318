import { styled } from '@mui/material';

export const HistoryItem = styled('div')({
    padding: '10px 5px',
});

export const Time = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    color: '#878EA5',
});

export const Statuses = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '10px',
    rowGap: '5px',
    margin: '10px 0',
});

export const UserDataWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '6px',
});

export const ChangeTitle = styled('div')({
    fontSize: '12px',
    color: '#878EA5',
});

export const Comment = styled('div')({
    fontSize: '12px',
});
