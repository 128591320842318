import { WorkerStatusesMock } from 'mockData/workers';

import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box, Checkbox, Stack } from '@mui/material';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import UserSmallData from '../../../components/UserSmallData';
import { IWorkerCM } from '../../../interfaces/candidatesModal';
import { setSelectedWorkersCMAction } from '../../../store/actions/candidatesModal';
import { useAppDispatch } from '../../../store/hooks';
import { selectedWorkersCMSelector, workersCMSelector } from '../../../store/selectors/candidatesModalSelectors';

import './Workers.scss';

function Workers() {
    const dispatch = useAppDispatch();

    const workers = useSelector(workersCMSelector);
    const selectedWorkers = useSelector(selectedWorkersCMSelector);

    function onClickWorkerHandler(worker: IWorkerCM) {
        const newSelectedWorkers = selectedWorkers ? [...selectedWorkers] : [];
        const newWorker = { ...worker, isCandidate: undefined };

        if (newSelectedWorkers.length) {
            const foundIndex = newSelectedWorkers.findIndex(({ id }) => newWorker.id === id);
            if (foundIndex !== -1) {
                newSelectedWorkers.splice(foundIndex, 1);
            } else {
                newSelectedWorkers.push(newWorker);
            }
        } else {
            newSelectedWorkers.push(newWorker);
        }
        dispatch(setSelectedWorkersCMAction(newSelectedWorkers));
    }

    return (
        <Box
            className={workers && workers.length ? 'cm-workers' : 'empty-list'}
            sx={{ mt: 3 }}
        >
            {workers && workers.length
                ? workers?.map((worker, index) => {
                      const isActiveCandidate = worker?.status === 'connected_working_ready' && !worker?.is_active;
                      return (
                          <div
                              key={worker.id}
                              onClick={() => onClickWorkerHandler(worker)}
                              tabIndex={index}
                              role="button"
                              className="cm-worker"
                          >
                              {!!worker && <UserSmallData user={worker} />}
                              <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                  color={WorkerStatusesMock[worker.status]?.color}
                              >
                                  <PanoramaFishEyeIcon
                                      style={{
                                          width: 11,
                                          height: 11,
                                          stroke: WorkerStatusesMock[worker.status]?.color,
                                          strokeWidth: 3,
                                      }}
                                  />
                                  {isActiveCandidate ? 'Неактивен' : WorkerStatusesMock[worker.status]?.title}
                                  <Checkbox checked={worker.isChecked} />
                              </Stack>
                          </div>
                      );
                  })
                : 'Работники не найдены'}
        </Box>
    );
}

export default memo(Workers);
