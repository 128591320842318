import { priceListsApi, servicesPriceListsApi, servicesApi } from 'api';
import Loader from 'components/Loader';
import { FilterIn } from 'features/filter-in';
import { TDescendants, TPriceList } from 'pages/price-lists/store/types';
import { FootNote } from 'pages/services/components/footnote';
import { CloseIconWrapper } from 'pages/services/styles';
import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';
import { Params } from 'types';

import { Search } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Divider,
    Typography,
    Autocomplete,
    Checkbox,
    InputAdornment,
} from '@mui/material';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ICompany } from 'interfaces/companies';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import {
    ButtonsWrapper,
    CellInputProps,
    CheckBox,
    CheckBoxContainer,
    CheckBoxItem,
    CheckLabel,
    SelectItem,
    MuiTextField,
    SubTitle,
    SelectedContainer,
} from './styles';

import { Asterisk, Field, FieldContainer } from '../../../../create-service/styles';
import { fetchServicesPriceLists } from '../../store';
import { ButtonsContainer } from '../../styles';
import { AddConfirm } from '../add-confirm';

export type TForm = {
    name: string;
    description: string;
    service_ids: string[];
    amount: number | undefined;
    commission: {
        value: number | undefined;
    };
    is_active: boolean | undefined;
    include_descriptions: any[];
    is_active_integration: boolean;
    availability_type: string;
    external_id: string | null;
    price_lists: {
        all: boolean;
        type: string;
        company_id__in?: string[] | null;
        region_id__in?: string[] | null;
        pk__in?: string[];
    };
};

type TFilters = {
    search: string;
    size: number;
    region_id__in: [];
    company_id__in: [];
    service_type_id__in: string[] | undefined;
};

type Props = {
    isOpenModal: boolean;
    setOpenModal: (value: boolean) => void;
};

export const AddInPriceLists: FC<Props> = ({ isOpenModal, setOpenModal }) => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);

    const initialState: TForm = {
        name: serviceById?.name as string,
        description: serviceById?.description as string,
        service_ids: [],
        amount: undefined,
        commission: {
            value: undefined,
        },
        is_active: serviceById?.is_active,
        include_descriptions: [],
        is_active_integration: true,
        availability_type: 'client',
        external_id: null,
        price_lists: {
            all: true,
            type: 'all',
        },
    };

    const initialFilters: TFilters = {
        search: '',
        size: 20,
        region_id__in: [],
        company_id__in: [],
        service_type_id__in: serviceById?.service_types?.map((item) => item.id),
    };

    const [loading, setLoading] = useState(false);
    const [checkValue, setCheckValue] = useState<string>('default');
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const [form, setForm] = useState<TForm>(initialState);

    const [descendants, setDescendants] = useState<TDescendants[]>([]);
    const [priceLists, setPriceLists] = useState<TPriceList[]>([]);
    const [filters, setFilters] = useState<TFilters>(initialFilters);

    const handleCreatePriceList = () => {
        const body = { ...form, service_ids: [serviceById?.id] };

        if (body.price_lists.type === 'filter' && !body.price_lists.company_id__in?.length) {
            body.price_lists.company_id__in = null;
        }

        if (body.price_lists.type === 'filter' && !body.price_lists.region_id__in?.length) {
            body.price_lists.region_id__in = null;
        }

        servicesPriceListsApi
            .createServicesComplex(body)
            .then(({ data }) => {
                setConfirmOpen(false);
                setOpenModal(false);

                dispatch(fetchServicesPriceLists());

                enqueueSnackbar(`Комплекс услуг добавлен в ${data.length} прайс-листов`, { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const converter = async (func: (params: any) => Promise<AxiosResponse<ICompany[]>>, params: Params | undefined) => {
        const res = await func({ size: 20, ...params });
        return { data: { items: res.data, total: res.data.length } };
    };

    const handleChangeFilter = (itemId: string, filterType: 'region_id__in' | 'company_id__in') => {
        const getIds = () => {
            if (filters[filterType].includes(itemId as never)) {
                return filters[filterType].filter((id) => id !== itemId);
            } else {
                return [...filters[filterType], itemId];
            }
        };

        setFilters((prev) => {
            return { ...prev, [filterType]: getIds() };
        });
    };

    const handleFilterClean = (key: string) => {
        setFilters((prev) => {
            return { ...prev, [key]: [] };
        });
    };

    useEffect(() => {
        if (!isOpenModal) return;

        servicesApi.getDescendants({ services_ids: [serviceById?.id] }).then(({ data }) => {
            setDescendants(data);

            data.forEach((item) => {
                setForm((prev) => {
                    return {
                        ...prev,
                        include_descriptions: [
                            ...prev.include_descriptions,
                            { service_id: item.id, is_include_in_price: item.is_include_in_price },
                        ],
                    };
                });
            });
        });

        return () => {
            setFilters(initialFilters);
            setForm(initialState);
            setCheckValue('default');
        };
    }, [isOpenModal]);

    const fetchPriceListWithActiveIds = async () => {
        setLoading(true);

        const { data: firstData } = await priceListsApi.getPriceLists({ pk__in: form.price_lists.pk__in });
        const { data: secondData } = await priceListsApi.getPriceLists({
            ...filters,
            exclude_pk__in: form.price_lists.pk__in,
        });

        setPriceLists([...firstData.items, ...secondData.items]);
        setPriceLists((prev) => sortArray(prev));

        setLoading(false);
    };

    const fetchPriceList = async () => {
        setLoading(true);

        const { data } = await priceListsApi.getPriceLists({
            ...filters,
            company_id__in: filters?.company_id__in?.length
                ? filters?.company_id__in
                : serviceById?.companies?.map((item) => item.id),
        });

        setPriceLists(data.items);
        setPriceLists((prev) => sortArray(prev));

        setLoading(false);
    };

    const sortArray = (array: TPriceList[]) => array.sort((a) => (form.price_lists?.pk__in?.includes(a.id) ? -1 : 1));

    const filterSearch = (search: string) => {
        setFilters({ ...filters, search });
    };

    const filterSearchWithDebounced = useDebouncedCallback(filterSearch, 800);

    useEffect(() => {
        if (!isOpenModal) return;

        if (form.price_lists?.pk__in?.length) {
            fetchPriceListWithActiveIds();
            return;
        }

        fetchPriceList();
    }, [filters, isOpenModal]);

    const render = (value: string) => {
        switch (value) {
            case 'addFilters':
                return (
                    <ButtonsContainer>
                        <FilterIn
                            title="Регион"
                            onChange={(itemId) => {
                                if (form?.price_lists?.region_id__in?.includes(itemId)) {
                                    const array = form?.price_lists?.region_id__in?.filter(
                                        (region) => region !== itemId,
                                    );
                                    setForm((prev) => {
                                        return {
                                            ...prev,
                                            price_lists: {
                                                ...prev.price_lists,
                                                region_id__in: array,
                                            },
                                        };
                                    });
                                } else {
                                    setForm((prev) => {
                                        return {
                                            ...prev,
                                            price_lists: {
                                                ...prev.price_lists,
                                                region_id__in: [...(prev.price_lists.region_id__in || []), itemId],
                                            },
                                        };
                                    });
                                }
                            }}
                            activeIds={(form?.price_lists?.region_id__in as string[]) ?? []}
                            fetcher={(params) => priceListsApi.getRegionality({ size: 20, ...params })}
                            cleaner={() => {
                                setForm((prev) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            ...prev.price_lists,
                                            region_id__in: [],
                                        },
                                    };
                                });
                            }}
                        />
                        <FilterIn
                            title="Компания"
                            onChange={(itemId) => {
                                if (form?.price_lists?.company_id__in?.includes(itemId)) {
                                    const array = form?.price_lists?.company_id__in?.filter(
                                        (company) => company !== itemId,
                                    );
                                    setForm((prev) => {
                                        return {
                                            ...prev,
                                            price_lists: {
                                                ...prev.price_lists,
                                                company_id__in: array,
                                            },
                                        };
                                    });
                                } else {
                                    setForm((prev) => {
                                        return {
                                            ...prev,
                                            price_lists: {
                                                ...prev.price_lists,
                                                company_id__in: [...(prev.price_lists.company_id__in || []), itemId],
                                            },
                                        };
                                    });
                                }
                            }}
                            activeIds={(form?.price_lists?.company_id__in as string[]) ?? []}
                            fetcher={(params) => converter(priceListsApi.getCompanies, params) as AxiosPromise}
                            defaultOptions={serviceById?.companies}
                            cleaner={() => {
                                setForm((prev) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            ...prev.price_lists,
                                            company_id__in: [],
                                        },
                                    };
                                });
                            }}
                        />
                    </ButtonsContainer>
                );
            case 'addInSelected':
                return (
                    <div>
                        <ButtonsContainer>
                            <TextField
                                placeholder="Поиск"
                                variant="outlined"
                                size="small"
                                onChange={(e) => filterSearchWithDebounced(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                defaultValue={filters?.search}
                            />
                            <FilterIn
                                title="Регион"
                                onChange={(itemId) => handleChangeFilter(itemId, 'region_id__in')}
                                activeIds={filters?.region_id__in ?? []}
                                fetcher={(params) => priceListsApi.getRegionality({ size: 20, ...params })}
                                cleaner={() => handleFilterClean('region_id__in')}
                            />
                            <FilterIn
                                title="Компания"
                                onChange={(itemId) => handleChangeFilter(itemId, 'company_id__in')}
                                activeIds={filters?.company_id__in ?? []}
                                fetcher={(params) => converter(priceListsApi.getCompanies, params) as AxiosPromise}
                                defaultOptions={serviceById?.companies}
                                cleaner={() => handleFilterClean('company_id__in')}
                            />
                        </ButtonsContainer>
                        <SelectedContainer loading={loading}>
                            {loading ? (
                                <Loader />
                            ) : !priceLists?.length ? (
                                'Список пуст'
                            ) : (
                                priceLists?.map((item) => {
                                    const isChecked = form.price_lists?.pk__in?.find((el) => el === item.id);
                                    return (
                                        <SelectItem key={item.id}>
                                            <CheckBox
                                                checked={Boolean(isChecked)}
                                                onClick={() => {
                                                    if (form?.price_lists?.pk__in?.includes(item.id)) {
                                                        const array = form?.price_lists?.pk__in?.filter(
                                                            (pkId) => pkId !== item.id,
                                                        );
                                                        setForm((prev) => {
                                                            return {
                                                                ...prev,
                                                                price_lists: {
                                                                    ...prev.price_lists,
                                                                    pk__in: array,
                                                                },
                                                            };
                                                        });
                                                    } else {
                                                        setForm((prev) => {
                                                            return {
                                                                ...prev,
                                                                price_lists: {
                                                                    ...prev.price_lists,
                                                                    pk__in: [
                                                                        ...(prev.price_lists.pk__in || []),
                                                                        item.id,
                                                                    ],
                                                                },
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                            <span>{item.system_name}</span>
                                        </SelectItem>
                                    );
                                })
                            )}
                        </SelectedContainer>
                    </div>
                );
        }
    };

    return (
        <Dialog
            open={isOpenModal}
            onClose={() => setOpenModal(false)}
            sx={{
                '.MuiDialog-paper': {
                    minWidth: '992px',
                },
            }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setOpenModal(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">Добавить в прайс-лист</Typography>
            </DialogTitle>
            <DialogContent>
                <CheckBoxContainer>
                    <CheckBoxItem>
                        <CheckBox
                            id="default"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => {
                                setCheckValue('default');
                                setForm((prev) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            all: true,
                                            type: 'all',
                                        },
                                    };
                                });
                            }}
                            checked={checkValue === 'default'}
                        />
                        <CheckLabel htmlFor="default">
                            Добавить во все прайс-листы компаний услуги
                            <span>
                                {serviceById?.service_types?.map((type, idx) => {
                                    return (
                                        <span key={type.id}>{`${idx ? ', ' : ' ('}${type.name}${
                                            idx === serviceById?.service_types.length - 1 ? ')' : ''
                                        }`}</span>
                                    );
                                })}
                            </span>
                        </CheckLabel>
                    </CheckBoxItem>
                    <CheckBoxItem>
                        <CheckBox
                            id="addFilters"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => {
                                setCheckValue('addFilters');
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            region_id__in: [],
                                            company_id__in: [],
                                            type: 'filter',
                                        },
                                    };
                                });
                            }}
                            checked={checkValue === 'addFilters'}
                        />
                        <CheckLabel htmlFor="addFilters">
                            Добавить во все прайс-листы, соответствующие фильтру
                            <span>
                                {serviceById?.service_types?.map((type, idx) => {
                                    return (
                                        <span key={type.id}>{`${idx ? ', ' : ' ('}${type.name}${
                                            idx === serviceById?.service_types.length - 1 ? ')' : ''
                                        }`}</span>
                                    );
                                })}
                            </span>
                        </CheckLabel>
                    </CheckBoxItem>
                    <CheckBoxItem>
                        <CheckBox
                            id="addInSelected"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => {
                                setCheckValue('addInSelected');
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            pk__in: [],
                                            type: 'chosen',
                                        },
                                    };
                                });
                            }}
                            checked={checkValue === 'addInSelected'}
                        />
                        <CheckLabel htmlFor="addInSelected">Добавить в выбранные</CheckLabel>
                    </CheckBoxItem>
                </CheckBoxContainer>

                {render(checkValue)}

                <FieldContainer>
                    <Field>
                        <SubTitle>
                            Наименование<Asterisk>*</Asterisk>
                        </SubTitle>
                        <MuiTextField
                            multiline
                            onChange={(e) => {
                                setForm((prev) => {
                                    return { ...prev, name: e.target.value.trim() };
                                });
                            }}
                            defaultValue={serviceById?.name}
                            placeholder="Наименование"
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Описание</SubTitle>
                        <MuiTextField
                            onChange={(e) => {
                                setForm((prev) => {
                                    return { ...prev, description: e.target.value.trim() };
                                });
                            }}
                            defaultValue={serviceById?.description}
                            minRows={4}
                            placeholder="Описание"
                            size="small"
                            multiline
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            <SubTitle>Что входит в стоимость</SubTitle>
                            <Box sx={{ color: '#A4A7B8', fontSize: '12px' }}>
                                Невыбранные элементы будут отображаться в блоке <div>«Что не входит в стоимость»</div>
                            </Box>
                        </SubTitle>
                        <div>
                            {descendants.map((descendant) => {
                                return (
                                    <div key={descendant.id}>
                                        <Checkbox
                                            checked={descendant.is_include_in_price}
                                            sx={{ padding: '5px 0' }}
                                            onClick={() => {
                                                setDescendants((prev) =>
                                                    prev.map((item) =>
                                                        item.id === descendant.id
                                                            ? {
                                                                  ...item,
                                                                  is_include_in_price: !item.is_include_in_price,
                                                              }
                                                            : item,
                                                    ),
                                                );
                                                setForm((prev) => {
                                                    return {
                                                        ...prev,
                                                        include_descriptions: prev.include_descriptions.map((item) => {
                                                            if (item.service_id === descendant.id) {
                                                                item.is_include_in_price = !item.is_include_in_price;
                                                            }
                                                            return item;
                                                        }),
                                                    };
                                                });
                                            }}
                                        />
                                        <span>{descendant?.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </Field>
                    <Field>
                        <SubTitle>
                            Цена<Asterisk>*</Asterisk>
                        </SubTitle>
                        <NumericFormat
                            onValueChange={(e) => {
                                setForm((prev) => {
                                    return { ...prev, amount: e.floatValue as number };
                                });
                            }}
                            suffix=" ₽"
                            customInput={TextField}
                            inputProps={CellInputProps}
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            Комиссия мастера<Asterisk>*</Asterisk>
                        </SubTitle>
                        <NumericFormat
                            onValueChange={(e) => {
                                setForm((prev) => {
                                    return {
                                        ...prev,
                                        commission: { value: e.floatValue as number },
                                    };
                                });
                            }}
                            suffix=" ₽"
                            customInput={TextField}
                            inputProps={CellInputProps}
                        />
                    </Field>
                    <Divider sx={{ margin: '20px 0' }} />
                    <Field>
                        <SubTitle>Активен</SubTitle>
                        <CheckBox
                            onClick={() =>
                                setForm((prev) => {
                                    return { ...prev, is_active: !prev.is_active };
                                })
                            }
                            disabled={serviceById?.is_active ? false : true}
                            checked={form?.is_active}
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            Плательщик по умолчанию<Asterisk>*</Asterisk>
                        </SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={['Клиент', 'Партнер']}
                            filterSelectedOptions
                            renderInput={(params) => <TextField {...params} />}
                            defaultValue={'Клиент'}
                            onChange={(_, value, reason) => {
                                if (reason === 'clear') {
                                    setForm((prev: any) => {
                                        return {
                                            ...prev,
                                            availability_type: null,
                                        };
                                    });
                                } else {
                                    setForm((prev) => {
                                        return {
                                            ...prev,
                                            availability_type: value === 'Клиент' ? 'client' : 'partner',
                                        };
                                    });
                                }
                            }}
                        />
                    </Field>
                    <Field>
                        <SubTitle>Доступен в API интеграции</SubTitle>
                        <CheckBox
                            defaultChecked
                            onChange={() => {
                                setForm((prev) => {
                                    return { ...prev, is_active_integration: !prev.is_active_integration };
                                });
                            }}
                        />
                    </Field>
                    <Field>
                        <SubTitle>Внешний идентификатор</SubTitle>
                        <MuiTextField
                            onChange={(e) => {
                                setForm((prev) => {
                                    return { ...prev, external_id: e.target.value };
                                });
                            }}
                            size="small"
                        />
                    </Field>
                </FieldContainer>
                <ButtonsWrapper>
                    <Button
                        variant="contained"
                        onClick={() => setConfirmOpen(true)}
                        disabled={
                            form.amount === undefined ||
                            form.commission.value === undefined ||
                            !form.name ||
                            !form.availability_type ||
                            (checkValue === 'addInSelected' && !form?.price_lists?.pk__in?.length) ||
                            (checkValue === 'addFilters' &&
                                !form?.price_lists?.region_id__in?.length &&
                                !form?.price_lists?.company_id__in?.length)
                        }
                    >
                        Добавить
                    </Button>
                    <Button
                        onClick={() => setOpenModal(false)}
                        variant="outlined"
                        sx={{ marginRight: '157px' }}
                    >
                        Отмена
                    </Button>
                    <FootNote />
                </ButtonsWrapper>
                <AddConfirm
                    checkValue={checkValue}
                    createPriceList={handleCreatePriceList}
                    isConfirmOpen={isConfirmOpen}
                    setConfirmOpen={setConfirmOpen}
                />
            </DialogContent>
        </Dialog>
    );
};
