import { EIssueHelpType } from 'enums/issues.enum';

import CloseIcon from '@mui/icons-material/Close';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';

import EditIssueStatusToCancelForm from './EditIssueStatusToCancelForm';

import {
    setErrorsEditIssueStatusToCancelAction,
    setFormValuesEditIssueStatusToCancelAction,
    setIsOpenEditIssueStatusToCancelAction,
} from '../../../../../store/actions/edit/editIssueStatusToCancel';
import { useAppDispatch } from '../../../../../store/hooks';
import { isOpenEditIssueStatusToCancelSelector } from '../../../../../store/selectors/edit/editIssueStatusToCancelSelectors';

import './EditIssueStatusToCancel.scss';

interface IProps {
    issueHelpType: EIssueHelpType;
}

function EditIssueStatusToCancel({ issueHelpType }: IProps) {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenEditIssueStatusToCancelSelector);

    function onClose() {
        dispatch(setIsOpenEditIssueStatusToCancelAction(false));
        dispatch(setFormValuesEditIssueStatusToCancelAction(null));
        dispatch(setErrorsEditIssueStatusToCancelAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="edit-issue-status-to-cancel-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <SmsOutlinedIcon className="title-icon" /> <div className="title-text">Укажите причину отказа</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditIssueStatusToCancelForm issueHelpType={issueHelpType} />
            </DialogContent>
        </Dialog>
    );
}

export default EditIssueStatusToCancel;
