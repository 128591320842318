import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditIssueStatusToMasterSearchForm from './EditIssueStatusToMasterSearchForm';

import { setIsOpenEditIssueStatusToMasterAction } from '../../../../../store/actions/edit/editIssueStatusToMasterSearch';
import { useAppDispatch } from '../../../../../store/hooks';
import { isOpenEditIssueStatusToMasterSearchSelector } from '../../../../../store/selectors/edit/editIssueStatusToMasterSearchSelectors';
import './EditIssueStatusToMasterSearch.scss';

function EditIssueStatusToMasterSearchWithoutCoords() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenEditIssueStatusToMasterSearchSelector);

    function onClose() {
        dispatch(setIsOpenEditIssueStatusToMasterAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="edit-issue-status-to-master-search-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <InfoOutlinedIcon
                        className="title-icon"
                        color="error"
                    />
                    <div className="title-text">Невозможно изменить статус на &#34;Поиск мастера&#34;</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditIssueStatusToMasterSearchForm />
            </DialogContent>
        </Dialog>
    );
}

export default EditIssueStatusToMasterSearchWithoutCoords;
