import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FC } from 'react';

import { ButtonsContainer, CloseIconWrapper, SubTitle } from '../styles';

type Props = {
    isOpenChangeStatus: boolean;
    setOpenChangeStatus: (value: boolean) => void;
};

export const EditStatus: FC<Props> = ({ isOpenChangeStatus, setOpenChangeStatus }) => {
    return (
        <Dialog
            open={isOpenChangeStatus}
            sx={{
                '.MuiDialog-paper': {
                    width: '480px',
                    borderRadius: '8px',
                    position: 'relative',
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h5">Изменение статуса заявки</Typography>
                <CloseIconWrapper onClick={() => setOpenChangeStatus(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent>
                <SubTitle>Укажите причину, из-за которой вы переводите заявку в статус @status@</SubTitle>
                <TextField
                    sx={{ width: '100%', marginTop: '15px' }}
                    size="small"
                    placeholder="Комментарий"
                    // onChange={(e) =>
                    //
                    // }
                />
                <ButtonsContainer>
                    <Button
                        onClick={() => setOpenChangeStatus(false)}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <Button
                        // onClick={}
                        variant="contained"
                    >
                        Отправить
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
