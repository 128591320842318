import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Popover, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';

type Props = {
    title: string;
    options: TOptions[];
    activeOption: TOptions;
    disabled?: boolean;
    onChange: (value: TOptions['value']) => void;
};

type TOptions = {
    value: boolean | null;
    title: string;
};

export const FilterIs: FC<Props> = ({ title, options, activeOption, disabled, onChange }) => {
    const [open, setOpen] = useState(null);
    const [activeItem, setActiveItem] = useState<TOptions>(activeOption);

    const handleOpen = (e: any) => {
        setOpen(e.currentTarget);
    };

    const handleClick = (item: TOptions) => {
        setActiveItem(item);
        onChange(item.value);
        setOpen(null);
    };

    useEffect(() => {
        setActiveItem(activeOption || { title: '', value: null });
    }, [activeOption]);

    useEffect(() => {
        if (!disabled) return;
        setActiveItem({ title: '', value: null });
    }, [disabled]);

    return (
        <div>
            <Button
                variant={open ? 'filterActive' : 'filter'}
                endIcon={<ExpandMoreIcon />}
                onClick={(e) => handleOpen(e)}
                disabled={disabled}
            >
                {title}
                {activeItem.value === null ? null : `: ${activeItem.title}`}
            </Button>

            <StyledPopover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(open)}
                anchorEl={open}
                onClose={() => setOpen(null)}
            >
                {options.map((item) => (
                    <ListItem
                        key={item.title}
                        onClick={() => handleClick(item)}
                    >
                        <div>{item.title}</div>
                        {activeItem.value === item.value ? <CheckIcon /> : null}
                    </ListItem>
                ))}
                <StyledButton onClick={() => handleClick({ value: null, title: '' })}>Очистить фильтр</StyledButton>
            </StyledPopover>
        </div>
    );
};

const ListItem = styled('div')({
    minHeight: '38px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    cursor: 'pointer',
});

const StyledPopover = styled(Popover)({
    '.MuiPaper-root': {
        marginTop: '8px',
        padding: '10px',
        borderRadius: '8px',
    },
});

const StyledButton = styled(Button)({
    padding: '14px',
});
