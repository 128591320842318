import {
    getAllPricesEditIssuePriceListServiceThunk,
    getAllPricesEditIssuePricesThunk,
    setFormValuesEditIssuePricesAction,
} from 'store/actions/edit/editIssuePrices';
import { useAppDispatch } from 'store/hooks';
import { formValuesEditIssuePricesSelector } from 'store/selectors/edit/editIssuePricesSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import { IPriceFormValue } from 'interfaces/edit/editIssuePrices';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';
import PriceInputStack from './PriceInputStack';

import './EditIssuePricesForm.scss';

interface IProps {
    onClose: () => void;
}
function EditIssuePricesForm({ onClose }: IProps) {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesEditIssuePricesSelector);
    const issue = useSelector(issueSelector);

    const { address } = issue || {};
    useEffect(() => {
        async function handle() {
            try {
                if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true' && issue) {
                    if (issue) {
                        await dispatch(
                            getAllPricesEditIssuePriceListServiceThunk({
                                help_type: issue.help_type,
                                region_id: issue.ratio_category_id,
                                company_id: issue.company_id,
                                is_active: true,
                                price_list__is_active: true,
                            }),
                        );
                    }
                } else {
                    await dispatch(
                        getAllPricesEditIssuePricesThunk({
                            params: {
                                region: address,
                                feature_type: 'string',
                                partner_id: issue?.partner?.id,
                                //category_id__in: categoryIds,
                            },
                        }),
                    );
                }
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, []);

    function onChangeIssuePrices(newPrices: IPriceFormValue[]) {
        dispatch(setFormValuesEditIssuePricesAction({ prices: newPrices }));
    }

    return (
        <div className="edit-issue-prices-form-wrapper">
            <PriceInputStack
                values={formValues?.prices || []}
                onChange={onChangeIssuePrices}
            />
            <FooterForm onClose={onClose} />
        </div>
    );
}

export default memo(EditIssuePricesForm);
