import { AppDispatch } from 'store/store';

import { setHomeDeviceAction, setHomeDevicesAction } from './devices';

import { clientsApi } from '../../api';
import { EClientsActions } from '../../enums/actions.enum';
import { TErrors } from '../../interfaces';
import {
    IClient,
    IClientDetail,
    ICreateClient,
    ICreateClientFormValues,
    ISetClient,
    ISetClients,
    ISetErrorsCreateClient,
    ISetFormValuesCreateClient,
    ISetIsOpenConfirmClose,
    ISetIsOpenCreateClient,
    IUpdateClient,
} from '../../interfaces/clients';
import { ICreateHomeDeviceReq, IHomeDeviceDetail, IUpdateClientHomeDeviceReq } from '../../interfaces/devices';

export const setClientsAction = (payload: IClient[] | null): ISetClients => ({
    type: EClientsActions.SetClients,
    payload,
});

export const setClientAction = (payload: IClientDetail | null): ISetClient => ({
    type: EClientsActions.SetClient,
    payload,
});

/* CC === CreateClient */
export const setFormValuesCCAction = (payload: ICreateClientFormValues | null): ISetFormValuesCreateClient => ({
    type: EClientsActions.SetFormValues,
    payload,
});

/* CC === CreateClient */
export const setErrorsCCAction = (payload: TErrors | null): ISetErrorsCreateClient => ({
    type: EClientsActions.SetErrors,
    payload,
});

export const setIsOpenCreateClientAction = (payload: boolean): ISetIsOpenCreateClient => ({
    type: EClientsActions.SetIsOpenCreateClient,
    payload,
});

export const setIsOpenConfirmCloseAction = (payload: boolean): ISetIsOpenConfirmClose => ({
    type: EClientsActions.SetIsOpenConfirmClose,
    payload,
});

export const getClientThunk = (clientId: IClient['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await clientsApi.getClient(clientId);
        dispatch(setClientAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const createClientThunk = (newClient: ICreateClient) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await clientsApi.createClient(newClient);
        dispatch(setClientAction(data));
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getClientsThunk = (queries?: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await clientsApi.getClients(queries);
        const { items: clients } = data;
        dispatch(setClientsAction(clients));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

interface ICreateClientHomeDeviceThunkParams extends ICreateHomeDeviceReq {
    clientId: IClient['id'];
}
export const createClientHomeDeviceThunk =
    ({
        clientId,
        home_device_id,
        device_photo_file_id,
        warranty_photo_file_id,
        receipt_photo_file_id,
        serial_number,
    }: ICreateClientHomeDeviceThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const request = {
                home_device_id,
                device_photo_file_id,
                warranty_photo_file_id,
                receipt_photo_file_id,
                serial_number,
            };
            const { data } = await clientsApi.createHomeDevice(clientId, request);
            dispatch(setHomeDeviceAction(data));
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const deleteClientHomeDeviceThunk =
    (clientId: IClient['id'], homeDeviceId: IHomeDeviceDetail['id']) => async (dispatch: AppDispatch) => {
        try {
            await clientsApi.deleteHomeDevice(clientId, homeDeviceId);
            dispatch(setHomeDeviceAction(null));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const updateClientHomeDeviceThunk =
    (clientId: IClient['id'], homeDeviceId: IHomeDeviceDetail['id'], request: IUpdateClientHomeDeviceReq) =>
    async () => {
        try {
            await clientsApi.updateHomeDevice(clientId, homeDeviceId, request);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getClientHomeDevicesThunk = (clientId: IClient['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await clientsApi.getHomeDevices(clientId);
        const { items: homeDevices } = data;
        dispatch(setHomeDevicesAction(homeDevices));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const updateClientThunk = (clientId: IClient['id'], postData: IUpdateClient) => async () => {
    try {
        await clientsApi.updateClient(clientId, postData);
    } catch (err) {
        console.error(err);
        throw err;
    }
};
