import { Button, Popover, styled } from '@mui/material';

export const StyledPopover = styled(Popover)({
    '.MuiPaper-root': {
        marginTop: '8px',
        padding: '10px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
    },
});

export const StyledButton = styled(Button)({
    padding: '14px',
});

export const ListItem = styled('div')({
    minHeight: '38px',
    padding: '5px 10px',
});
