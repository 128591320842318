import { EIssueHelpType } from 'enums/issues.enum';
import { issueWarrantyEditingAllowedStatuses } from 'mockData/issues';
import { changeIssueWarrantyThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { changeIssueWarrantyTypeReq } from 'interfaces/issues';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

function Warranty() {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<changeIssueWarrantyTypeReq | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    function changeWarrantyHandler(e: SelectChangeEvent<String>) {
        const val = e.target.value === 'true';
        setFormValues({ is_warranty: val });
    }

    function handleClose() {
        setIsOpen(false);
    }

    async function submitHandler() {
        try {
            if (!isEditable || !formValues || !issue) return;
            setIsLoading(true);
            await dispatch(changeIssueWarrantyThunk(issue.id, formValues));
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
            handleClose();
        }
    }

    const isEditable = useMemo(() => {
        switch (true) {
            case issue?.help_type !== EIssueHelpType.Repair:
                return false;
            case issue?.status && issueWarrantyEditingAllowedStatuses.includes(issue.status):
                return true;
            default:
                return false;
        }
    }, [issue?.help_type, issue?.status]);

    return (
        <div className="val-prop-iss">
            <div>{issue?.is_warranty ? 'Да' : 'Нет'}</div>
            <IconButton
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                disabled={!isEditable}
                className="edit-iss-prop-icon"
            >
                <EditSharpIcon />
            </IconButton>

            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box className="modal-description-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменение наличия гарантии</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl
                        fullWidth
                        margin="dense"
                    >
                        <InputLabel id="is_warranty-label">Гарантийный ремонт</InputLabel>
                        <Select
                            labelId="is_warranty-label"
                            name="is_warranty"
                            margin="dense"
                            label="Гарантийный ремонт"
                            onChange={changeWarrantyHandler}
                            defaultValue={`${issue?.is_warranty || false}`}
                        >
                            <MenuItem value={'true'}>Да</MenuItem>
                            <MenuItem value={'false'}>Нет</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={submitHandler}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Warranty;
