import { chatsApi } from 'api';
import { EChatTypes, EChatUserTypes } from 'enums/chats.enum';
import ChatModule from 'modules/Chat2Module';
import ChatsInfinite from 'modules/ChatsInfiniteModule';
import { chatResetAction, getChatDetailThunk } from 'store/actions/chat';
import { setChatGroupByUserAction } from 'store/actions/chatGroupByUser';
import { getChatsInfiniteThunk } from 'store/actions/chatsInfinite';
import { useAppDispatch } from 'store/hooks';
import { chatDetailSelector } from 'store/selectors/chatSelectors';
import { chatsSelector } from 'store/selectors/chatsInfiniteSelectors';

import { Add, Close } from '@mui/icons-material';
import { Dialog, DialogTitle, styled, IconButton, Divider, Box, Fab, Popover } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import { IChatGroupByUser } from 'interfaces/chatGroupByUsers';
import { IChat, IChatInfoReq } from 'interfaces/chats';
import { IIssue } from 'interfaces/issues';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper
                sx={{ pointerEvents: 'all' }}
                {...props}
            />
        </Draggable>
    );
}

type Props = {
    issue?: IIssue | null;
    onClose: () => void;
};

function Chat({ onClose, issue }: Props) {
    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const chat = useSelector(chatDetailSelector);
    const chats = useSelector(chatsSelector);

    const [item, setItem] = useState<IChat | null>(null);

    useEffect(() => {
        dispatch(chatResetAction());
    }, []);

    const handleWorkerClick = () => {
        createChat({
            user: {
                id: issue?.worker?.id || '',
                type: EChatUserTypes.Worker,
            },
            chat_type: EChatTypes.Support,
        });
    };

    const handleClientClick = () => {
        createChat({
            user: {
                id: issue?.client?.id || '',
                type: EChatUserTypes.Client,
            },
            chat_type: EChatTypes.Support,
        });
    };

    const getChatType = (type: string) => {
        switch (type) {
            case 'worker':
                return 'Мастер.';
            case 'client':
                return 'Клиент.';
            case 'issue_related':
                return 'Клиент-Мастер';
            default:
                return '';
        }
    };

    const createChat = async (params: IChatInfoReq) => {
        const { data } = await chatsApi.postChatInfo({ ...params, issue_id: issue?.id });

        dispatch(setChatGroupByUserAction(params.user as unknown as IChatGroupByUser));

        await dispatch(
            getChatsInfiniteThunk({
                filters: { issue_id__in: issue?.id },
                page: 1,
                size: 30,
                sortOptions: new Map().set('last_message_at', 'desc').set('created_at', 'desc'),
            }),
        );

        await dispatch(getChatDetailThunk(data.chat.id));

        handleClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            hideBackdrop
            open
            PaperComponent={PaperComponent}
            sx={{ pointerEvents: 'none' }}
        >
            <Box sx={{ position: 'relative', height: '500px' }}>
                <ChatListInfiniteWrapper>
                    <ChatsInfinite
                        onChatClick={(chatItem) => {
                            setItem(chatItem);
                        }}
                        isIssue
                        filters={{ issue_id__in: issue?.id }}
                    />
                </ChatListInfiniteWrapper>

                <DialogInner>
                    <Title id="draggable-dialog-title">
                        {item
                            ? `
								${getChatType(item?.chat?.chat_type!) || getChatType(item.participants?.[0]?.user_type!)}
								${
                                    getChatType(item?.chat?.chat_type!)
                                        ? ''
                                        : `${item.participants?.[0]?.last_name || ''} ${
                                              item.participants?.[0]?.first_name || ''
                                          }`
                                }
							  `
                            : `Чаты по заявке №${issue?.oid}`}
                        <CloseBtn onClick={onClose}>
                            <Close />
                        </CloseBtn>
                    </Title>
                    <Divider />
                    {!chats || !chat ? (
                        <div className="choose_chat">
                            <span className="text">Выберите чат для начала работы</span>
                        </div>
                    ) : (
                        <ChatModule
                            channelId={chat.chat.channel_id!}
                            chatId={chat.chat.id!}
                            chat_available={chat.chat.chat_available}
                        />
                    )}
                </DialogInner>

                <Fab
                    color="primary"
                    aria-label="add"
                    sx={{
                        backgroundColor: '#0040f1',
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        ':hover': {
                            backgroundColor: '#0040f1',
                        },
                    }}
                    onClick={handleClick}
                >
                    <Add
                        sx={{ fontSize: '30px' }}
                        className={anchorEl ? 'rotate-animation' : 'rotate-animation-clear'}
                    />
                </Fab>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <PopoverInner>
                        <PopoverItem onClick={handleClientClick}>С клиентом</PopoverItem>
                        {issue?.worker && <PopoverItem onClick={handleWorkerClick}>С мастером</PopoverItem>}
                    </PopoverInner>
                </Popover>
            </Box>
        </Dialog>
    );
}

export default Chat;

const ChatListInfiniteWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '40%',
    height: '100%',
    backgroundColor: '#fff',
    borderRight: '1px solid #00000012',
});

const Title = styled(DialogTitle)({
    textAlign: 'center',
    position: 'relative',
    cursor: 'default',
});

const CloseBtn = styled(IconButton)({
    position: 'absolute',
    top: '5px',
    right: '5px',
});

const DialogInner = styled(Paper)({
    height: '100%',
    width: '60%',
    marginLeft: 'auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
});

const PopoverInner = styled('div')({
    padding: '10px 0',
});

const PopoverItem = styled('div')({
    padding: '2px 15px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: '#F8F8F8',
    },
});
