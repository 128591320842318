import { issuesApi } from 'api';
import UserSmallData from 'components/UserSmallData';
import { EIssueHistory } from 'enums/issues.enum';
import { issueStatusesMock } from 'mockData/issues';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { getIssueThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { dateFormatter } from 'utils/helpers/dateFormatter';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Divider } from '@mui/material';
import { ICommentsHistory } from 'interfaces/history';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ChangeTitle, Comment, HistoryItem, Statuses, Time, UserDataWrapper } from './styles';

import { AddComment } from '../components/modals/add-comment';
import { Confirm } from '../components/modals/confirm';
import { EditComment } from '../components/modals/edit-comment';
import { EditStatus } from '../components/modals/edit-status';
import { Menu } from '../components/modals/menu';
import { fetchHistoryComments, setChooseDelete } from '../store';
import { commentsHistorySelector } from '../store/selectors';

const format: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

export const IssueHistory = () => {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const { commentsHistory, chooseDelete } = useSelector(commentsHistorySelector);

    const [isOpenMenu, setOpenMenu] = useState<any>(false);
    const [isOpenEditComment, setOpenEditComment] = useState<boolean>(false);
    const [isOpenEditStatus, setOpenEditStatus] = useState<boolean>(false);
    const [isOpenConfirm, setOpenConfirm] = useState<boolean>(false);
    const [isOpenChangeStatus, setOpenChangeStatus] = useState<boolean>(false);

    const [isHistoryComment, setIsHistoryComment] = useState<any>(false);

    const addComment = (value: string) => {
        if (issue) {
            issuesApi
                .addComment(issue.id, { comment: value, history_id: chooseDelete })
                .then(() => {
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));
                    dispatch(setChooseDelete(null));

                    enqueueSnackbar('Комментарий добавлен', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                })
                .finally(() => {
                    setOpenEditStatus(false);
                });
        }
    };

    const editComment = (value: string) => {
        if (issue) {
            issuesApi
                .editComment(issue.id, chooseDelete as string, { comment: value })
                .then(() => {
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));
                    dispatch(setChooseDelete(null));

                    enqueueSnackbar('Комментарий изменен', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                })
                .finally(() => {
                    setOpenEditComment(false);
                });
        }
    };

    const deleteComment = () => {
        if (issue) {
            issuesApi
                .deleteComment(issue.id, chooseDelete as string)
                .then(() => {
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));
                    dispatch(setChooseDelete(null));

                    enqueueSnackbar('Комментарий удален', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                })
                .finally(() => {
                    setOpenConfirm(false);
                });
        }
    };

    return (
        <div>
            {commentsHistory?.length ? (
                commentsHistory?.map((item: ICommentsHistory) => {
                    return (
                        <HistoryItem key={item.data.id}>
                            <UserDataWrapper>
                                <UserSmallData
                                    user={item.data?.user}
                                    hideUserType={true}
                                />
                                <div
                                    style={{ cursor: 'pointer', padding: '0 10px', height: '20px' }}
                                    onClick={(e) => {
                                        setOpenMenu(e.target);
                                        if (item.object_type === 'history') {
                                            dispatch(setChooseDelete(item.data?.comments?.[0]?.id || item.data.id));
                                            setIsHistoryComment(item.data?.comments?.[0] || {});
                                        } else {
                                            dispatch(setChooseDelete(item.data.id));
                                            setIsHistoryComment(false);
                                        }
                                    }}
                                >
                                    <ThreeDots height={16} />
                                </div>
                            </UserDataWrapper>

                            {item.object_type === 'comment' ? (
                                <div>
                                    <Comment>{item.data?.comment}</Comment>
                                </div>
                            ) : null}

                            {item.object_type === 'history' ? (
                                <>
                                    {item.data.trigger === EIssueHistory.Status ? (
                                        <ChangeTitle>Изменение статуса</ChangeTitle>
                                    ) : null}
                                    <Statuses>
                                        <Chip
                                            label={issueStatusesMock[item.data.old_value]?.title}
                                            sx={{
                                                fontWeight: '600',
                                                backgroundColor: issueStatusesMock[item.data.old_value]?.colorHex,
                                                color: `${
                                                    issueStatusesMock[item.data.old_value]?.colorText
                                                } !important`,
                                            }}
                                            size={'small'}
                                        />
                                        <ArrowForwardIcon color="disabled" />
                                        <Chip
                                            label={issueStatusesMock[item.data.new_value]?.title}
                                            sx={{
                                                fontWeight: '600',
                                                backgroundColor: `${issueStatusesMock[item.data.new_value]?.colorHex}`,
                                                color: `${
                                                    issueStatusesMock[item.data.new_value]?.colorText
                                                } !important`,
                                            }}
                                            size={'small'}
                                        />
                                    </Statuses>
                                    {item?.data?.comments?.length
                                        ? item?.data?.comments.map((commentItem) => {
                                              return (
                                                  <div key={commentItem?.id}>
                                                      <Comment>{commentItem?.comment}</Comment>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </>
                            ) : null}

                            <Time>{dateFormatter(item.data.created_at, {}, format)}</Time>
                            <Divider sx={{ marginTop: '5px' }} />
                        </HistoryItem>
                    );
                })
            ) : (
                <p className="plug">Статусы пока не изменялись</p>
            )}

            <Menu
                isHistoryComment={isHistoryComment}
                isOpenMenu={isOpenMenu}
                setOpenMenu={setOpenMenu}
                setOpenEditComment={setOpenEditComment}
                setOpenEditStatus={setOpenEditStatus}
                setOpenConfirm={setOpenConfirm}
            />
            <EditComment
                editComment={editComment}
                isOpenEditComment={isOpenEditComment}
                setOpenEditComment={setOpenEditComment}
            />
            <AddComment
                addComment={addComment}
                isOpenDialog={isOpenEditStatus}
                setOpenDialog={setOpenEditStatus}
            />
            <Confirm
                deleteComment={deleteComment}
                isOpenConfirm={isOpenConfirm}
                setOpenConfirm={setOpenConfirm}
            />
            <EditStatus
                isOpenChangeStatus={isOpenChangeStatus}
                setOpenChangeStatus={setOpenChangeStatus}
            />
        </div>
    );
};
