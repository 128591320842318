import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import './ChoosePartner.scss';
import { startAutoAssignmentIssueThunk } from '../../../store/actions/issues';
import { useAppDispatch } from '../../../store/hooks';
import { selectedIssuesForAutoAssignment } from '../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const ConfirmAutoAssignment: FC<Props> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const selectedIssues = useSelector(selectedIssuesForAutoAssignment);
    const numbers = selectedIssues.map((issue) => `№\u00A0${issue.oid}`).join(', ');
    const text =
        selectedIssues.length === 1
            ? `Запустить автопоиск мастера для заявки ${numbers}?`
            : `Запустить автопоиск мастера для заявок ${numbers}?`;

    function autoAssignmentHandle() {
        if (!selectedIssues || selectedIssues.length === 0) return;
        try {
            dispatch(startAutoAssignmentIssueThunk(selectedIssues.map((issue) => issue.id)));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth
            className="dialog"
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Автопоиск</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '20px' }}>{text}</Box>
                <Box sx={{ display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Нет
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={autoAssignmentHandle}
                    >
                        Да
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
