import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useState } from 'react';

import UploadPhotoFileDevice from './UploadPhotoFileDevice';

import TextField from '../../../../../../../components/inputs/TextField';
import { IPhotoFile } from '../../../../../../../interfaces';
import { IUpdateClientHomeDeviceReq } from '../../../../../../../interfaces/devices';
import { IHomeDevice } from '../../../../../../../interfaces/issues';
import { updateClientHomeDeviceThunk } from '../../../../../../../store/actions/clients';
import { getIssueThunk } from '../../../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../../../store/hooks';
import defaultErrorCallback from '../../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../../utils/helpers/getErrorString';
import './Device.scss';

interface IProps {
    client_id: string;
    issue_id: string;
    home_device: IHomeDevice;
    deviceName: string;
}

function DeviceOrder({ client_id, issue_id, home_device, deviceName }: IProps) {
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<IUpdateClientHomeDeviceReq | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { device_photo_file, nameplate_photo_file, id } = home_device || {};

    async function submitHandler() {
        try {
            if (!client_id || !id || !formValues) return;
            setIsLoading(true);
            await dispatch(updateClientHomeDeviceThunk(client_id, id, formValues));
            await dispatch(getIssueThunk(issue_id));
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        setIsOpen(false);
    }

    function changePhotoFileDeviceHandler(files: IPhotoFile[] | null) {
        setFormValues({
            ...(formValues || {}),
            device_photo_file_id:
                // eslint-disable-next-line no-shadow
                files?.map(({ id }) => {
                    return id;
                })[0] || null,
        });
    }
    function changePhotoFilePartHandler(files: IPhotoFile[] | null) {
        setFormValues({
            ...(formValues || {}),
            nameplate_photo_file_id:
                // eslint-disable-next-line no-shadow
                files?.map(({ id }) => {
                    return id;
                })[0] || null,
        });
    }

    return (
        <div>
            <IconButton
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className="edit-iss-prop-icon"
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box className="modal-description-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменение фото техники</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        name="device"
                        labelText="Техника"
                        fullWidth
                        type="text"
                        disabled
                        params={{ margin: 'normal' }}
                        value={deviceName}
                    />
                    <div className="photos">
                        <UploadPhotoFileDevice
                            photoTitle="Техника"
                            name="device_photo_file_ids"
                            onChange={changePhotoFileDeviceHandler}
                            initFileResp={device_photo_file || null}
                        />
                        <UploadPhotoFileDevice
                            photoTitle="Шильдик"
                            name="nameplate_photo_file_ids"
                            onChange={changePhotoFilePartHandler}
                            initFileResp={nameplate_photo_file || null}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={submitHandler}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeviceOrder;
