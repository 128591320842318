import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { EPartsOrdersStatus } from '../../../../../enums/partsOrders.enum';
import {
    getOrdersThunk,
    nextStatusOrderThunk,
    setIsOpenNextStatusOrderAction,
    setOrderAction,
    setSetSelectedOrdersAction,
} from '../../../../../store/actions/orders';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import {
    isOpenNextStatusOrderSelector,
    orderSelector,
    selectedOrdersSelector,
} from '../../../../../store/selectors/ordersSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import { getNextStatusTitle } from '../../../../../utils/helpers/getNextStatusTitle';

function NextStatusOrder() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenNextStatusOrderSelector);
    const order = useSelector(orderSelector);
    const issue = useSelector(issueSelector);
    const selectedOrders = useSelector(selectedOrdersSelector);

    const [currentOrderStatus, setCurrentOrderStatus] = useState<EPartsOrdersStatus | null>(null);
    const [currentOrderNames, setCurrentOrderNames] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            dispatch(setOrderAction(null));
        }
    }, [isOpen]);

    useMemo(() => {
        // eslint-disable-next-line default-case
        switch (true) {
            case !!order: {
                setCurrentOrderStatus(order!.status);
                setCurrentOrderNames([order!.spare_part.name]);
                break;
            }
            case !!selectedOrders && !!selectedOrders.length: {
                setCurrentOrderStatus(selectedOrders![0].status);
                setCurrentOrderNames(selectedOrders!.map((selectedOrder) => selectedOrder.spare_part.name) as string[]);
                break;
            }
        }
    }, [order, selectedOrders]);

    function onCloseHandler() {
        dispatch(setIsOpenNextStatusOrderAction(false));
    }

    async function submitFormHandler() {
        try {
            setIsLoading(true);
            switch (true) {
                case !!order && !!issue && !!currentOrderStatus: {
                    await dispatch(
                        nextStatusOrderThunk({
                            order_ids: [order!.id],
                            status: currentOrderStatus!,
                            moysklad_move_target_store: issue!.worker?.moysklad_store?.meta_data,
                        }),
                    );
                    await dispatch(getOrdersThunk({ issue_id: issue!.id }));
                    onCloseHandler();
                    break;
                }
                case !!selectedOrders && !!issue && !!currentOrderStatus: {
                    const order_ids = selectedOrders!.map((selectedOrder) => selectedOrder.id);
                    await dispatch(
                        nextStatusOrderThunk({
                            order_ids,
                            status: currentOrderStatus!,
                            moysklad_move_target_store: issue!.worker?.moysklad_store?.meta_data,
                        }),
                    );
                    await dispatch(getOrdersThunk({ issue_id: issue!.id }));
                    dispatch(setSetSelectedOrdersAction(null));
                    onCloseHandler();
                    break;
                }
                default:
                    console.error('submitFormHandler parts orders "NextStatusOrder"');
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div
                        className="close-icon"
                        style={{ position: 'absolute', right: '15px', cursor: 'pointer' }}
                    >
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '15px' }}
                        fontSize="large"
                    />{' '}
                    <Typography
                        variant="h6"
                        sx={{ marginRight: '30px' }}
                    >
                        Вы уверены, что хотите перевести <strong>{currentOrderNames.join(', ')}</strong> в{' '}
                        {`"${currentOrderStatus && getNextStatusTitle({ status: currentOrderStatus })}"`}?
                    </Typography>
                </Box>
                <DialogContent>
                    <Box mt={2}>
                        <Button
                            onClick={onCloseHandler}
                            color="inherit"
                            variant="contained"
                        >
                            Нет
                        </Button>

                        <LoadingButton
                            onClick={submitFormHandler}
                            loading={isLoading}
                            variant="contained"
                            sx={{ ml: 1 }}
                        >
                            Да
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </DialogTitle>
        </Dialog>
    );
}

export default NextStatusOrder;
