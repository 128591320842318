import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { FC, useState } from 'react';

import { ListItem, StyledPopover } from './styles';

type TFilterItems = {
    title: string;
    value: string;
};

type Props = {
    filterItems: TFilterItems[];
    activeFilters?: any;
    handleStatusChange: (value: string | null) => void;
    handleInactiveChange: () => void;
};

export const FilterStatus: FC<Props> = ({ handleStatusChange, handleInactiveChange, activeFilters, filterItems }) => {
    const [isOpen, setOpen] = useState<any>();

    return (
        <>
            <Button
                variant={activeFilters?.length ? 'filterActive' : 'filter'}
                onClick={(e) => setOpen(e.currentTarget)}
                endIcon={<ExpandMoreIcon />}
            >
                Статус
                {!!activeFilters?.length && <span style={{ marginLeft: 10 }}>{activeFilters?.length}</span>}
            </Button>
            <StyledPopover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(isOpen)}
                anchorEl={isOpen}
                onClose={() => setOpen(false)}
            >
                <div>
                    {filterItems.map((item: any) => {
                        const isCheck = activeFilters?.includes(item.value);
                        return (
                            <ListItem key={item.title}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isCheck}
                                            onClick={() => handleStatusChange(item.value)}
                                            sx={{ padding: 0, marginRight: '5px' }}
                                        />
                                    }
                                    label={item.title}
                                />
                            </ListItem>
                        );
                    })}
                    <ListItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={activeFilters?.[activeFilters?.length - 1] === false}
                                    onClick={() => handleInactiveChange()}
                                    sx={{ padding: 0, marginRight: '5px' }}
                                />
                            }
                            label={'Неактивен'}
                        />
                    </ListItem>
                </div>
            </StyledPopover>
        </>
    );
};
