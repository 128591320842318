import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import CreateIssueReminderForm from './CreateIssueReminderForm';

import {
    setErrorsCreateIssueReminderAction,
    setFormValuesCreateIssueReminderAction,
    setIsOpenCreateIssueReminderAction,
} from '../../../store/actions/create/createIssueReminder';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenCreateIssueReminderSelector } from '../../../store/selectors/create/createIssueReminderSelectors';
import './CreateIssueReminder.scss';

function CreateIssueReminder() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenCreateIssueReminderSelector);

    function onClose() {
        dispatch(setIsOpenCreateIssueReminderAction(false));
        dispatch(setFormValuesCreateIssueReminderAction(null));
        dispatch(setErrorsCreateIssueReminderAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="create-issue-reminder-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <NotificationsNoneOutlinedIcon className="title-icon" />{' '}
                    <div className="title-text">Новое напоминание</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <CreateIssueReminderForm />
            </DialogContent>
        </Dialog>
    );
}

export default CreateIssueReminder;
