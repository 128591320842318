import { workersApi } from 'api';
import { Button } from 'components/Button';
import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { getWorkerPaymentDetailsThunk } from 'store/actions/worker';
import { useAppDispatch } from 'store/hooks';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';
import { workerDetailSelector, workerPaymentDetailSelector } from 'store/selectors/workerSelectors';

import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    styled,
    TextField,
} from '@mui/material';
import { IPaymentDetails } from 'interfaces/workers';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import Address from './address';

const ignoredInputs = ['registration_address', 'juridical_address', 'address', 'withOutNds'];
const personInput = ['first_name', 'last_name', 'middle_name', 'action_document'];

export const WorkerRequisites = () => {
    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);
    const worker = useSelector(workerDetailSelector);
    const paymentDetails = useSelector(workerPaymentDetailSelector);

    const initialState: IPaymentDetails = {
        data: {
            worker_id: worker?.id,
            first_name: paymentDetails?.director?.first_name || paymentDetails?.first_name || '',
            last_name: paymentDetails?.director?.last_name || paymentDetails?.last_name || '',
            middle_name: paymentDetails?.director?.middle_name || paymentDetails?.middle_name || '',
            account_number: paymentDetails?.account_number || '',
            bank_bik: paymentDetails?.bank_bik || '',
            bank_name: paymentDetails?.bank_name || '',
            inn: paymentDetails?.inn || '',
            ogrnip: paymentDetails?.ogrnip || '',
            okpo: paymentDetails?.okpo || '',
            registration_address: {
                address: paymentDetails?.registration_address?.address || '',
                fias_id: paymentDetails?.registration_address?.fias_id || '',
            },
            correspondent_account: paymentDetails?.correspondent_account || '',
            juridical_person_name: paymentDetails?.juridical_person_name || '',
            cpp: paymentDetails?.cpp || '',
            juridical_address: {
                address: paymentDetails?.juridical_address?.address || '',
                fias_id: paymentDetails?.juridical_address?.fias_id || '',
            },
            address: {
                address: paymentDetails?.address?.address || '',
                fias_id: paymentDetails?.address?.fias_id || '',
            },
            email: paymentDetails?.email || '',
            director: {
                first_name: paymentDetails?.director?.first_name || '',
                last_name: paymentDetails?.director?.last_name || '',
                middle_name: paymentDetails?.director?.middle_name || '',
                action_document: paymentDetails?.director?.action_document || '',
            },
            entity_type: paymentDetails?.entity_type || '',
            nds: paymentDetails?.nds || undefined,
        },
        intervals: paymentDetails?.intervals?.map((interval) => interval.interval_type) as string[],
    };

    const [shouldSave, setShouldSave] = useState(false);

    const [form, setForm] = useState<IPaymentDetails>(initialState);
    const [withOutNds, setWidthOutNds] = useState(paymentDetails?.nds !== undefined ? !paymentDetails.nds : true);
    const [detailsType, setDetailsType] = useState<string>(paymentDetails?.entity_type || '');
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (ignoredInputs.includes(e.target.name)) return;
        setShouldSave(true);

        if (detailsType === 'juridical_person' && personInput.includes(e.target.name)) {
            setForm((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    director: {
                        ...prev.data.director,
                        [e.target.name]: e.target.value,
                    },
                },
            }));
        } else if (e.target.name === 'nds') {
            if (/[.]/.test(e.target.value)) {
                setForm((prev) => ({
                    ...prev,
                    data: { ...prev.data, [e.target.name]: e.target.value.slice(0, -1) },
                }));
                e.target.value = e.target.value.slice(0, -1);
                return;
            }

            if (e.target.value === '0' || /^0\d/.test(e.target.value)) return;

            if (e.target.value === '') {
                setForm((prev) => ({
                    ...prev,
                    data: { ...prev.data, [e.target.name]: undefined },
                }));
                return;
            }

            const ndsNumber = Number(e.target.value);

            if (isNaN(ndsNumber) || ndsNumber < 1 || ndsNumber > 20 || !Number.isInteger(ndsNumber)) return;

            setForm((prev) => ({
                ...prev,
                data: { ...prev.data, [e.target.name]: e.target.value },
            }));
        } else {
            setForm((prev) => ({
                ...prev,
                data: { ...prev.data, [e.target.name]: e.target.value },
            }));
        }
    };

    const handleChangeAddress = (
        addressName: 'registration_address' | 'juridical_address' | 'address',
        body: { address: string; fias_id: string },
    ) => {
        setShouldSave(true);
        setForm((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                [addressName]: body,
            },
        }));
    };

    const handleChangeType = (e: SelectChangeEvent<string>) => {
        setShouldSave(true);
        setDetailsType(e.target.value);

        if (e.target.value === 'self_employed') {
            setForm((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    worker_id: worker?.id,
                    first_name: form?.data?.director?.first_name || form?.data?.first_name || '',
                    last_name: form?.data?.director?.last_name || form?.data?.last_name || '',
                    middle_name: form?.data?.director?.middle_name || form?.data?.middle_name || '',
                    account_number: form?.data?.account_number || '',
                    bank_bik: form?.data?.bank_bik || '',
                    bank_name: form?.data?.bank_name || '',
                    inn: form?.data?.inn || '',
                    entity_type: 'self_employed',
                },
                intervals: ['once_a_day', 'twice_a_week'],
            }));
        } else if (e.target.value === 'ie') {
            setForm((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    worker_id: worker?.id,
                    first_name: form?.data?.director?.first_name || form?.data?.first_name || '',
                    last_name: form?.data?.director?.last_name || form?.data?.last_name || '',
                    middle_name: form?.data?.director?.middle_name || form?.data?.middle_name || '',
                    ogrnip: form?.data?.ogrnip || '',
                    okpo: form?.data?.okpo || '',
                    registration_address: form?.data?.registration_address,
                    account_number: form?.data?.account_number || '',
                    correspondent_account: form?.data?.correspondent_account || '',
                    bank_bik: form?.data?.bank_bik || '',
                    bank_name: form?.data?.bank_name || '',
                    inn: form?.data?.inn || '',
                    entity_type: 'ie',
                    nds: form?.data?.nds,
                },
                intervals: ['once_a_week'],
            }));
        } else if (e.target.value === 'juridical_person') {
            setForm((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    worker_id: worker?.id,
                    juridical_person_name: form?.data?.juridical_person_name || '',
                    inn: form?.data?.inn || '',
                    cpp: form?.data?.cpp || '',
                    juridical_address: form?.data?.juridical_address,
                    address: form?.data?.address,
                    account_number: form?.data?.account_number || '',
                    correspondent_account: form?.data?.correspondent_account || '',
                    bank_bik: form?.data?.bank_bik || '',
                    bank_name: form?.data?.bank_name || '',
                    email: form?.data?.email || '',
                    director: {
                        first_name: form?.data?.first_name || '',
                        last_name: form?.data?.last_name || '',
                        middle_name: form?.data?.middle_name || '',
                        action_document: form?.data?.director?.action_document || '',
                    },
                    entity_type: 'juridical_person',
                    nds: form?.data?.nds,
                },
                intervals: ['every_day'],
            }));
        }
    };

    const handleCancel = () => {
        setDetailsType(paymentDetails?.entity_type || '');
        setForm(initialState);
        setShouldSave(false);
    };

    const handleSend = () => {
        setLoading(true);
        if (!worker) return;
        workersApi
            .postPaymentDetails(form)
            .then(() => {
                dispatch(getWorkerPaymentDetailsThunk(worker.id))
                    .then(() => {
                        enqueueSnackbar('Данные успешно обновлены', { variant: 'success' });
                        setShouldSave(false);
                    })
                    .catch((e) =>
                        enqueueSnackbar(e.response?.data?.detail?.[0]?.msg || e.message, { variant: 'error' }),
                    )
                    .finally(() => setLoading(false));
            })
            .catch((e) => {
                enqueueSnackbar(e.response?.data?.detail?.[0]?.msg || e.message, { variant: 'error' });
                setLoading(false);
            });
    };

    const getError = () => {
        switch (detailsType) {
            case 'self_employed':
                return (
                    !form?.data?.first_name?.trim() ||
                    !form?.data?.last_name?.trim() ||
                    !form?.data?.account_number?.trim() ||
                    !form?.data?.bank_bik?.trim() ||
                    !form?.data?.bank_name?.trim() ||
                    !form?.data?.inn?.trim()
                );
            case 'ie':
                return (
                    !form?.data?.first_name?.trim() ||
                    !form?.data?.last_name?.trim() ||
                    !form?.data?.account_number?.trim() ||
                    !form?.data?.bank_bik?.trim() ||
                    !form?.data?.bank_name?.trim() ||
                    !form?.data?.inn?.trim() ||
                    !form?.data?.ogrnip?.trim() ||
                    !form?.data?.okpo?.trim() ||
                    !form?.data?.correspondent_account?.trim() ||
                    !form?.data?.registration_address?.address.trim() ||
                    (!withOutNds && !form?.data?.nds)
                );
            case 'juridical_person':
                return (
                    !form?.data?.director?.first_name?.trim() ||
                    !form?.data?.director?.last_name?.trim() ||
                    !form?.data?.account_number?.trim() ||
                    !form?.data?.bank_bik?.trim() ||
                    !form?.data?.bank_name?.trim() ||
                    !form?.data?.correspondent_account?.trim() ||
                    !form?.data?.address?.address.trim() ||
                    !form?.data?.juridical_address?.address.trim() ||
                    !form?.data?.juridical_person_name?.trim() ||
                    !form?.data?.cpp?.trim() ||
                    !form?.data?.email?.trim() ||
                    !form?.data?.director?.action_document?.trim() ||
                    !form?.data?.inn?.trim() ||
                    (!withOutNds && !form?.data?.nds)
                );
        }
    };

    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <Paper className="moy-sklad-container">
            <div className="moy-sklad-container-title">Реквизиты</div>
            <FormControlWrapper onChange={handleChange}>
                <InputLabel id="type-select">Тип реквизитов</InputLabel>
                <Select
                    labelId={'type-select'}
                    label={'Тип реквизитов'}
                    onChange={handleChangeType}
                    value={detailsType || paymentDetails?.entity_type}
                >
                    <MenuItem value={'self_employed'}>Самозанятый</MenuItem>
                    <MenuItem value={'ie'}>ИП</MenuItem>
                    <MenuItem value={'juridical_person'}>Юридическое лицо</MenuItem>
                </Select>
                <Divider sx={{ margin: '10px 0' }} />

                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="juridical_person_name"
                        value={form?.data?.juridical_person_name}
                        label="Полное наименование юридического лица"
                        required
                        placeholder='ООО "Техника вашего дома"'
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="inn"
                        value={form?.data?.inn}
                        label={'ИНН'}
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="cpp"
                        value={form?.data?.cpp}
                        label="КПП"
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <Address
                        name="juridical_address"
                        label="Юридический адрес"
                        disabled={!isOwner && !isCompanyOwner}
                        values={form.data?.juridical_address as { address: string; fias_id: string }}
                        handleChange={handleChangeAddress}
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <Address
                        disabled={!isOwner && !isCompanyOwner}
                        name="address"
                        label="Фактический адрес"
                        values={form.data?.address as { address: string; fias_id: string }}
                        handleChange={handleChangeAddress}
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="account_number"
                        value={form?.data?.account_number}
                        label={'Номер расчетного счета'}
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="bank_name"
                        value={form?.data?.bank_name}
                        label="Название банка"
                        required
                    />
                )}
                {(detailsType === 'self_employed' || detailsType === 'ie') && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="last_name"
                        value={form?.data?.director?.last_name || form?.data?.last_name}
                        label={'Фамилия'}
                        required
                    />
                )}
                {(detailsType === 'self_employed' || detailsType === 'ie') && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="first_name"
                        value={form?.data?.director?.first_name || form?.data?.first_name}
                        label={'Имя'}
                        required
                    />
                )}
                {(detailsType === 'self_employed' || detailsType === 'ie') && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="middle_name"
                        value={form?.data?.director?.middle_name || form?.data?.middle_name}
                        label={'Отчество (при наличии)'}
                    />
                )}
                {detailsType === 'self_employed' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="inn"
                        value={form?.data?.inn}
                        label={'ИНН самозанятого'}
                        required
                    />
                )}
                {detailsType === 'ie' && (
                    <Address
                        disabled={!isOwner && !isCompanyOwner}
                        name="registration_address"
                        label="Адрес постоянной регистрации"
                        values={form.data?.registration_address as { address: string; fias_id: string }}
                        handleChange={handleChangeAddress}
                    />
                )}
                {detailsType === 'ie' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="inn"
                        value={form?.data?.inn}
                        label={'ИНН'}
                        required
                    />
                )}
                {detailsType === 'ie' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="ogrnip"
                        value={form?.data?.ogrnip}
                        label="ОГРНИП"
                        required
                    />
                )}
                {detailsType === 'ie' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="okpo"
                        value={form?.data?.okpo}
                        label="ОКПО"
                        required
                    />
                )}
                {(detailsType === 'self_employed' || detailsType === 'ie') && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="account_number"
                        value={form?.data?.account_number}
                        label={detailsType === 'self_employed' ? 'Номер банковского счета' : 'Номер расчетного счета'}
                        required
                    />
                )}
                {detailsType === 'self_employed' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="bank_name"
                        value={form?.data?.bank_name}
                        label="Название банка"
                        required
                    />
                )}
                {detailsType === 'ie' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="bank_name"
                        value={form?.data?.bank_name}
                        label="Название банка"
                        required
                    />
                )}
                {(detailsType === 'self_employed' || detailsType === 'ie' || detailsType === 'juridical_person') && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="bank_bik"
                        value={form?.data?.bank_bik}
                        label="БИК банка"
                        required
                    />
                )}
                {detailsType === 'ie' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="correspondent_account"
                        value={form?.data?.correspondent_account}
                        label="Корреспондентский счет"
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="correspondent_account"
                        value={form?.data?.correspondent_account}
                        label="Корреспондентский счет"
                        required
                    />
                )}

                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="email"
                        value={form?.data?.email}
                        label="Электронная почта"
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="last_name"
                        value={form?.data?.director?.last_name || form?.data?.last_name}
                        label={'Фамилия руководителя'}
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="first_name"
                        value={form?.data?.director?.first_name || form?.data?.first_name}
                        label={'Имя руководителя'}
                        required
                    />
                )}
                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="middle_name"
                        value={form?.data?.director?.middle_name || form?.data?.middle_name}
                        label={'Отчество руководителя (при наличии)'}
                    />
                )}

                {detailsType === 'juridical_person' && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="action_document"
                        value={form?.data?.director?.action_document}
                        label="Документ, на основании которого действует руководитель"
                        required
                    />
                )}
                {(detailsType === 'juridical_person' || detailsType === 'ie') && !withOutNds && (
                    <TextField
                        disabled={!isOwner && !isCompanyOwner}
                        name="nds"
                        value={form?.data?.nds || ''}
                        label={'НДС, %'}
                        required
                        type="text"
                        inputProps={{
                            pattern: '[0-9]*',
                        }}
                    />
                )}
                {(detailsType === 'juridical_person' || detailsType === 'ie') && (
                    <FormControlLabel
                        checked={withOutNds}
                        name="withOutNds"
                        onChange={(_, value) => {
                            if (value) {
                                setForm((prev) => ({
                                    ...prev,
                                    data: { ...prev.data, nds: undefined },
                                }));
                            }
                            setWidthOutNds(value);
                        }}
                        control={<Checkbox />}
                        label="Без НДС"
                    />
                )}
            </FormControlWrapper>
            {shouldSave ? (
                <ButtonsWrapper>
                    <Button
                        variant="outlined"
                        onClick={handleCancel}
                        disabled={isLoading}
                    >
                        Отмена
                    </Button>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            disabled={isLoading || getError()}
                            onClick={handleSend}
                        >
                            Сохранить
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={22}
                                sx={{
                                    color: '#0040F1',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </ButtonsWrapper>
            ) : null}
        </Paper>
    );
};

const FormControlWrapper = styled(FormControl)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

const ButtonsWrapper = styled('div')({
    display: 'flex',
    gap: '15px',
    marginTop: '20px',
});
