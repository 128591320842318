import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../../components/inputs/TextField';
import { TErrors } from '../../../../../interfaces';
import { ICreateInvoicingNotificationsReq } from '../../../../../interfaces/invoicingNotifications';
import { setEmailIsOpenINAction } from '../../../../../store/actions/invoicingNotifications';
import { useAppDispatch } from '../../../../../store/hooks';
import { dataINSelector, emailIsOpenINSelector } from '../../../../../store/selectors/invoicingNotificationsSelectors';
import { isEmail } from '../../../../../utils/helpers/isEmail';

interface IProps {
    sendHandler: (formValues: ICreateInvoicingNotificationsReq) => void;
}

function EmailModal({ sendHandler }: IProps) {
    const dispatch = useAppDispatch();

    const dataIN = useSelector(dataINSelector);
    const isOpen = useSelector(emailIsOpenINSelector);

    const [formValues, setFormValues] = useState<Partial<ICreateInvoicingNotificationsReq> | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);

    const defaultValues = useMemo<Partial<ICreateInvoicingNotificationsReq>>(() => {
        return {
            message_text: dataIN?.message_text,
            confirmation_url: dataIN?.confirmation_url,
        };
    }, [dataIN?.message_text, dataIN?.confirmation_url, isOpen]);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.target) {
            errs.target = { message: 'Обязательное поле' };
        }

        if (formValues?.target && !isEmail(formValues.target)) {
            errs.target = { message: 'Некорректное значение' };
        }
        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        setFormValues(null);
        setErrors(null);
        setIsBtnDisabled(false);
    }, [isOpen]);

    useEffect(() => {
        if (!defaultValues) return;
        setFormValues(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    useEffect(() => {
        if (errors && Object.keys(errors).length) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [errors]);

    function onCloseHandler() {
        dispatch(setEmailIsOpenINAction(false));
    }

    function changeEmail(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues(formValues ? { ...formValues, target: e.target.value } : { target: e.target.value });
    }

    function onSend() {
        if (!formValues) return;
        if (validator()) {
            sendHandler(formValues as NonNullable<ICreateInvoicingNotificationsReq>);
        }
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box sx={{ display: 'flex' }}>
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NotificationsNoneIcon
                        color="primary"
                        sx={{ marginRight: 3 }}
                        fontSize="large"
                    />
                    <Typography variant="h6">Отправка уведомления по почте</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="subtitle">Вы можете изменить номер телефона, либо использовать текущий</div>
                <TextField
                    fullWidth
                    errors={errors}
                    defaultValue={defaultValues.target}
                    onChange={changeEmail}
                    labelText="E-mail *"
                    name="target"
                    params={{ margin: 'normal' }}
                />
                <TextField
                    fullWidth
                    errors={errors}
                    defaultValue={defaultValues.message_text}
                    labelText="Текст сообщения *"
                    name="message_text"
                    disabled
                    params={{ margin: 'normal' }}
                />
                <TextField
                    fullWidth
                    className="comment-field"
                    errors={errors}
                    defaultValue={defaultValues.confirmation_url}
                    labelText="Ссылка на оплату *"
                    name="confirmation_url"
                    disabled
                    params={{ margin: 'normal' }}
                />
            </DialogContent>
            <DialogActions className="buttons">
                <Button
                    onClick={onCloseHandler}
                    variant="outlined"
                >
                    Отмена
                </Button>
                <LoadingButton
                    disabled={isBtnDisabled}
                    variant="contained"
                    onClick={onSend}
                >
                    Отправить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default memo(EmailModal);
