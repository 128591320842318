import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EWorkerStatus } from '../../../enums/workers.enum';
import { workerStatusFiltersMock } from '../../../mockData/workers';
import { setWorkersFiltersAction } from '../../../store/actions/workers';
import { useAppDispatch } from '../../../store/hooks';
import { countWorkersSelector, workersFiltersSelector } from '../../../store/selectors/workersSelectors';
import './WorkersTabs.scss';

function WorkersTabs() {
    const dispatch = useAppDispatch();
    const filters = useSelector(workersFiltersSelector);
    const count = useSelector(countWorkersSelector);
    const [value, setValue] = useState<EWorkerStatus[]>(workerStatusFiltersMock[0].statuses);

    function changeTab(event: React.SyntheticEvent, tab: EWorkerStatus[]) {
        setValue(tab);
        dispatch(setWorkersFiltersAction({ ...filters, status__in: tab }));
    }

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-10px' }}>
                <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={changeTab}
                >
                    {workerStatusFiltersMock.map((item) => (
                        <Tab
                            key={item.title}
                            label={
                                item.title === 'На проверке' ? (
                                    <div className="worker-filter-label">
                                        {item.title}
                                        <span className="filter-count">{count}</span>
                                    </div>
                                ) : (
                                    item.title
                                )
                            }
                            value={item.statuses}
                        />
                    ))}
                </Tabs>
            </Box>
        </div>
    );
}

export default WorkersTabs;
