import { withWrapper } from 'hocs/with-wrapper';
import { useIntersection } from 'hooks/useIntersection';
import { TCompany } from 'pages/services/store/types';
import { Params } from 'types';
import { getFullName } from 'utils/helpers/getFullName';

import { Search, ExpandMore } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Popover,
    TextField,
    styled,
} from '@mui/material';
import { AxiosPromise } from 'axios';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-lodash-debounce';

type Props = {
    title: string;
    activeIds: string[];
    searchKey?: string;
    fetcher: (params?: Params) => AxiosPromise;
    cleaner: () => void;
    onChange: (itemId: string) => void;
    withoutSearch?: boolean;
    withOutInfinityScroll?: boolean;
    isDepositPartners?: boolean;
    isStaticHelpType?: boolean;
    defaultOptions?: TCompany[];
};

export const FilterIn = <T,>({
    title,
    fetcher,
    cleaner,
    activeIds,
    onChange,
    searchKey = 'search',
    withoutSearch,
    withOutInfinityScroll,
    isDepositPartners,
    isStaticHelpType,
    defaultOptions,
}: Props) => {
    const [items, setItems] = useState<T[]>([]);
    const [open, setOpen] = useState<HTMLButtonElement | null>(null);
    const [loading, setLoading] = useState(false);

    const page = useRef(1);
    const total = useRef(0);
    const search = useRef('');

    const [ref] = useIntersection(
        {
            rootId: 'filterIn-wrapper',
            onViewInter: async () => {
                if (!items.length || items.length >= total.current || page.current === 1) return;

                const { data } = await fetcher({
                    page: page.current,
                    [searchKey]: search.current,
                    exclude_pk__in: activeIds,
                }).finally(() => setLoading(false));

                setItems((prev) => [...prev, ...data.items]);

                page.current++;
            },
        },
        [items.length],
    );

    const fetchItems = async () => {
        setLoading(true);

        const { data } = await fetcher({ page: page.current, [searchKey]: search.current }).finally(() =>
            setLoading(false),
        );

        setItems(data.items || data);
        if (!isStaticHelpType) {
            setItems((prev) => sortArray(prev));
        }

        total.current = data.total;
        page.current++;
    };

    const fetchItemsWithActiveIds = async () => {
        setLoading(true);
        const activeIdsNew = isDepositPartners
            ? activeIds.map(
                  (id) => (items.find((item: any) => item.partner_id === id) as { partner_id: string; id: string }).id,
              )
            : activeIds;

        const { data: firstData } = await fetcher({ page: page.current, pk__in: activeIdsNew });
        const { data: secondData } = await fetcher({
            page: page.current,
            exclude_pk__in: activeIdsNew,
            [searchKey]: search.current,
        }).finally(() => setLoading(false));

        const first = firstData?.items || firstData;
        const second = secondData?.items || secondData;

        setItems([...first, ...second]);
        setItems((prev) => sortArray(prev));

        total.current = firstData.total || firstData.length + secondData.total || secondData.length;
        page.current++;
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        search.current = e.target.value;
        page.current = 1;
        if (activeIds.length) fetchItemsWithActiveIds();
        else fetchItems();
    };

    const handleSearchDebounced = useDebouncedCallback(handleSearch, 700);

    useEffect(() => {
        page.current = 1;
        search.current = '';
        total.current = 0;

        if (!open || activeIds.length) return;

        fetchItems();
    }, [open]);

    useEffect(() => {
        if (!activeIds.length || !open) return;
        if (isStaticHelpType) {
            fetchItems();
        } else {
            fetchItemsWithActiveIds();
        }
    }, [open]);

    const sortArray = (array: any[]) =>
        array.sort((a: any) => (activeIds.includes(isDepositPartners ? a.partner_id : a.id) ? -1 : 1));

    const renderItems = () => {
        if (loading) return withWrapper(StateContainer, () => <CircularProgress />);
        if (!items.length) return withWrapper(StateContainer, () => <>Ничего не найдено</>);

        if (defaultOptions?.length) {
            return defaultOptions.map((item: any) => {
                return (
                    <FormGroup key={item.id}>
                        <Item
                            control={
                                <Checkbox
                                    onChange={() => onChange(isDepositPartners ? item.partner_id : item.id)}
                                    checked={activeIds.includes(isDepositPartners ? item.partner_id : item.id)}
                                />
                            }
                            label={item.name}
                        />
                    </FormGroup>
                );
            });
        }

        return items.map((item: any) => (
            <FormGroup key={item.id}>
                <Item
                    control={
                        <Checkbox
                            onChange={() => onChange(isDepositPartners ? item.partner_id : item.id)}
                            checked={activeIds.includes(isDepositPartners ? item.partner_id : item.id)}
                        />
                    }
                    label={getFullName(item) || item.system_name || item.title || item.name || item.oid || ''}
                />
            </FormGroup>
        ));
    };

    return (
        <>
            <Button
                onClick={(e) => setOpen(e.currentTarget)}
                variant={open ? 'filterActive' : 'filter'}
                endIcon={<ExpandMore />}
                sx={{ textTransform: 'none' }}
            >
                {title}
                {activeIds.length > 0 && <Count>{activeIds.length}</Count>}
            </Button>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={() => setOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={PaperProps}
            >
                {withoutSearch ? null : (
                    <TextField
                        fullWidth
                        placeholder={title}
                        variant="outlined"
                        size="small"
                        onChange={handleSearchDebounced}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                <ScrollContainer id="filterIn-wrapper">
                    {renderItems()}
                    {!withOutInfinityScroll && <div ref={ref} />}
                </ScrollContainer>
                <Button
                    onClick={() => {
                        cleaner();
                        setOpen(null);
                    }}
                    fullWidth
                >
                    Очистить фильтр
                </Button>
            </Popover>
        </>
    );
};

const PaperProps = {
    style: {
        width: '400px',
        marginTop: '8px',
        padding: '10px',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)',
        borderRadius: '8px',
        overflow: 'hidden',
    },
};

const ScrollContainer = styled('div')({
    overflow: 'auto',
    maxHeight: '420px',
});

const StateContainer = styled('div')({
    display: 'flex',
    padding: '20px 0',
    alignItems: 'center',
    justifyContent: 'center',
});

const Item = styled(FormControlLabel)({
    margin: '0',
});

const Count = styled('div')({
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#F56767',
    marginLeft: '5px',
    color: '#fff',
});
