import { CloseIcon } from 'static/Icons/CloseIcon';

import { FC, useState } from 'react';

import { Basics } from './basics';
import { Integrations } from './integrations';
import { PriceLists } from './price-lists';

import { CloseIconWrapper, Header, ModalWrapper, MuiDivider, MuiTab, MuiTabs } from '../create-service/styles';

type Props = {
    setOpenEdit: (isOpen: boolean) => void;
    setIsEditing: (isOpen: boolean) => void;
};

export const EditService: FC<Props> = ({ setOpenEdit, setIsEditing }) => {
    const [value, setValue] = useState<any>(0);

    const handleChangeTabs = (e: React.SyntheticEvent, value: number) => {
        setValue(value);
    };

    const render = (value: number) => {
        switch (value) {
            case 0:
                return (
                    <Basics
                        setOpenEdit={setOpenEdit}
                        setIsEditing={setIsEditing}
                    />
                );
            case 1:
                return <PriceLists />;
            // case 2:
            //     return <Specifications />;
            case 2:
                return <Integrations />;
        }
    };

    return (
        <ModalWrapper>
            <Header>
                <MuiTabs
                    onChange={handleChangeTabs}
                    value={value}
                >
                    <MuiTab label="Основное" />
                    <MuiTab label="Прайс-листы" />
                    <MuiTab label="Внешние интеграции" />
                </MuiTabs>
                <CloseIconWrapper onClick={() => setOpenEdit(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
            </Header>
            <MuiDivider sx={{ color: 'rgba(0, 0, 0, 0.08)', marginBottom: '20px' }} />
            {render(value)}
        </ModalWrapper>
    );
};
