import { IClient } from 'interfaces/clients';

import { getFullName } from './getFullName';

export default ({ item, clientInpVal }: { item: IClient; clientInpVal: string | null }) => {
    const name = getFullName(item);

    let phone = '';

    const formattedValue = clientInpVal?.replace(/[\s+\-()]/g, '') || '';

    if (!isNaN(Number(formattedValue)) && formattedValue !== '') {
        if (item.phone_number && item.phone_number.replace(/[\s+\-()]/g, '').includes(formattedValue)) {
            phone = `${item.phone_number} - `;
        } else {
            const matchedPhone = item.phones.find((phoneObj) =>
                phoneObj.phone_number.replace(/[\s+\-()]/g, '').includes(formattedValue),
            );
            if (matchedPhone) {
                phone = `${matchedPhone.phone_number} (доп.) - `;
            }
        }
    } else {
        if (item.phone_number) {
            phone = item.phone_number + ' - ';
        } else if (item.phones.length > 0) {
            phone = item.phones[0].phone_number + ' - ';
        }
    }

    return {
        value: item.id,
        label: `${phone}${name || '* Пустое имя *'}`,
    };
};
