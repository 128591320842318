import { getCategoriesThunk } from 'store/actions/categories';
import { useAppDispatch } from 'store/hooks';
import { categoriesOptionsSelector } from 'store/selectors/categoriesSelectors';
import { formValuesSelector } from 'store/selectors/create/createIssuePartnerSelectors';
import { Option } from 'utils/helpers/selectMapper';

import { Autocomplete, Chip, FormControl, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { setSetFormValuesAction } from '../../../../store/actions/create/createIssuePartner';

export const Technique = () => {
    const dispatch = useAppDispatch();

    const categories = useSelector(categoriesOptionsSelector);
    const formValues = useSelector(formValuesSelector);
    const [inputValue, setInputValue] = useState('');

    const handleGetCategories = useDebouncedCallback(() => dispatch(getCategoriesThunk({ search: inputValue })), 700);

    const handleChangeTechnique = (option: Option | null) => {
        setInputValue('');
        if (option) {
            const isFoundCategory = (formValues?.categories || []).find((item) => item.value === option.value);
            if (!isFoundCategory) {
                dispatch(setSetFormValuesAction({ categories: [...(formValues?.categories || []), option] }));
            }
        }
    };

    const handleDeleteTechnique = (id: string) => {
        dispatch(
            setSetFormValuesAction({ categories: (formValues?.categories || []).filter((item) => item.value !== id) }),
        );
    };

    const defaultOptions = useMemo(() => {
        if (categories) {
            return categories;
        }
        return [];
    }, [categories]);

    useEffect(() => {
        handleGetCategories();
    }, [inputValue]);

    return (
        <Box mt={5}>
            <Typography
                variant={'h5'}
                mb={2}
            >
                Техника
            </Typography>

            {formValues?.categories && (
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    gap={1}
                    mb={2}
                >
                    {formValues.categories.map((category) => (
                        <Chip
                            key={category.value}
                            color="secondary"
                            onDelete={() => handleDeleteTechnique(category.value)}
                            label={category.label}
                        />
                    ))}
                </Stack>
            )}

            <FormControl fullWidth>
                <Autocomplete
                    renderInput={(params) => {
                        return (
                            <TextField
                                placeholder="Категория техники *"
                                {...params}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.value}
                            >
                                {option.label}
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        handleChangeTechnique(option);
                    }}
                    onInputChange={(_, value) => {
                        setInputValue(value);
                    }}
                    inputValue={inputValue}
                    noOptionsText={'Нет категорий техники...'}
                    loadingText={'Загрузка...'}
                    loading={defaultOptions === null}
                    options={defaultOptions}
                    value={null}
                />
            </FormControl>
        </Box>
    );
};
